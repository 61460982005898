import React from "react";
import { DateRangePicker } from "rsuite";

interface IProps {
    handleDateRangeChange: Function 
}


export default function DateRange({ handleDateRangeChange } : IProps) {
    const ref = React.useRef()

    return (
      <div data-testid='div-report-generator-select-date-range'>
        <h5 className="mt-3 mb-2 text-base">Date Range</h5>
        <div className="relative">
          <DateRangePicker
            // ranges={ranges}
            // defaultValue={[formValues.startDate, formValues.endDate]}
            onChange={(dates) => handleDateRangeChange(dates)}
            placeholder="Select Date(s)"
            placement="autoVerticalStart"
          />
        </div>
      </div>
    )
}
