import { Board } from '@/views/Discover/Boards/useBoards'
import { Dispatch, SetStateAction } from 'react'
import { z } from 'zod'

export interface MomentInsightsProps {
  brandProfileId: number | undefined
  clusterId: number
  timeRange: number
}

export const InsightsSchema = z.object({
  taxonomyType: z.string(),
  taxonomyName: z.string(),
  storyPercentage: z.number().multipleOf(0.01)
})

export type InsightsType = z.infer<typeof InsightsSchema>

export const MomentInsightSchema = z.object({
  boardId: z.string(),
  boardName: z.string(),
  clusterId: z.number(),
  insights: z.array(InsightsSchema)
})

export type MomentInsightsType = z.infer<typeof MomentInsightSchema>

export const MomentInsightsListSchema = z.object({
  isTrendingMoment: z.boolean(),
  momentInsights: z.array(MomentInsightSchema)
})


export type MomentInsightsListType = z.infer<typeof MomentInsightsListSchema>

export interface BoardsPanelProps {
  boards: Array<{ boardName: string; boardId: string }>
  activeBoardId: string
  setActiveBoardId: Dispatch<SetStateAction<string>>
}

export interface InsightsPanelProps {
  momentInsights: MomentInsightsType[]
  activeBoardId: string | undefined
}

export interface TaxonomyCardProps {
  taxonomyName: string
  storyPercentage: number
}

export interface TaxonomyCardSectionProps {
  insights: InsightsType[]
  caption: string
}

export interface ModalFooterProps {
  handleFooterNav: (text: 'prev' | 'next') => void
  activeIndex: number
  maxLength: number
}

export interface MomentInsightsModalProps {
  show: boolean
  handleClose: () => void
  momentInsights: MomentInsightsListType | undefined
  trendingMoments: Board
  momentInsightsError: any
}
