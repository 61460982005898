import React from 'react';
import { Link, useMatch } from '@tanstack/react-location';
import SightlyInputForm from '@/components/Sightly/SightlyFormElements/SightlyInputForm';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import { api } from '@/views/Admin/ScenariosV2/api';
import { PatchThemeSchema, PatchThemeType } from '@/views/Admin/ScenariosV2/types';
import SightlyButton from '@/components/Sightly/SightlyButton';
import toast from 'react-hot-toast';
import { isAxiosError } from '@/utils_ts';
import { logError } from '@/utils';
import SightlyToggle from '@/components/Sightly/SightlyFormElements/SightlyToggle';
import { MyLocationGenerics } from '@/classes/utils';
import { LoadingPage } from '@/components/LoadingPage';

export const EditTheme = () => {
    const {
        params: { themeId }
    } = useMatch<MyLocationGenerics>()

    const themeQuery = useQuery(['v2ScenarioTheme', themeId], () => api.themes.getById({ themeId: Number(themeId) }), {
        enabled: !!themeId
    })
    const {
        register,
        handleSubmit,
        control,
        getValues,
        setValue,
        reset,
        formState: { errors, isDirty, isValid }
    } = useForm<PatchThemeType>({
        defaultValues: {
            archived: false,
            theme: '',
            description: ''
        },
        resolver: zodResolver(PatchThemeSchema)
    })

    React.useEffect(() => {
        if (!themeQuery.data) return
        reset(themeQuery.data)
    }, [themeQuery.data])

    const onSubmit = (theme: PatchThemeType) => {
        submitMutation.mutate({ theme, themeId: Number(themeId) })
    }

    const submitMutation = useMutation(api.themes.patch, {
        onSuccess: () => {
            toast.success('Changes saved')
        },
        onError: (err) => {
            console.log('onError')
            reset(themeQuery.data)
            if (isAxiosError<any>(err)) {
                // Access to config, request, and response
                const isDuplicateNameError = err?.response?.data.includes('already exists')
                const linkedToScenarioError = err?.response?.data.includes('Cannot archive theme linked to scenario')
                if (isDuplicateNameError) {
                    toast.error('That name is already used, please try another name')
                } else if (linkedToScenarioError) {
                    toast.error('Cannot archive theme linked to scenario.')
                } else {
                    toast.error('Unknown error ocurred')
                    logError(err, { info: 'error posting scenario' })
                }
            } else {
                toast.error('Unknown error ocurred')
                logError(err, { info: 'error posting scenario' })
            }
        },
        onSettled: () => {
            console.log('onSettled')
            reset(getValues())
        }
    })

    if (themeQuery.isLoading) {
        return <LoadingPage message="Fetching Theme" />
    }

    return (
        <div className="w-full flex justify-center items-center mt-12">
            <div className="w-3/6">
                <Link to={'/app/admin/v2scenarios/themes'}>Back to Themes</Link>
                <div className="mt-2 md:flex md:items-center md:justify-between">
                    <div className="min-w-0 flex-1">
                        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                            Edit Theme
                        </h2>
                    </div>
                </div>
                <form
                    className="w-full space-y-4 mt-8"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <SightlyToggle
                        id="archived"
                        setEnabled={(v) => {
                            setValue('archived', v, {
                                shouldValidate: true,
                                shouldDirty: true
                            })
                        }}
                        enabled={getValues().archived || false}
                        enabledText="Archived"
                        disabledText="Active"
                        isDisabled={false}
                    />

                    <SightlyInputForm
                        control={control}
                        id="theme"
                        name="theme"
                        register={register}
                        label="Name"
                        required
                        autoFocus
                        error={errors?.theme?.message}
                        disabled={getValues().archived}
                    />

                    <SightlyInputForm
                        control={control}
                        id="description"
                        name="description"
                        register={register}
                        label="Description"
                        required
                        autoFocus
                        error={errors?.description?.message}
                        textarea
                        disabled={getValues().archived}
                    />

                    <SightlyButton
                        id="submitButton"
                        handleClick={() => undefined}
                        text="Submit"
                        disabled={!isDirty || !isValid}
                    />
                </form>
            </div>
        </div>
    )
}
