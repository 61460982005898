import React, { useState } from "react";
import { Button, Panel } from "rsuite";
import useBoardCreator from '@/views/Discover/Boards/useBoardCreator';
import { useSearch } from "@tanstack/react-location";
import { MyLocationGenerics } from "@/classes/utils";
import { ItemTypes } from '@/views/Discover/Boards/types';


type IProps = {
    id: number,
    name: string,
    boardCreator: any
    img: string,
    hoverText: string,
    handleClose: () => void,
    disableBlurButton?: boolean,
}

export const BoardFromTemplateItem = ({
    id,
    name,
    boardCreator,
    img,
    hoverText,
    handleClose,
    disableBlurButton = false,
}: IProps) => {

    const [showContent, setShowContent] = useState(false);

    const handleMouseEnter = () => {
        setShowContent(true);
    };

    const handleMouseLeave = () => {
        setShowContent(false);
    };

    const handleNewBoardFromTemplate = async () => {
        boardCreator.handleSelectItem(({
            itemType: ItemTypes.aggregate,
            name: "Suggested Boards",
            id: id,
        }));
        await boardCreator.createBoardFromTemplate();
        handleClose();
    }

    return (
        <div
            data-testid={'card-' + name}
            className="relative w-[196px] h-[164px]"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Panel
                className={`${showContent ? 'blur-lg' : ''} inline-block w-full`}
                shaded
                bordered
                bodyFill
            >
                {disableBlurButton && <div className="bg-[#D9D9D9] h-[160px] w-full absolute opacity-75 rounded-md"/>}

                <div className="w-[196px] h-[109px] content-center justify-center flex bg-[#7B61FF26]">
                    <img data-testid={'card-image'} src={img} width='80px' />
                </div>
                <div
                    data-testid={'card-name'}
                    className='flex h-[51px] pl-3 items-center'
                >
                    <h1 className='font-black text-xl overflow-hidden whitespace-nowrap overflow-ellipsis'>
                        {name}
                    </h1>
                </div>
            </Panel>
            {showContent &&
                (<div
                    className='px-1 absolute w-[196px] h-[160px] top-1'
                >
                    <h5
                        data-testid={'card-hover-text'}
                        className='text-sightlySlate text-base h-3/4 overflow-hidden overflow-ellipsis' >
                        {!disableBlurButton ? hoverText : `To create a ${name.toLowerCase()} board, please update your brand profile`}
                    </h5>
                    {!disableBlurButton && <div
                        className='flex justify-end rounded-lg'
                        >
                        <Button
                            id={`#${name.replace(/\s/g,'')}Template`}
                            data-testid={'card-create-button'}
                            className='rounded-lg w-[90px]'
                            appearance="primary"
                            style={{ backgroundColor: '#7B61FF' }}
                            onClick={() => handleNewBoardFromTemplate()}
                        >
                            Create
                        </Button>
                    </div>}
                </div>)
            }
        </div>
    )
}
