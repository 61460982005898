import React, { useEffect, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { Tree, InputGroup, Input, Icon } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Controller, UseFormRegister } from 'react-hook-form';
import { FormLabel } from './SightlyFormLabel';

interface Option {
  id: number;
  name: string;
  parentId: number;
  taxonomyCode: string;
  taxonomyLevel: number;
  path: string;
  description: string;
  children?: Option[];
}

interface SightlySelectTreeProps {
  dataTestId: string;
  options: Option[];
  triggerChange: (value: any) => void;
  register: any;
  disabled: boolean;
  label: string;
  placeholder: string;
  required?: boolean;
  error?: string;
  control: any;
  name: string;
  id: string;
  initialValue?: number;
}

const SightlySelectTree: React.FC<SightlySelectTreeProps> = ({
  dataTestId,
  options = [],
  triggerChange,
  disabled,
  label,
  placeholder,
  required,
  error,
  control,
  name,
  id,
  initialValue
}) => {

  const getOptionName = (value: number | undefined, optionsList: Option[]): string | undefined => {
    const findOption = (options: Option[]): Option | null => {
      for (const option of options) {
        if (option.id === value) {
          return option;
        }
        if (option.children) {
          const found = findOption(option.children);
          if (found) {
            return found;
          }
        }
      }
      return null;
    };

    const initialOption = findOption(optionsList);
    return initialOption ? initialOption.name : undefined;
  };

  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [initialOptionName, setInitialOptionName] = useState<string | null>(null);
  const [optionNameIsLoaded, setOptionNameIsLoaded] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const optionName = getOptionName(initialValue, options) ?? null;
    setSelectedOption(optionName);
    setInitialOptionName(optionName);
    setOptionNameIsLoaded(!!optionName);
    if (initialValue === null) setOptionNameIsLoaded(true)
  }, [initialValue, options]);

  useEffect(() => {
    const optionName = getOptionName(initialValue, options) || null;
    setInitialOptionName(optionName)
    setSelectedOption(optionName)
  }, []);

  const handleSelect = (value: any, item: any) => {
    setSelectedOption(value.name);
    triggerChange(value.id);
    setOpen(false);
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handleDropdownToggle = () => {
    setOpen(!open);
    if (!open) {
      setSearchTerm('');
    }
  };

  return (
    <div data-testid={dataTestId}>
      <div className="flex items-center">
        <FormLabel
            id={id}
            text={label}
            required={required}
        />
      </div>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div>
            <div
              className={`relative w-full cursor-default mr-0 rounded-md border ${open ? 'border-sightly-blue-thick' : 'border-gray-300'} bg-white py-2 pl-3 pr-10 text-left shadow-sm sm:text-sm disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500`}
              style={{
                position: 'relative',
                marginTop: 1,
                paddingRight: 10,
                color: 'black',
                height: 38,
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                marginRight: 0
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  flex: 1,
                  justifyContent: 'space-between'
                }}
                onClick={() => handleDropdownToggle()}
              >
                <div
                  style={{
                    textAlign: 'left',
                    lineHeight: '24px',
                    minWidth: 122,
                    color: '#4A5568'
                  }}
                  className="line-clamp-1"
                >
                  {optionNameIsLoaded && (
                    selectedOption || initialOptionName || placeholder
                  )}
                </div>
                <div style={{ textAlign: 'right' }}>
                  <ChevronDownIcon className="text-gray-500 w-5" />
                </div>
              </div>

              {open && (
                <ClickAwayListener
                  onClickAway={() => {
                    setOpen(false);
                    setSearchTerm('');
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: 64,
                      left: 14,
                      backgroundColor: 'white',
                      zIndex: 99999,
                      overflowY: 'auto',
                      borderRadius: 6,
                      border: '1px solid rgb(224, 224, 224)',
                      minWidth: 400,
                      padding: 8
                    }}
                  >
                    <InputGroup>
                      <Input
                        placeholder="Search"
                        onChange={(val, event) => {
                          event.stopPropagation();
                          handleSearch(val)
                        }}
                      />
                      <InputGroup.Button
                        disabled
                        style={{ backgroundColor: 'transparent' }}
                      >
                        <Icon icon="search" />
                      </InputGroup.Button>
                    </InputGroup>

                    <div style={{ height: 8 }} />
                    <Tree
                      showIndentLine
                      searchKeyword={searchTerm}
                      autoHeight
                      defaultExpandAll={false}
                      defaultValue={initialValue}
                      labelKey="name"
                      valueKey="id"
                      data={options}
                      onSelect={(value, item) => {
                        field.onChange(item);
                        handleSelect(value, item);
                      }}
                    />
                  </div>
                </ClickAwayListener>
              )}
            </div>
            {error && <span className="error">{error}</span>}
          </div>
        )}
      />
      </div>
  );
};

export default SightlySelectTree;
