import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { logError } from '@/utils';
import { findAccountNodeByAccountId } from '@/utils_ts';
import { useNavigate, useSearch } from '@tanstack/react-location';
import { MyLocationGenerics } from '@/classes/utils';
import { rqKeys } from '@/ReactQueryKeyFactory';
import { location } from '@/RouteContainer';
import { api } from '@/api/api';
import { perms } from '@/staticData/permissions';
import { products, userActions } from '@/staticData/products';
import { AccountType } from '@/schemas/schemas';
import userCanPermissionProductQuota, {PermissionCheckType} from '@/services/userCanPermissionProductQuota';
import useSubscription, { SubscriptionQuota } from '@/hooks/subscription/useSubscription';

export default function useUser() {
  const search = useSearch<MyLocationGenerics>()
  const accountIdFromParam = Number(search.accountId)
  const userId = Number(localStorage.getItem('userId'))
  const QUERY_KEY = rqKeys.userKey(Number(userId), Number(accountIdFromParam))
  const userObj = useQuery(QUERY_KEY, () => api.user.get(userId, accountIdFromParam, !accountIdFromParam), {
    enabled: !!userId,
    retry: 1,
    staleTime: 50000,
    onError: (err) => {
      logError(err, { info: 'onError in useUser useQuery' })
    }
  })

  const userPermissions = React.useMemo(
    () => userObj.data?.permissions.map((p) => p.permissionName),
    [userObj?.data?.permissions]
  );

  const userAccounts = React.useMemo(() =>
    userObj?.data?.accounts,
    [userObj?.data?.accounts]
  );

  const currentAccount = React.useMemo<AccountType | undefined>(() => {
    if (userObj.isLoading || userAccounts === undefined) {
      return undefined
    }
    if (userAccounts.length === 0) {
      return undefined
    }
    let currentAccountId
    if (accountIdFromParam) {
      let userStillHasAccessToThisAccount = false
      let node = findAccountNodeByAccountId(accountIdFromParam, userAccounts)
      if (node) {
        userStillHasAccessToThisAccount = true
      }

      if (userStillHasAccessToThisAccount) {
        currentAccountId = accountIdFromParam
      } else {
        currentAccountId = userAccounts[0].accountId
      }
    } else {
      currentAccountId = userAccounts[0].accountId
    }
    return findAccountNodeByAccountId(currentAccountId, userAccounts)
  }, [accountIdFromParam, userAccounts])

  const userProducts = React.useMemo(() => {
    if (currentAccount?.products?.length) {
      return currentAccount.products.map((product) => product.name)
    }
    return []
  }, [currentAccount?.products])

  const navigate = useNavigate()

  React.useEffect(() => {
    if (currentAccount?.accountId && !search.accountId) {
      navigate({
        hash: location.current.hash,
        search: (prev) => {
          return {
            ...prev,
            accountId: currentAccount?.accountId
          }
        }
      })
    }
  }, [currentAccount])

  const isInvalidUser = userObj.error instanceof Error ? userObj.error.message.includes('404') : false

  const { subscriptionQuotaQueryResults } = useSubscription({
    accountId: currentAccount?.accountId,
    userProducts
  })

  const subscriptionQuota = React.useMemo(() => {
    if (!subscriptionQuotaQueryResults.length) return new Map()
    return new Map(
      subscriptionQuotaQueryResults
        .filter((quota: any) => !!quota.data)
        .map((quota: any) => [quota.data?.data?.productName, <SubscriptionQuota>quota.data?.data])
    )
  }, [subscriptionQuotaQueryResults])

  /**
   *
   * @param {requiredPermission} requiredPermission the permission name that's to be authorized
   * @param {requiredProduct} requiredProduct the requiredProduct: if provided will be authorized in combination with permission. User must have both perm and prod access to pass.
   * @param {action} action the userAction: if CREATE is passed then the quota is authorized with perm, prod and quota availability
   * @returns boolean: if allowed then true else false
   */

  const userCanService = React.useCallback(
    (requiredPermission: string, requiredProduct?: string, action?: string) => {
      if (!(currentAccount && userPermissions)) return false
      // checks if userCan on basis of if permission is not labeled with product.
      if (!requiredProduct) {
        return userCanPermissionProductQuota({
          requiredPermissionValue: requiredPermission,
          userPermissions: userPermissions,
          checkType: PermissionCheckType.PERMISSION_CHECK
        });
      }
      // checks if userCan on basis of if permission is labeled with product and userAction is to create an entity.
      if (action && action === userActions.CREATE) {
        if (!subscriptionQuota?.size) return false
        if (subscriptionQuota.has(requiredProduct)) {
          const quota = subscriptionQuota.get(requiredProduct)
            return userCanPermissionProductQuota({
              requiredPermissionValue: requiredPermission,
              userPermissions: userPermissions,
              requiredProductValue: requiredProduct,
              userProducts: userProducts,
              quota_is_allowed: quota?.isAllowed,
              checkType: PermissionCheckType.PRODUCT_QUOTA_PERMISSION_CHECK
            });
        }
      }
      // checks if userCan on basis of if permission is labeled with product
      return userCanPermissionProductQuota({
        requiredPermissionValue:requiredPermission,
        userPermissions:userPermissions,
        requiredProductValue:requiredProduct,
        userProducts:userProducts,
        checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
      })
    },
    [userPermissions, userProducts, subscriptionQuota]
  )

  return {
    ...userObj,
    QUERY_KEY,
    isInvalidUser,
    perms,
    products,
    userCanService,
    currentAccount,
    accounts: userAccounts,
    user: userObj.data,
    userPermissions,
    userProducts
  }
}
