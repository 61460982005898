import React, { useRef, useState } from 'react';
import { Board } from './useBoards'
import {
  PATH_MOMENTS_V2_AI_EDIT,
  PATH_MOMENTS_V2_EDIT_CUSTOM
} from '@/routes'
import { accentColor } from '@/assets/jss/colorConstants'
import { ViewColumnsIcon } from '@heroicons/react/24/outline';
import { products } from '@/staticData/products'
import { CustomWhisper } from '@/components/CustomWhisper';
import Dropdown from 'rsuite/lib/Dropdown';
import IconButton from 'rsuite/lib/IconButton';
import Icon from 'rsuite/lib/Icon';
import Whisper from 'rsuite/lib/Whisper';
import RTooltip from 'rsuite/lib/Tooltip';
import RenameModal from '@/views/Discover/Boards/RenamingModal';
import { PencilIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';

interface SelectorProps {
  index: number
  board: Board
  handleSelectBoard: Function
  selectedBoards: number[]
  userCanService: Function
  perms: any
  navigate: Function
  setDeletingBoardId: Function
  setShowDeleteAlert: Function
  handleRename: Function
}

const BoardSelectCard = ({
  index,
  board,
  handleSelectBoard,
  selectedBoards,
  userCanService,
  perms,
  navigate,
  setDeletingBoardId,
  setShowDeleteAlert,
  handleRename
}: SelectorProps) => {
  const isSelected = selectedBoards.includes(board.id);
  const setEnabled = (bool: boolean) => handleSelectBoard(board.id);
  const selectedClass = 'bg-lightPurple border-l-4 border-sightlyPurple';
  const isGeneral = board.type === 'general';

  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showContent, setShowContent] = useState(false);
  let timerRef = useRef<NodeJS.Timeout | null>(null);


  const handleMouseEnter = () => {
      setShowContent(true);
      if (timerRef.current) {
          clearTimeout(timerRef.current);
      }
  };

  const handleMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      setShowContent(false);
    }, 500);
  };
  return <>
    <div
    key={index}
    data-testid='moment-board-item'
    data-testischecked={isSelected.toString()}
    className={`${isSelected ? selectedClass : ''} rounded-md min-h-8`}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    >
      <div className="flex items-center justify-between h-8">
        <button data-testid='moment-board-item-switch' className='w-full' id={`board-toggle-${board.id}`} onClick={() => setEnabled(!isSelected)}>
          <div
            className={"flex gap-2 font-bold overflow-hidden break-words whitespace-normal text-gray-700"}
          >
            <span data-testid='moment-board-item-name' data-board-name={board.name} className='flex items-center'>
              <ViewColumnsIcon className="h-5 ml-2 shrink-0" />
              <span className="ml-2">
                {board.name.length > 24 &&
                  <Whisper
                    delayShow={0}
                    delayHide={0}
                    trigger='hover'
                    placement='auto'
                    speaker={
                      <RTooltip className="inline">
                        {board.name}
                      </RTooltip>
                    }
                  >
                    <span>
                      {board.name.substring(0, 24)}{board.name.length > 24 ? '...' : ''}
                    </span>
                  </Whisper>
                }
                {board.name.length <= 24 &&
                  <span>{board.name}</span>
                }
              </span>
            </span>
          </div>
        </button>
        {userCanService(perms.AYLIEN_BOARD_CREATE, products.BOARDS) && showContent && (
          <div className="flex gap-1 text-gray-700">
            <span data-testid='moment-board-item-button-edit' className="mr-2">
              <CustomWhisper
                dropdownItems={[
                  <Dropdown.Item
                    key={1}
                    disabled={isGeneral}
                    onClick={() => {
                      if (board.type === 'naturalLanguage') {
                        // manage edit in NLP
                        navigate({
                          to: `${PATH_MOMENTS_V2_AI_EDIT}/${board.id}`,
                          search: (search: any) => ({
                            ...search,
                            boardId: board.id,
                            editingBoard: true
                          })
                        })
                      } else if (isGeneral) {
                        // cannot edit template boards
                        return;
                      } else {
                        navigate({
                          to: `${PATH_MOMENTS_V2_EDIT_CUSTOM}/${board.id}`
                        })
                      }
                    }}
                  >
                    <span className="pr-2">
                      <PencilIcon className="w-6 h-w-6 inline-block mr-2" />
                    Edit Board
                    </span>
                  </Dropdown.Item>,
                  <Dropdown.Item
                    key={2}
                    onClick={() => {
                      setShowRenameModal(true);
                    }}
                  >
                    <span className="pr-2">
                      <PencilSquareIcon className="w-6 h-w-6 inline-block mr-2" />
                    Rename
                    </span>
                  </Dropdown.Item>,
                  <Dropdown.Item
                    key={3}
                    onClick={() => {
                      setDeletingBoardId(board.id);
                      setShowDeleteAlert(true);
                    }}
                  >
                    <span className="pr-2">
                      <TrashIcon className="w-6 h-w-6 inline-block mr-2" />
                    Delete
                    </span>
                  </Dropdown.Item>
                ]}
              >
                <IconButton
                  size="sm"
                  appearance="subtle"
                  icon={
                    <Icon
                      icon="more"
                      color={accentColor}
                    />
                  }
                />
              </CustomWhisper>
            </span>
          </div>
        )}
      </div>
    </div>

    <RenameModal
      key={board.id}
      handleCancel={() => setShowRenameModal(false)}
      handleClose={() => setShowRenameModal(false)}
      handleRename={(val: string) => {
          if (!board.id) return;
          handleRename({ id: board.id, name: val });
          setShowRenameModal(false);
      }}
      open={showRenameModal}
      name={board.name} />
  </>
}

export default BoardSelectCard;
