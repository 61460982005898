import { useMatch, useSearch } from '@tanstack/react-location';
import React, { useState } from 'react';
import { brandSuccessColor } from '@/assets/jss/colorConstants';
import { MyLocationGenerics } from '@/classes/utils';
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox';
import { OPINION_ACTIONS } from '@/staticData/data';
import { ActionButton } from '@/views/BrandProfiles/BrandProfile/components/Opinions/V2/ActionButton';
import { OpinionsThemeType, OpinionType } from '@/views/BrandProfiles/BrandProfile/components/Opinions/V2/opinions-types';
import useOpinions from '@/views/BrandProfiles/BrandProfile/components/Opinions/V2/useOpinionsV2';
import { Dropdown } from '@/views/BrandProfiles/BrandProfile/components/Opinions/V2/Dropdown';
import { Loader } from 'rsuite';
import { InspireMeButton } from '@/views/BrandProfiles/BrandProfile/components/Queries/components/InspireMeButton';
import { AIWarningModal } from '@/views/BrandProfiles/BrandProfile/components/AIWarningModal';
import { AiInfoWhisper } from '@/views/BrandProfiles/BrandProfile/components/AiInfoWhisper';
import useUser from '@/hooks/useUser';
import useBrandProfileBasic from '@/hooks/brandProfile/useBrandProfileBasic';
import GeneratingData from '@/views/BrandProfiles/BrandProfile/components/GeneratingData';
import { productNamesForUI, products, userActions } from '@/staticData/products';
import Tooltip from '@/components/TailwindTooltip';
import ProductLimitedAccessInfo from '@/components/ProductLimitedAccessInfo';
import { useProductUsage } from '@/hooks/subscription/useProductUsage';
import { OpinionDescriptionHoverable } from '@/views/BrandProfiles/BrandProfile/components/Opinions/V2/SlideOver/OpinionDiscriptionHoverable';

const getResponseLabel = (responseId: number) => {
  const OPINIONS_ACTIONS_VALUES = Object.values(OPINION_ACTIONS)
  const currentOpinion = OPINIONS_ACTIONS_VALUES.find((val) => val.id === responseId)
  return currentOpinion?.text || ''
}

export const OpinionTable = ({
  opinionTheme,
  sortBy
}: {
  opinionTheme: OpinionsThemeType | null
  sortBy: 'least' | 'most'
}) => {
  const {
    params: { brandProfileId }
  } = useMatch<MyLocationGenerics>()
  const { currentAccount, userCanService, perms } = useUser()
  const opinions = opinionTheme?.opinions ?? []
  const opinionsMap = React.useMemo(() => new Map(opinions.map((opinion) => [opinion.opinionId, opinion])), [opinions])
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const { viewOnly } = useSearch<MyLocationGenerics>()
  const [checked, setChecked] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)
  const [selectedOpinions, setSelectedOpinions] = useState<Map<number, OpinionType>>(new Map())
  const { queryResult: BrandBasicInfo } = useBrandProfileBasic(Number(brandProfileId))
  const { productQuotaTally: aiCreditsTally } = useProductUsage({
    accountId: currentAccount?.accountId,
    productName: products.AI_CREDITS
  })
  const { handleOpinionChangeMutation, aiGenerateOpinionsMutation, isAIGeneratingBrandOpinion } = useOpinions(
    Number(brandProfileId),
    sortBy,
    currentAccount?.accountId,
    setSelectedOpinions
  )

  const isIndeterminate = selectedOpinions.size > 0 && selectedOpinions?.size < opinions.length
  function toggleAll() {
    setSelectedOpinions(checked || indeterminate ? new Map() : opinionsMap)
    setChecked(!checked && !indeterminate)
    setIndeterminate(false)
  }

  const handleBulkActionClick = (actionId: 1 | 2 | 3 | 4 | 5) => {
    selectedOpinions.forEach((opinion) => {
      selectedOpinions.set(opinion.opinionId, { ...opinion, opinionResponseId: actionId })
    })

    handleOpinionChangeMutation.mutate({
      opinions: Array.from(selectedOpinions.values()).map(({ opinionId, opinionResponseId, isAiGenerated }) => ({
        opinionId,
        opinionResponseId
      })),
      brandProfileId: Number(brandProfileId)
    })
  }

  const answeredCount = React.useMemo(
    () => opinions.reduce((ansCount, opinion) => (opinion.opinionResponseId ? ansCount + 1 : ansCount), 0),
    [opinions]
  )
  const percentComplete = opinionTheme ? ((answeredCount || 0) / opinionTheme?.opinions.length) * 100 : 0

  const isOpinionAIGenerationAllowed = React.useMemo(()=>{
    if (aiCreditsTally && selectedOpinions.size > aiCreditsTally.productBalance) {
      return false
    }
    else {
      return true
    }
  },[selectedOpinions])

  const selectedOpinionIds = React.useMemo(() => {
    return Array.from(selectedOpinions.keys())
  }, [selectedOpinions, opinions])

  return (
    <>
      <div>
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="relative">
            {!isOpinionAIGenerationAllowed && (
              <p
                className="pl-8 text-sm text-red-600"
                data-testid="brand-suggestion-count-max-error"
              >
                Selection count exceeds the available limit of {aiCreditsTally?.productBalance || 0} AI Credits.
              </p>
            )}
            <div
              data-testid="opinion-table-percentage-complete"
              style={{ color: '#8f8f8f', fontWeight: 600, fontSize: 12, paddingRight: 16 }}
              className="w-full mb-2 text-end"
            >
              Progress: {percentComplete.toFixed(0)}%
            </div>
            <div className="w-full h-2 rounded bg-slate-100">
              <div
                data-testid="opinion-table-progress-complete"
                style={{
                  backgroundColor: percentComplete === 100 ? brandSuccessColor : '#0047ff',
                  width: `${percentComplete}%`
                }}
                className="w-5 h-2 rounded w-"
              ></div>
            </div>

            <div className="flex items-center px-8 bg-sightly-blue-light h-14">
              <SightlyCheckbox
                dataTestId="opinion-table-select-all-checkbox"
                id="selectAllCheckbox"
                checked={selectedOpinions.size === opinionsMap.size}
                handleChange={toggleAll}
                indeterminate={isIndeterminate}
                disabled={!!viewOnly}
              />

              {selectedOpinions.size > 0 && (
                <div
                  data-testid="opinion-table-select-all-count"
                  style={{ color: '#333d47' }}
                  className="mx-2 text-xs font-bold"
                >{`${selectedOpinions.size} item(s) selected`}</div>
              )}

              {selectedOpinions.size > 0 && (
                <div className="flex items-center h-12 space-x-1 bg-sightly-blue-light">
                  <Tooltip
                    disabled={userCanService(perms.AI_CREDITS, products.AI_CREDITS, userActions.CREATE)}
                    content={
                      <ProductLimitedAccessInfo
                        inSufficientPermission={!userCanService(perms.AI_CREDITS, products.AI_CREDITS)}
                        productNames={[
                          aiCreditsTally && aiCreditsTally.isMaxedOut ? productNamesForUI.AI_CREDITS : undefined
                        ]}
                      />
                    }
                  >
                    <div>
                      <InspireMeButton
                        size="xs"
                        label="Inspire Me"
                        dataTestId="opinion-ai-generate-btn"
                        pendoId='pendo-inspireme-opinions'
                        disabled={
                          viewOnly ||
                          !isOpinionAIGenerationAllowed ||
                          !userCanService(perms.AI_CREDITS, products.AI_CREDITS, userActions.CREATE)
                        }
                        handleAIGenerate={() => {
                          setIsConfirmDialogOpen(true)
                        }}
                      />
                    </div>
                  </Tooltip>
                  <ActionButton
                    dataTestId="action-button-strongly-agree"
                    text={OPINION_ACTIONS.StronglyAgree.text}
                    handleClick={() => {
                      handleBulkActionClick(OPINION_ACTIONS.StronglyAgree.id)
                    }}
                  />
                  <ActionButton
                    dataTestId="action-button-agree"
                    text={OPINION_ACTIONS.Agree.text}
                    handleClick={() => {
                      handleBulkActionClick(OPINION_ACTIONS.Agree.id)
                    }}
                  />
                  <ActionButton
                    dataTestId="action-button-unsure"
                    text={OPINION_ACTIONS.Unsure.text}
                    handleClick={() => {
                      handleBulkActionClick(OPINION_ACTIONS.Unsure.id)
                    }}
                  />
                  <ActionButton
                    dataTestId="action-button-disagree"
                    text={OPINION_ACTIONS.Disagree.text}
                    handleClick={() => {
                      handleBulkActionClick(OPINION_ACTIONS.Disagree.id)
                    }}
                  />
                  <ActionButton
                    dataTestId="action-button-strongly-disagree"
                    text={OPINION_ACTIONS.StronglyDisagree.text}
                    handleClick={() => {
                      handleBulkActionClick(OPINION_ACTIONS.StronglyDisagree.id)
                    }}
                  />
                </div>
              )}
            </div>

            <table className="min-w-full divide-y divide-gray-300">
              <tbody className="bg-white divide-y divide-gray-200">
                {opinions.map((opinion: OpinionType) => (
                  <tr
                    data-testid="opinion-table-row"
                    key={opinion.opinionId}
                    className={selectedOpinions.has(opinion.opinionId) ? 'bg-gray-50' : undefined}
                  >
                    <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                      {selectedOpinions.has(opinion.opinionId) && (
                        <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                      )}
                      <SightlyCheckbox
                        dataTestId="opinion-table-row-checkbox"
                        disabled={!!viewOnly}
                        id="checkbox"
                        checked={selectedOpinionIds.includes(opinion.opinionId)}
                        handleChange={(checked: boolean) => {
                          setSelectedOpinions((prevValue: Map<number, OpinionType>) => {
                            const newValue = new Map(prevValue)
                            checked ? newValue.set(opinion.opinionId, opinion) : newValue.delete(opinion.opinionId)
                            return newValue
                          })
                        }}
                      />
                    </td>
                    <td
                      data-testid="opinion-table-row-opinion-name"
                      className={`whitespace py-4 pr-3 font-semibold text-sm text-sightlySlate
											${selectedOpinionIds.includes(opinion.opinionId)} ? 'text-indigo-600' : 'text-gray-900'`}
                    >
                      {opinion.question}
                    </td>

                    <td
                      data-testid="opinion-table-row-opinion-description"
                      className={`whitespace py-4 pr-3 font-semibold text-sm text-sightlySlate
                        ${selectedOpinionIds.includes(opinion.opinionId)} ? 'text-indigo-600' : 'text-gray-900'`}
                    >
                      {opinion.opinionDescription &&
                        <OpinionDescriptionHoverable description={opinion.opinionDescription} />
                      }
                    </td>

                    <td className="py-4 pl-3 pr-4 text-right whitespace-nowrap w-44">
                      <div data-testid="action-dropdown">
                        <Dropdown
                          defaultResponseId={opinion.opinionResponseId}
                          disabled={!!viewOnly || selectedOpinions.size > 0}
                          handleChange={(val: number) => {
                            const _opinions = [
                              {
                                opinionId: opinion.opinionId,
                                opinionResponseId: val
                              }
                            ]
                            handleOpinionChangeMutation.mutate({
                              opinions: _opinions,
                              brandProfileId: Number(brandProfileId)
                            })
                          }}
                        />
                      </div>
                    </td>
                    <td className="w-20 text-right whitespace-nowrap">
                      {opinion.isAiGenerated && (
                        <AiInfoWhisper
                          confidenceScore={opinion.confidenceScore || 0}
                          question={opinion.question}
                          reason={opinion.reason || ''}
                          responseLabel={getResponseLabel(opinion.opinionResponseId || 0)}
                          key={opinion.opinionId}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {!!isAIGeneratingBrandOpinion && (
          <GeneratingData
            backdrop
            message="Generating..."
          />
        )}

        {handleOpinionChangeMutation.isLoading && (
          <Loader
            backdrop
            center={false}
          />
        )}
        {isConfirmDialogOpen && (
          <AIWarningModal
            open={isConfirmDialogOpen}
            handleCancel={() => setIsConfirmDialogOpen(false)}
            handleGenerate={() => {
              aiGenerateOpinionsMutation.mutate({
                opinionIds: Array.from(selectedOpinions.keys()),
                brandProfileId: Number(brandProfileId),
                brandProfileInfo: BrandBasicInfo.data
              })
              setIsConfirmDialogOpen(false)
            }}
          />
        )}
      </div>
      <style>
        {`.rs-loader-spin {
            position:fixed;
            top:40em;
        }`}
      </style>
    </>
  )
}
