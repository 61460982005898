import React from "react";
import { ActivationStatus } from "@/views/Discover/Moments/v2/types";
import { SightlyTag } from "@/components/Sightly/SightlyTag";

interface IProps {
    action: 'block'
    activationStatus: ActivationStatus
}

const toTitleCase = (word:string) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export const ActivationStatusBadge = ({ action, activationStatus }: IProps) => {
    // Converts "placements" type name to "videos"
    const activationType = activationStatus.type === 'placements' ? 'videos' : activationStatus.type

    let text
    let tagType

    switch(activationStatus.status) {
        case 'pending':
        case 'queued':
        case 'processing':
            // e.g. Block videos processing
            text = `${toTitleCase(action)} ${activationType} processing`
            tagType = 'warning'
            break
        case 'timeout':
        case 'failed':
            // e.g. Block videos error
            text = `${toTitleCase(action)} ${activationType} error`
            tagType = 'error'
            break
        case 'blocked':
            // e.g. Videos blocked
            text = `${toTitleCase(activationType)} ${action}ed`
            tagType = 'success'
            break
    }

    return (
      <SightlyTag
        dataTestId='moment-card-action-tags'
        text={text}
        type={tagType}
      />
    )
}
