import React from 'react';
import SightlyButton from '@/components/Sightly/SightlyButton';
import { ProgressBar } from "@/components/ProgressBar";
import {UsersIcon} from "@heroicons/react/20/solid";
import {products, userActions} from '@/staticData/products';
import { InviteUserExternal } from "@/components/InviteUserExternal";
import {perms} from "@/staticData/permissions";
import { ProductQuotaTally } from "@/hooks/subscription/useProductUsage/types";
import { getProductUsageProgressValues } from "./helpers/getProductUsageProgressValues";

type InviteTeamMembersProps = {
  userCanService: Function
  productQuotaTally: ProductQuotaTally
}

export const InviteTeamMembers = ( { userCanService, productQuotaTally }: InviteTeamMembersProps) => {
  const [inviteUserDialogIsOpen, setInviteUserDialogIsOpen] = React.useState(false)

  const { percentage, text} = getProductUsageProgressValues(productQuotaTally)

  const userCannotTakeAction = !userCanService(perms.USER_CREATE, products.USERS, userActions.CREATE)
  const quotaIsSpent = percentage >= 100 || !percentage

  return (
    <div
      data-testid="invite-team"
      className="flex items-center justify-between border h-24 bg-white rounded-md"
    >
      <div className="mx-6 grid gap-2 flex-1 ">
        <div className="font-semibold text-base">Invite Team Members</div>
        <ProgressBar
          percentage={percentage}
          text={text}
        />
      </div>
      <div className="mx-4">
        <SightlyButton
          datatestid="button-invite-user"
          handleClick={() => setInviteUserDialogIsOpen(true)}
          icon={<UsersIcon className="-ml-0.5 mr-2 h-5"/>}
          text={"Invite User"}
          disabled={userCannotTakeAction || quotaIsSpent}
        />
      </div>

      <InviteUserExternal
        isOpen={inviteUserDialogIsOpen}
        setIsOpen={setInviteUserDialogIsOpen}
      />
    </div>
  )
}
