import React, { useEffect, useRef, useState } from 'react';
import Input from 'rsuite/lib/Input';
import { PencilSquareIcon, ClockIcon } from '@heroicons/react/24/outline';
import { ArrowDownTrayIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { Button } from 'rsuite';
import Tabs from '@/components/Tabs/Tabs';
import { ActivationReportForm } from './ActivationReportForm';

interface IProps {
    tabTitles: string[]
}

export const ActivationReportsView = ({ tabTitles = ['Performance Summary', 'Ad Group'] }: IProps) => {

    const [reportName, setReportName] = useState('New Activation Report');
    const [activeEditName, setActiveEditName] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const [activeTab, setActiveTab] = React.useState(tabTitles[0]);

    useEffect(() => {
        if (activeEditName && inputRef.current) {
            inputRef.current.focus(); // Focus the input when editing the report name
            inputRef.current.select(); // Select all text when focus
            inputRef.current.style.borderColor = '#E4E7E7';
        }
    }, [activeEditName]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            setActiveEditName(false); // Exit on "Enter" key
        }
    };
    return (
        <div>
            {/* Header */}
            <div className='flex flex-row w-full items-center justify-center mt-8'>
                <div className=' w-1/2 flex flex-row'>
                    {
                        activeEditName ? <Input
                            inputRef={inputRef}
                            className='ml-6 custom-input-editable-report-name'
                            id="report-name"
                            defaultValue={reportName}
                            onChange={(val: string) => setReportName(val)}
                            placeholder="New Activation Report"
                            onBlur={() => setActiveEditName(false)} // Handle unfocus to exit editing mode
                            onKeyDown={handleKeyDown} // Handle "Enter" key to exit editing mode
                            style={{ borderColor: 'white' }}
                        />
                            : <div className='ml-6 mr-2 text-2xl font-medium'>
                                {reportName}
                            </div>
                    }
                    {
                        !activeEditName &&
                        <PencilSquareIcon className="w-6 inline-block mr-2 cursor-pointer" onClick={() => setActiveEditName(true)} />
                    }
                </div>

                <div className="border-l border-[#D9D9D9] h-10 mx-2"></div>
                <div className=' w-1/3 flex flex-row place-content-evenly'>
                    <div className='flex flex-col items-center cursor-pointer' onClick={() => { }}>

                        <ArrowDownTrayIcon
                            className="flex-shrink-0 w-6 h-6 text-indigo-600 mb-2"
                            aria-hidden="true"
                        />
                        <div>Download</div>
                    </div>

                    <div className='flex flex-col items-center cursor-pointer' onClick={() => { }}>
                        <ClockIcon
                            className="flex-shrink-0 w-6 h-6 text-indigo-600 mb-2"
                            aria-hidden="true"
                        />
                        <div>Schedule</div>
                    </div>

                </div>
                <div className="border-l border-[#D9D9D9] h-10 mx-2"></div>
                <div className=' w-1/3 flex flex-row place-content-evenly mr-16'>
                    <Button
                        id='cancel-report-button'
                        data-testid={'card-create-button'}
                        className='rounded-md w-[129px] h-[40px]'
                        appearance="primary"
                        style={{ backgroundColor: '#FFFFFF', color: '#7B61FF' }}
                        onClick={() => { }}
                    >
                        Cancel
                    </Button>
                    <Button
                        id='create-report-button'
                        data-testid={'card-create-button'}
                        className='rounded-md w-[129px] h-[40px]'
                        appearance="primary"
                        style={{ backgroundColor: '#7B61FF' }}
                        onClick={() => { }}
                    >
                        Create report
                    </Button>

                </div>
            </div>
            <div className='ml-6 flex flex-row mt-3 custom-tab-active-color'>
                <Tabs
                    width={'100%'}
                    marginRight={40}
                    active={activeTab}
                    onChange={(active: string) => {
                        setActiveTab(active)
                    }}
                    
                >
                    {tabTitles.map((tab) => {
                        return <div className="flex flex-row items-center" key={tab} data-testid={tab}>
                            <div className='flex flex-row text-sm ml-2'>
                                {tab}
                                <ChevronDownIcon
                                    className="w-5 h-5 ml-2"
                                    aria-hidden="true"
                                />

                            </div>
                        </div>
                    })}
                </Tabs>
                <div className='flex flex-row items-center cursor-pointer' >
                    <div className='font-semibold text-base'>
                        + Add a Report
                    </div>
                </div>
            </div>
            {
                tabTitles.map((report) => {
                    if (report == activeTab) {
                        return <div className={`data-active-activation-report-${report} mt-1`}>
                            {
                                <ActivationReportForm />
                            }
                        </div>
                    }
                })
            }
        </div>

    )
}
