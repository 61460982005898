import React from "react";
import { Radio, RadioGroup } from "rsuite";

interface IProps {
    handleOnChange: Function 
}


export default function AdFormatSection({  handleOnChange } : IProps) {
    return (
      <div data-testid='div-report-generator-select-ad-format custom-radio-color-checked'>
        <h5 className="mt-3 mb-2 text-base"> Ad Format Breakout</h5>
        <div className="flex flex-row custom-radio-color-checked custom-radio-margin">
          <RadioGroup
            inline
            name="adFormatList"
            onChange={(value) => handleOnChange('splitAdFormat', value)}
          >
            <Radio value={true} color="violet">Split View</Radio>
            <Radio value={false} color="violet">Combined</Radio>
          </RadioGroup>
        </div>
      </div>
    )
}