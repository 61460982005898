import React from 'react'
import { Icon } from 'rsuite'
import { TrashIcon } from '@heroicons/react/20/solid'
import { UseQueryResult } from '@tanstack/react-query'

import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'
import { InsertionOrderPersonasWrapper } from '@/views/Discover/Moments/v2/TargetMoment/InsertionOrderPersonasWrapper'
import { ClusterType } from '@/views/Discover/Moments/v2/types'
import { TargetviewIo } from '@/classes/brandProfile'
import { IoOptionsType, TargetListType } from '@/views/Discover/Moments/v2/TargetMoment/index'
import Tooltip from '@/components/TailwindTooltip'

export const MomentTable = ({
  selectedTargetMoments,
  setSelectedTargetMoments,
  isSelectAllChecked,
  setIsSelectAllChecked,
  handleSelectAll,
  listedMoments,
  ioOptions,
  listedMomentBoards,
  targetList,
  setIOsToTargetList,
  removeIOsFromTargetList,
  setPersonasToTargetList,
  setMomentToBeDeleted,
  isBulkAssignApplied,
  setIsBulkAssignApplied,
  isFullScreen
}: MomentTableProps) => {
  return (
    <div className="relative flex-1 h-full overflow-y-auto">
      <div className="inline-block h-full min-w-full py-2 align-middle md:px-6">
        <div className="h-full shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table
            data-testid="act-mgr-target-moment-table"
            className="min-w-full overflow-y-auto divide-y divide-gray-300"
          >
            <thead className="bg-gray-50">
              <tr>
                <th
                  id="target-moments-checkbox"
                  scope="col"
                  className="relative px-3 py-4"
                >
                  <SightlyCheckbox
                    data-testid="select-all-target-moments-checkbox"
                    id="selectAllCheckbox"
                    indeterminate={selectedTargetMoments.size > 0 && !isSelectAllChecked}
                    checked={isSelectAllChecked}
                    handleChange={(checked: boolean) => {
                      setIsSelectAllChecked(checked)
                      handleSelectAll(checked)
                    }}
                  />
                </th>
                <th
                  id="target-moments-boards"
                  scope="col"
                  className="py-3.5  px-2 text-left text-xs font-semibold text-gray-900"
                >
                  Board
                </th>
                <th
                  id="target-moments-moment"
                  scope="col"
                  className={`px-3 py-3.5 text-left text-xs font-semibold text-gray-900 ${isFullScreen && 'w-full'}`}
                >
                  Moment
                </th>
                <th
                  id="target-moments-insertion-order"
                  scope="col"
                  className="py-3.5 text-left text-xs font-semibold text-gray-900 w-[650px]"
                >
                  <div className="flex justify-evenly">
                    <span className="w-[304px]">Insertion Order</span>
                    <span className="w-[308px]">Personas</span>
                  </div>
                </th>

                <th
                  id="target-moments-action"
                  scope="col"
                  className="relative py-3.5 w-16"
                >
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {listedMoments.length <= 0 && <tr ><td rowSpan={7} colSpan={7} className='text-center h-[50vh]'>No Moments Found</td></tr>}
              {listedMoments.map((moment) => (
                <tr
                  key={moment.clusterId}
                  className="align-top"
                >
                  <td
                    data-testid="act-mgr-target-moments-table-checkbox"
                    headers="target-moments-checkbox"
                    className="relative px-3 py-4 text-sm font-medium text-right whitespace-nowrap"
                  >
                    <SightlyCheckbox
                      id="targetMomentSelector"
                      dataTestId="target-moments-checkbox"
                      checked={selectedTargetMoments.has(moment.clusterId)}
                      handleChange={(checked: boolean) => {
                        if (checked)
                          setSelectedTargetMoments((prev) => {
                            const next = new Set(prev)
                            next.add(moment.clusterId)
                            return next
                          })
                        else
                          setSelectedTargetMoments((prev) => {
                            const next = new Set(prev)
                            next.delete(moment.clusterId)
                            return next
                          })
                      }}
                    />
                  </td>

                  <td
                    headers="target-moments-boards"
                    data-testid="act-mgr-target-moments-table-boards"
                    className="py-4"
                  >
                    {!listedMomentBoards?.get(moment.clusterId)?.length && (
                      <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                        <Icon
                          icon="spinner"
                          spin
                        />
                      </p>
                    )}
                    {listedMomentBoards?.get(moment.clusterId)?.map((board) => (
                      <p
                        className="p-1 text-xs font-semibold rounded-md bg-badgeLightBlue text-badgeDarkPurple max-w-fit"
                        key={board.boardId}
                      >
                        {board.boardName}
                      </p>
                    ))}
                  </td>

                  <td
                    headers="target-moments-moment"
                    data-testid="act-mgr-target-moments-table-moment"
                    className="max-w-xs px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap"
                  >
                    <Tooltip
                      content={
                        <div
                          data-testid="act-mgr-target-moments-table-cluster-name-tooltip"
                          className="text-left"
                        >
                          {moment.clusterName}
                        </div>
                      }
                    >
                      <div
                        data-testid="act-mgr-target-moments-table-moment-clusterName"
                        className="truncate"
                      >
                        {moment.clusterName}
                      </div>
                    </Tooltip>
                  </td>
                  <td
                    data-testid="act-mgr-target-moments-table-io-persona"
                    headers="target-moment-io-persona"
                    className='w-[650px]'
                  >
                    <InsertionOrderPersonasWrapper
                      clusterId={moment.clusterId}
                      ioOptions={ioOptions}
                      targetList={targetList}
                      setIOsToTargetList={setIOsToTargetList}
                      removeIOsFromTargetList={removeIOsFromTargetList}
                      setPersonasToTargetList={setPersonasToTargetList}
                      isBulkAssignApplied={isBulkAssignApplied}
                      setIsBulkAssignApplied={setIsBulkAssignApplied}
                    />
                  </td>

                  <td
                    headers="target-moments-action"
                    data-testid="act-mgr-target-moments-table-action"
                    className="relative text-sm font-medium text-right whitespace-nowrap "
                  >
                    <div
                      data-testid="act-mgr-target-moments-table-action-delete"
                      className="flex justify-center p-5"
                    >
                      <TrashIcon
                        onClick={() => {
                          setMomentToBeDeleted(moment.clusterId)
                        }}
                        className="w-6 h-6 rounded hover:cursor-pointer hover:bg-slate-100"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

type MomentTableProps = {
  selectedTargetMoments: Set<number>
  setSelectedTargetMoments: (value: React.SetStateAction<Set<number>>) => void
  isSelectAllChecked: boolean
  setIsSelectAllChecked: (value: React.SetStateAction<boolean>) => void
  handleSelectAll: (checked: boolean) => void
  listedMoments: ClusterType[]
  listedMomentBoards:
    | Map<
        number,
        {
          boardId: number
          boardName: string
        }[]
      >
    | undefined
  ioOptions: IoOptionsType[]
  targetList: Map<number, TargetListType>
  setIOsToTargetList: (clusterId: number, ioId: number, prevIoId: number|undefined) => void
  removeIOsFromTargetList: (clusterId: number, ioId: number) => void
  setPersonasToTargetList: (clusterId: number, ioId: number, personas: string[]) => void
  setMomentToBeDeleted: (value: React.SetStateAction<number | null>) => void
  isFullScreen: boolean
  isBulkAssignApplied: boolean
  setIsBulkAssignApplied: React.Dispatch<React.SetStateAction<boolean>>

}
