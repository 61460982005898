import {
  ReportTemplate,
  REPORT_TYPE,
  Option,
  TEMPLATE_TYPE,
  templateGenericDimensionOptions,
  templateMetricOptions,
  templateConversionOptions,
  GENERIC_DIMENSION,
  METRIC, GenericDimensionOption, CONVERSION
} from "@/views/ReportGenerator/types/report-generator.types";

enum TOPIC_DIMENSION {
  DEVICE = 'device',
  TOPIC_NAME = 'topicName',
}

interface TopicDimensionOption extends Omit<GenericDimensionOption, 'value'> {
  name: string
  value: GENERIC_DIMENSION | TOPIC_DIMENSION
}

interface TopicReport extends Omit<ReportTemplate, 'columns' | 'dimensions'> {
  columns: (GENERIC_DIMENSION | TOPIC_DIMENSION | METRIC | CONVERSION)[],
  dimensions: (GenericDimensionOption | TopicDimensionOption)[]
}

export const dimensionOptions: Option<TopicDimensionOption> = {
    [TOPIC_DIMENSION.TOPIC_NAME]: {
      name: 'Topic Name',
      value: TOPIC_DIMENSION.TOPIC_NAME
    },
    [TOPIC_DIMENSION.DEVICE]: {
        name: 'Device',
        value: TOPIC_DIMENSION.DEVICE
    },
}

export const baseTopicReport: TopicReport = {
  name: 'Topic Report',
  description: '',
  type: REPORT_TYPE.DETAIL,
  templateType: TEMPLATE_TYPE.TOPIC,
  columns: [],
  dimensions: [
    ...templateGenericDimensionOptions,
    ...Object.keys(dimensionOptions).map(key => dimensionOptions[key])
  ],
  metrics: templateMetricOptions,
  conversions: templateConversionOptions
}

export const personaTopicReportTemplate: TopicReport = {
  ...baseTopicReport,
  name: 'Persona Topic Report',
  columns: [
    METRIC.STAT_DATE,
    GENERIC_DIMENSION.CUSTOMER_ID,
    GENERIC_DIMENSION.INSERTION_ORDER_NAME,
    GENERIC_DIMENSION.PERSONA,
    TOPIC_DIMENSION.TOPIC_NAME,
    METRIC.IMPRESSIONS,
    METRIC.CPM,
    METRIC.VIEWS,
    METRIC.CLICKS,
    METRIC.CTR,
    METRIC.COST,
    METRIC.VCR,
    METRIC.QUARTILE_25,
    METRIC.QUARTILE_50,
    METRIC.QUARTILE_75,
    METRIC.QUARTILE_100,
  ],
}
