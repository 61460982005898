import { z } from 'zod'

export enum RelevanceClassification {
    High = 'High',
    Medium = 'Medium',
    Low = 'Low'
}

export enum MomentJustificationOrigin {
    Analyze = 'analyze',
    History = 'history',
}

export const ActivationStatusSchema = z.object({
    type: z.enum(['placements', 'keywords']),
    status: z.enum(['pending', 'queued', 'processing', 'failed', 'timeout', 'blocked']),
})
export type ActivationStatus = z.infer<typeof ActivationStatusSchema>

//['monitor', 'target', 'block', 'dont monitor']
export const ClusterSchema = z.object({
    clusterId: z.number(),
    clusterName: z.string(),
    originalRecommendedAction: z.string(),
    action: z.string(),
    blockStatuses: z.array(ActivationStatusSchema),
    targetActivationStatus: z.string().nullable(),
    areKeywordsTargeted: z.boolean(),
    arePlacementsTargeted: z.boolean(),
    editedFromAction: z.string().nullable(),
    editedBy: z.string().nullable(),
    editedDateTime: z.string().nullable(),
    blockKeywordsCount: z.number(),
    targetKeywordsCount: z.number(),
    articleCount: z.number(),
    videoCount: z.number(),
    imageUrl: z.string().optional(),
    feedback: z.string().nullable(),
    keywordsFeedback: z.string().nullable(),
    recommendedActionFeedback: z.string().nullable(),
    targetList: z.object({
        id: z.number(),
        clusterName: z.string(),
        ioIdWithPersonas: z.map(z.number(), z.set(z.string()))
    }).optional(),
    garmFlagged: z.boolean(),
    minPublishedAt: z.string(),
    maxPublishedAt: z.any(),
    clusterUpdatedAt: z.any(),
    articleCountPerDay: z.any().default([]),
    clusterSummary: z.any(),
    //once the brand relevance is fully deply this arguments should not be optional.
    brandRelevanceScore: z.number().optional().nullable(),
    relevanceClassification: z.nativeEnum(RelevanceClassification).optional().nullable(),

})

export type ClusterType = z.infer<typeof ClusterSchema>

const ClusterActivationTypeSchema = z.object({
    clusterId: z.number(),
    clusterName: z.string(),
})

export type ClusterActivationType = z.infer<typeof ClusterActivationTypeSchema>


export const MomentArticleSchema = z.object({
    images: z.array(z.string()).optional(),
    description: z.string().optional(),
    title: z.string().optional(),
    url: z.string()
})

export type MomentArticleType = z.infer<typeof MomentArticleSchema>

export const MomentArticlesSchema = z.object({
    articles: z.array(MomentArticleSchema),
    count: z.number(), 
    page: z.number(), 
    total: z.number(),
});

export const MomentVideoSchema = z.object({
    channelId: z.string(),
    channelTitle: z.string(),
    videoId: z.string(),
    videoTitle: z.string(),
    clusterId: z.number(),
    feedback: z.object({
        videoRelatedToTrend: z.boolean().optional(),
        languageIsEnglish: z.boolean().optional(),
        dateRelevancyIsInvalid: z.boolean().optional()
    })
})
export type MomentVideoType = z.infer<typeof MomentVideoSchema>

export const MomentVideosSchema = z.object({
    videos: z.array(MomentVideoSchema),
    count: z.number(), 
    page: z.number(), 
    total: z.number(),
});

export const MomentScenarioSchema = z.object({
    scenarioId: z.number(),
    scenarioName: z.string(),
    scenarioResponseId: z.number().min(0).max(4),
    feedback: z.string().nullable().optional()
})

export type MomentScenarioType = z.infer<typeof MomentScenarioSchema>

export const MomentScenariosSchema = z.array(MomentScenarioSchema)

const MomentKeywordSchema = z.object({
    keyword: z.string(),
    rank: z.number()
})

export type MomentKeywordType = z.infer<typeof MomentKeywordSchema>

export const MomentKeywordsSchema = z.object({
    targetKeywords: z.array(MomentKeywordSchema),
    blockKeywords: z.array(MomentKeywordSchema)
})

export type MomentKeywordsType = z.infer<typeof MomentKeywordsSchema>

const AylienTaxonomy = z.object({
    id: z.number(),
    taxonomyPath: z.string(),
    taxonomyCode: z.string()
})

export const TaxonomiesSchema = z.object({
    aylienIndustries: z.array(AylienTaxonomy),
    aylienNews: z.array(AylienTaxonomy)
})

const MomentJustificationSchema = z.object({
    uuid: z.string(),
    relevanceSummary: z.string(),
    actionableInsight: z.string(),
    marketingActionSuggestion: z.string(),
  });
  
export const MomentsJustificationResponseSchema = z.array(MomentJustificationSchema);

export const HistoryResponseItemSchema = z.object({
    uuid: z.string(),
    actionableInsight: z.string(),
    brandProfileId: z.string(),
    clusterId: z.string(),
    clusterTitle: z.string(),
    createdAt: z.string(),
    marketingActionSuggestion: z.string(),
    relevanceClassification: z.string(),
    relevanceSummary: z.string(),
    feedbackRelevanceSummary: z.boolean().nullable(),
    feedbackActionableInsight: z.boolean().nullable(),
    feedbackMarketingActionSuggestion: z.boolean().nullable(),
  });
  
export const HistoryResponseSchema = z.object({
    hasNext: z.boolean(),
    results: z.array(HistoryResponseItemSchema),
})
  
const MomentActionsEnum = z.enum(['target', 'block', 'monitor', 'noaction']);
export type MomentActionsEnum = z.infer<typeof MomentActionsEnum>;
export const QueryActionJustificationSchema = z.object({
    name: z.string(),
    action: z.string()
})
export type QueryActionJustificationType = z.infer<typeof QueryActionJustificationSchema>

export const CategoryActionJustificationSchema = z.object({
    category: z.string(),
    taxonomy_code: z.string(),
    action: z.string()
})
export type CategoryActionJustificationType = z.infer<typeof CategoryActionJustificationSchema>

export const MomentActionJustificationsSchema = z.object({
    brandProfileId: z.number(),
    clusterId: z.number(),
    headline: z.string(),
    queryActionJustifications: z.array(QueryActionJustificationSchema),
    categoryActionJustifications: z.array(CategoryActionJustificationSchema),
    categoryActionJustificationWithGarm: z.array(CategoryActionJustificationSchema),
    categoryActionJustificationWithoutGarm: z.array(CategoryActionJustificationSchema),
    action: z.string(),
    created: z.coerce.date(),
})
export type MomentActionJustificationType = z.infer<typeof MomentActionJustificationsSchema>

export type MomentSortBy = 'placements' | 'ranking' | 'publishedAsc' | 'publishedDesc' | 'relevance'
