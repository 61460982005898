export enum REPORT_TYPE {
    DETAIL = 'detail',
    // SUMMARY = 'summary', TODO: To be implemented with the Performance Summary report
}

export enum GRANULARITY {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year',
    SUMMARY = 'summary',
}

export enum TEMPLATE_TYPE {
    AGE = 'age',
    AUDIENCE = 'audience',
    CREATIVE = 'creative',
    DEVICE = 'device',
    DMA_REGION = 'dmaRegion',
    GENDER = 'gender',
    GOOGLE_PLACEMENT = 'placement',
    HOUSEHOLD_INCOME= 'householdIncome',
    INDUSTRY_CATEGORY = 'industryCategory',
    KEYWORD= 'keyword',
    MOMENT = 'moment',
    MOMENT_KEYWORD = 'momentKeyword',
    MOMENT_SCENARIO = 'scenario',
    NEWS_CATEGORY = 'newsCategory',
    PARENTAL_STATUS = 'parentalStatus',
    TOPIC = 'topic',
}


// Shared dimensions across all TEMPLATE_TYPE's
export enum GENERIC_DIMENSION {
    ACCOUNT_ID = 'accountId',
    ACCOUNT_NAME = 'accountName',
    AD_GROUP_ID = 'adGroupId',
    AD_GROUP_NAME = 'adGroupName',
    CAMPAIGN_ID = 'camapaignId',
    CAMPAIGN_NAME = 'campaignName',
    CLIENT_PACKAGE_NAME = 'clientPackageName',
    CLIENT_PACKAGE_ID = 'clientPackageId',
    CLIENT_PLACEMENT_ID = 'clientPlacementId',
    CLIENT_PLACEMENT_NAME = 'clientPlacementName',
    CUSTOMER_ID = 'customerId',
    CUSTOMER_NAME = 'customerName',
    INSERTION_ORDER_NAME = 'insertionOrderName',
    INSERTION_ORDER_ID = 'insertionOrderId',
    PERSONA = 'persona',
    SIGHTLY_PLACEMENT_ID = 'sightlyPlacementId',
    SIGHTLY_PLACEMENT_NAME = 'sightlyPlacementName',
}


export enum METRIC {
    CLICKS ='clicks',
    COST ='cost',
    CPM ='cpm',
    CPV ='cpv',
    CPC ='cpc',
    CTR ='ctr',
    IMPRESSIONS ='impressions',
    MARGIN ='margin',
    QUARTILE_25 ='q25',
    QUARTILE_50 ='q50',
    QUARTILE_75 ='q75',
    QUARTILE_100 ='q100',
    STAT_DATE = 'statDate',
    VCR ='vcr',
    VIEWS ='views',
    VIEW_RATE ='viewRate',
}

export enum CONVERSION {
    ALL_CONVERSIONS = 'allConversions',
    ADD_TO_CART = 'addToCart',
    BEGIN_CHECKOUT = 'beginCheckout',
    BOOK_APPOINTMENT = 'bookAppointment',
    CONTACT = 'contact',
    CONVERTED_LEAD = 'convertedLead',
    // DEFAULT = 'default',
    DOWNLOAD = 'download',
    ENGAGEMENT = 'engagement',
    GET_DIRECTIONS = 'getDirections',
    IMPORTED_LEAD = 'importedLead',
    OUTBOUND_CLICK = 'outboundClick',
    PAGE_VIEW = 'pageView',
    PAGE_VISIT = 'pageVisit',
    PHONE_CALL_LEAD = 'phoneCallLead',
    PURCHASE = 'purchase',
    QUALIFIED_LEAD = 'qualifiedLead',
    REQUEST_QUOTE = 'requestQuote',
    SIGNUP = 'signup',
    STORE_SALE = 'storeSale',
    STORE_VISIT = 'storeVisit',
    SUBMIT_LEAD_FORM = 'submitLeadForm',
    SUBSCRIBE_PAID = 'subscribePaid',
    // UNKNOWN = 'unknown',
    // UNSPECIFIED = 'unspecified',
}

export interface GenericDimensionOption {
    name: string
    value: GENERIC_DIMENSION
}

export interface MetricOption {
    name: string
    value: METRIC
}

interface ConversionOption {
    name: string
    value: CONVERSION
}

interface ReportConfiguration {
    name: string,
    type: REPORT_TYPE,
    templateType: TEMPLATE_TYPE
    columns: (GENERIC_DIMENSION | METRIC | CONVERSION)[]
    icon?: string
}

export interface ReportTemplate extends ReportConfiguration{
    description: string
    dimensions: GenericDimensionOption[]
    metrics: MetricOption[]
    conversions: ConversionOption[]
}

export interface InsertionOrder {
    id: number,
    name: string
}

export interface ActivationReportConfiguration {
    insertionOrders: InsertionOrder[],
    startDate: Date,
    endDate: Date,
    splitAdFormat: boolean,
    granularity: GRANULARITY
    reports: ReportConfiguration[]
}


export interface ActivationReport {
    name: string,
    configuration: ActivationReportConfiguration
}

export type Option<T> = {
    [key: string]: T;
}

export const genericDimensionOptions: Option<GenericDimensionOption> = {
    [GENERIC_DIMENSION.ACCOUNT_ID]: {
        name: 'Account Id',
        value: GENERIC_DIMENSION.ACCOUNT_ID
    },
    [GENERIC_DIMENSION.ACCOUNT_NAME]: {
        name: 'Account Name',
        value: GENERIC_DIMENSION.ACCOUNT_NAME
    },
    [GENERIC_DIMENSION.INSERTION_ORDER_ID]: {
        name: 'Insertion Order Id',
        value: GENERIC_DIMENSION.INSERTION_ORDER_ID
    },
    [GENERIC_DIMENSION.INSERTION_ORDER_NAME]: {
        name: 'Insertion Order Name',
        value: GENERIC_DIMENSION.INSERTION_ORDER_NAME
    },
    [GENERIC_DIMENSION.CLIENT_PACKAGE_ID]: {
        name: 'Client Package Id',
        value: GENERIC_DIMENSION.CLIENT_PACKAGE_ID
    },
    [GENERIC_DIMENSION.CLIENT_PACKAGE_NAME]: {
        name: 'Client Package Name',
        value: GENERIC_DIMENSION.CLIENT_PACKAGE_NAME
    },
    [GENERIC_DIMENSION.CLIENT_PLACEMENT_ID]: {
        name: 'Client Placement Id',
        value: GENERIC_DIMENSION.CLIENT_PLACEMENT_ID
    },
    [GENERIC_DIMENSION.CLIENT_PLACEMENT_NAME]: {
        name: 'Client Placement Name',
        value: GENERIC_DIMENSION.CLIENT_PLACEMENT_NAME
    },
    [GENERIC_DIMENSION.SIGHTLY_PLACEMENT_ID]: {
        name: 'Sightly Placement Id',
        value: GENERIC_DIMENSION.SIGHTLY_PLACEMENT_ID
    },
    [GENERIC_DIMENSION.SIGHTLY_PLACEMENT_NAME]: {
        name: 'Sightly Placement Name',
        value: GENERIC_DIMENSION.SIGHTLY_PLACEMENT_NAME
    },
    [GENERIC_DIMENSION.PERSONA]: {
        name: 'Persona',
        value: GENERIC_DIMENSION.PERSONA
    },
    [GENERIC_DIMENSION.CUSTOMER_ID]: {
        name: 'Customer Id',
        value: GENERIC_DIMENSION.CUSTOMER_ID
    },
    [GENERIC_DIMENSION.CUSTOMER_NAME]: {
        name: 'Customer Name',
        value: GENERIC_DIMENSION.CUSTOMER_NAME
    },
    [GENERIC_DIMENSION.CAMPAIGN_ID]: {
        name: 'Campaign Id',
        value: GENERIC_DIMENSION.CAMPAIGN_ID
    },
    [GENERIC_DIMENSION.CAMPAIGN_NAME]: {
        name: 'Campaign Name',
        value: GENERIC_DIMENSION.CAMPAIGN_NAME
    },
    [GENERIC_DIMENSION.AD_GROUP_ID]: {
        name: 'Ad Group Id',
        value: GENERIC_DIMENSION.AD_GROUP_ID
    },
    [GENERIC_DIMENSION.AD_GROUP_NAME]: {
        name: 'Ad Group Name',
        value: GENERIC_DIMENSION.AD_GROUP_NAME
    },
}

export const metricOptions: Option<MetricOption> = {
    [METRIC.STAT_DATE]: {
        name: 'Date',
        value: METRIC.STAT_DATE
    },
    [METRIC.IMPRESSIONS]: {
        name: 'Impressions',
        value: METRIC.IMPRESSIONS
    },
    [METRIC.VIEWS]: {
        name: 'Views',
        value: METRIC.VIEWS
    },
    [METRIC.VIEW_RATE]: {
        name: 'View Rate',
        value: METRIC.VIEW_RATE
    },
    [METRIC.VCR]: {
        name: 'VCR',
        value: METRIC.VCR
    },
    [METRIC.CLICKS]: {
        name: 'Clicks',
        value: METRIC.CLICKS
    },
    [METRIC.CPM]: {
        name: 'Cpm',
        value: METRIC.CPM
    },
    [METRIC.CPV]: {
        name: 'Cpv',
        value: METRIC.CPV
    },
    [METRIC.CPC]: {
        name: 'Cpc',
        value: METRIC.CPC
    },
    [METRIC.CTR]: {
        name: 'Ctr',
        value: METRIC.CTR
    },
    [METRIC.COST]: {
        name: 'Cost',
        value: METRIC.COST
    },
    [METRIC.MARGIN]: {
        name: 'Margin',
        value: METRIC.MARGIN
    },
    [METRIC.QUARTILE_25]: {
        name: 'Video Quartile 25%',
        value: METRIC.QUARTILE_25
    },
    [METRIC.QUARTILE_50]: {
        name: 'Video Quartile 50%',
        value: METRIC.QUARTILE_50
    },
    [METRIC.QUARTILE_75]: {
        name: 'Video Quartile 75%',
        value: METRIC.QUARTILE_75
    },
    [METRIC.QUARTILE_100]: {
        name: 'Video Quartile 100%',
        value: METRIC.QUARTILE_100
    },
}

export const conversionOptions: Option<ConversionOption> = {
    [CONVERSION.ALL_CONVERSIONS]: {
        name: 'All Conversions',
        value: CONVERSION.ALL_CONVERSIONS
    },
    [CONVERSION.ADD_TO_CART]: {
        name: 'Add To Cart',
        value: CONVERSION.ADD_TO_CART
    },
    [CONVERSION.BEGIN_CHECKOUT]: {
        name: 'Begin Checkout',
        value: CONVERSION.BEGIN_CHECKOUT
    },
    [CONVERSION.BOOK_APPOINTMENT]: {
        name: 'Book Appointment',
        value: CONVERSION.BOOK_APPOINTMENT
    },
    [CONVERSION.CONVERTED_LEAD]: {
        name: 'Converted Lead',
        value: CONVERSION.CONVERTED_LEAD
    },
    // [CONVERSION.DEFAULT]: {
    //     name: 'Default',
    //     value: CONVERSION.DEFAULT
    // },
    [CONVERSION.DOWNLOAD]: {
        name: 'Download',
        value: CONVERSION.DOWNLOAD
    },
    [CONVERSION.ENGAGEMENT]: {
        name: 'Engagement',
        value: CONVERSION.ENGAGEMENT
    },
    [CONVERSION.GET_DIRECTIONS]: {
        name: 'Get Directions',
        value: CONVERSION.GET_DIRECTIONS
    },
    [CONVERSION.IMPORTED_LEAD]: {
        name: 'Imported Lead',
        value: CONVERSION.IMPORTED_LEAD
    },
    [CONVERSION.OUTBOUND_CLICK]: {
        name: 'Outbound Click',
        value: CONVERSION.OUTBOUND_CLICK
    },
    [CONVERSION.PAGE_VIEW]: {
        name: 'Page View',
        value: CONVERSION.PAGE_VIEW
    },
    [CONVERSION.PHONE_CALL_LEAD]: {
        name: 'Phone Call Lead',
        value: CONVERSION.PHONE_CALL_LEAD
    },
    [CONVERSION.QUALIFIED_LEAD]: {
        name: 'Qualified Lead',
        value: CONVERSION.QUALIFIED_LEAD
    },
    [CONVERSION.REQUEST_QUOTE]: {
        name: 'Request Quote',
        value: CONVERSION.REQUEST_QUOTE
    },
    [CONVERSION.SIGNUP]: {
        name: 'Signup',
        value: CONVERSION.SIGNUP
    },
    [CONVERSION.STORE_SALE]: {
        name: 'Store Sale',
        value: CONVERSION.STORE_SALE
    },
    [CONVERSION.SUBMIT_LEAD_FORM]: {
        name: 'Submit Lead Form',
        value: CONVERSION.SUBMIT_LEAD_FORM
    },
    [CONVERSION.SUBSCRIBE_PAID]: {
        name: 'Subscriber paid',
        value: CONVERSION.SUBSCRIBE_PAID
    },
    // [CONVERSION.UNKNOWN]: {
    //     name: 'Unknown',
    //     value: CONVERSION.UNKNOWN
    // },
    // [CONVERSION.UNSPECIFIED]: {
    //     name: 'Unspecified',
    //     value: CONVERSION.UNSPECIFIED
    // },
    [CONVERSION.CONTACT]: {
        name: 'Contact',
        value: CONVERSION.CONTACT
    },
    [CONVERSION.PAGE_VISIT]: {
        name: 'Page Visit',
        value: CONVERSION.PAGE_VISIT
    },
    [CONVERSION.STORE_VISIT]: {
        name: 'Store Visit',
        value: CONVERSION.STORE_VISIT
    },
    [CONVERSION.PURCHASE]: {
        name: 'Purchase',
        value: CONVERSION.PURCHASE
    },
}

export const templateGenericDimensionOptions:GenericDimensionOption[] = Object.keys(genericDimensionOptions).map(key => genericDimensionOptions[key])
export const templateMetricOptions:MetricOption[] = Object.keys(metricOptions).map(key => metricOptions[key])
export const templateConversionOptions:ConversionOption[] = Object.keys(conversionOptions).map(key => conversionOptions[key])
