import React from 'react';
import { ReportFiltersItems } from '@/views/ActivationReports/components/ReportFiltersItems'
import SearchBox from '@/components/SearchBox';

interface IProps {
    tableData: any[]
    handleTableData: Function
}


export const ReportFilters = ({tableData, handleTableData} : IProps) => {

    return (
        <div className='mx-8 mt-2'>
            <SearchBox
                dataTestId="check-tree-data-picker-search-box"
                handleSearch={(keyword) => { }}
                placeholder="Search by name"
                className='mb-3'
            />
            <ReportFiltersItems filters={['Age Range', 'Detail']} filterName={'Dimensions'} tableData={tableData} handleDataTable={handleTableData} />
            <div className='mb-6' />
            <ReportFiltersItems filters={['Clicks', 'CPC']} filterName={'Metrics'} tableData={tableData} handleDataTable={handleTableData}/>
            <div className='mb-6' />
            <ReportFiltersItems filters={['Clicks 1', 'CPC2 ']} filterName={'Conversions'} tableData={tableData} handleDataTable={handleTableData}/>
            <div className='mb-6' />
        </div>
    )
}
