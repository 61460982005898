import React from 'react'
import { Loader } from 'rsuite'

import SlideOver from '@/components/SlideOver'
import SearchBox from '@/components/SearchBox'
import { Footer } from '@/views/TikTok/ActivationManager/Footer'
import { ActivationStatusType, SelectedHashtagType } from '@/views/TikTok/Hashtags/types'
import { CategoryFilter } from '@/views/TikTok/ActivationManager/CategoryFilter'
import ActivationsTable from '@/views/TikTok/ActivationManager/ActivationsTable/index'
import {
  FinalActivationType,
  ListedCategoryType,
  TargetedActivationListType
} from '@/views/TikTok/ActivationManager/types'
import { useTargetedActivationList } from '@/views/TikTok/ActivationManager/ActivationManagerContext'
import { useSubmitTargetActivations } from '@/views/TikTok/ActivationManager/hooks/useSubmitTargetActivations'
import { ACTIVATION_STATUS_MESSAGE, SUBMITTING_ACTIVATIONS } from '@/views/TikTok/ActivationManager/constants'
import { SerializableMap } from '@/utils/classes/SerializableMap'
import useUser from '@/hooks/useUser'
import { perms } from '@/staticData/permissions'
import { products } from '@/staticData/products'
import { SubmitActivationsType } from '@/views/TikTok/Activations/types'

type IProps = {
  open: boolean
  setOpen: (val: boolean) => void
  setActivationStatus: React.Dispatch<React.SetStateAction<ActivationStatusType | undefined>>
  setSelectedHashtags: React.Dispatch<React.SetStateAction<SerializableMap<string, SelectedHashtagType>>>
}

const ActivationManager = ({ open, setOpen, setSelectedHashtags, setActivationStatus }: IProps) => {
  const targetActivationList = useTargetedActivationList()
  const { currentAccount } = useUser()
  const [isFullScreen, setIsFullScreen] = React.useState(false)
  const [disabledCreateButtonReasons, setDisabledCreateButtonReason] = React.useState<Set<string>>()
  const [categoryFilters, setCategoryFilters] = React.useState<string[]>([])
  const { userCanService } = useUser()

  const canUserCreateActivation = () => {
    if (!userCanService(perms.TIKTOK_ACTIVATIONS_UPDATE, products.TIKTOK_ACTIVATIONS)) {
      setDisabledCreateButtonReason((prev) => {
        const next = new Set(prev)
        next.add('Insufficient Access Privilege. Please contact us.')
        return next
      })
      return false
    }
    return true
  }

  const canActivationListBeSubmitted = () => {
    if (!targetActivationList.state.size) return false

    const targetedActivations = Array.from(targetActivationList.state.values()).flat()
    const hasSomeTargetedHashtags = targetedActivations.some(({ campaignsToAdGroups }) => {
      return Array.from(campaignsToAdGroups.entries()).some(
        ([campaignId, adGroups]) => campaignId && !(campaignId % 1) && adGroups.size > 0
      )
    })
    if (!hasSomeTargetedHashtags) {
      setDisabledCreateButtonReason((prev) => {
        const next = new Set(prev)
        next.add('At least 1 hashtag should be targeted.')
        return next
      })
    }

    return hasSomeTargetedHashtags
  }

  const disabledCreateTargetMoment = React.useMemo(() => {
    if (!canUserCreateActivation()) return true

    if (!canActivationListBeSubmitted()) {
      return true
    }
    return false
  }, [targetActivationList.state])

  const updateActivationStatus = (data: SubmitActivationsType) => {
    if (!data) return
    const { hashtagsCountActivated, hashtagsCountFailed } = data.data

    if (hashtagsCountActivated && hashtagsCountFailed) {
      setActivationStatus(() => [
        { type: 'SUCCESS', message: ACTIVATION_STATUS_MESSAGE.SUCCESS(hashtagsCountActivated) },
        { type: 'PARTIAL_FAILURE', message: ACTIVATION_STATUS_MESSAGE.PARTIAL_FAILURE(hashtagsCountFailed) }
      ])
      return
    }

    if (hashtagsCountActivated) {
      setActivationStatus(() => [
        { type: 'SUCCESS', message: ACTIVATION_STATUS_MESSAGE.SUCCESS(hashtagsCountActivated) }
      ])
      return
    }

    hashtagsCountFailed && setActivationStatus(() => [{ type: 'FAILURE', message: ACTIVATION_STATUS_MESSAGE.FAILURE }])
  }

  const submitTargetActivations = useSubmitTargetActivations({
    accountId: currentAccount?.accountId!,
    setOpen,
    setSelectedHashtags,
    updateActivationStatus
  })

  const categoryOptions = React.useMemo(() => {
    const categories: Set<string> = new Set()
    targetActivationList.state.forEach(({ categoryName }) => {
      categories.add(categoryName)
    })
    const options: ListedCategoryType = []
    categories.forEach((category) =>
      options.push({
        categoryId: category,
        categoryName: category
      })
    )
    return options
  }, [])

  const filteredHashtagsByCategory = React.useMemo(() => {
    if (!categoryFilters || !categoryFilters.length) {
      return targetActivationList.state
    }
    const filteredHashtags = new SerializableMap<string, TargetedActivationListType>()

    targetActivationList.state.forEach((activation, hashtagId) => {
      if (categoryFilters.includes(activation.categoryName)) {
        filteredHashtags.set(hashtagId, activation)
      }
    })
    return filteredHashtags
  }, [targetActivationList.state, categoryFilters])

  const [searchedHashtagsIds, setSearchedHashtagsIds] = React.useState<string[]>([])

  React.useEffect(() => {
    setSearchedHashtagsIds((prev) => Array.from(filteredHashtagsByCategory.keys()))
  }, [categoryFilters])

  React.useEffect(() => {
    setSelectedHashtags(
      () =>
        new SerializableMap(
          Array.from(targetActivationList.state.entries()).map(([hashtagId, { hashtagName, categoryName }]) => [
            hashtagId,
            { hashtagId, hashtagName, categoryName }
          ])
        )
    )
    setSearchedHashtagsIds((prev) => prev.filter((id) => targetActivationList.state.has(id)))

    if (targetActivationList.state.size === 0) setOpen(false)
  }, [targetActivationList.state])

  const filteredActivations = React.useMemo(() => {
    const _filteredActivations = new SerializableMap<string, TargetedActivationListType>()
    targetActivationList.state.forEach((activation, hashtagId) => {
      if (!searchedHashtagsIds.includes(hashtagId)) return
      if (categoryFilters.length && !categoryFilters.includes(activation.categoryName)) return
      _filteredActivations.set(hashtagId, activation)
    })
    return _filteredActivations
  }, [searchedHashtagsIds, targetActivationList.state, categoryFilters])

  const handleSearchHashtags = React.useCallback(
    (searchKey: string) => {
      const filteredHashtagIds: string[] = []
      filteredHashtagsByCategory.forEach((activation, hashtagId) => {
        if (activation.hashtagName.toLowerCase().includes(searchKey.toLowerCase())) {
          filteredHashtagIds.push(hashtagId)
        }
      })
      setSearchedHashtagsIds(() => [...filteredHashtagIds])
    },
    [filteredHashtagsByCategory]
  )

  const handleCreateTargetHashtags = async () => {
    if (!targetActivationList.state.size) return
    const targetedActivations = Array.from(targetActivationList.state.values()).flat()
    const finalTargetedActivations: FinalActivationType[] = []
    targetedActivations.forEach(({ hashtagId, campaignsToAdGroups }) => {
      const activation: FinalActivationType = {
        hashtagId,
        campaignToAdGroups: new Array<{ campaignId: number; adGroupIds: number[] }>()
      }
      Array.from(campaignsToAdGroups.entries()).forEach(([campaignId, adGroups]) => {
        if (campaignId && !(campaignId % 1) && adGroups.size > 0) {
          activation.campaignToAdGroups.push({ campaignId, adGroupIds: Array.from(adGroups) })
        }
      })
      finalTargetedActivations.push(activation)
    })
    submitTargetActivations?.mutate({ activations: finalTargetedActivations })
  }

  return (
    <SlideOver
      dataTestId="tiktok-act-mgr-target-moment-drawer"
      headerBackgroundColor="bg-sightlyBlue"
      headerTitle="New Target List"
      headerDescription="Get started by filling in the information below to create your new target list."
      show={open}
      onHide={() => setOpen(false)}
      fullScreen={isFullScreen}
      maxOffset="345px - 7em"
      minOffset="7em"
      footer={
        <Footer
          disabledCreateButton={disabledCreateTargetMoment}
          disabledCreateButtonReasons={disabledCreateButtonReasons}
          handleConfirmClick={handleCreateTargetHashtags}
          setOpen={setOpen}
        />
      }
    >
      <label className="px-4 pt-6 mb-2 font-medium sm:px-6 text-grey-900">Hashtags ({filteredActivations.size})</label>

      <div className="sticky flex justify-between px-6 py-2">
        <div className="w-[200px]">
          <CategoryFilter
            categoryOptions={categoryOptions}
            handleFilterCategory={(categoryFilters: string[]) => {
              setCategoryFilters(() => categoryFilters)
            }}
          />
        </div>
        <SearchBox
          handleSearch={handleSearchHashtags}
          placeholder="Search Hashtags"
          dataTestId="tiktok-act-mgr-search-hashtags"
        />
      </div>
      <ActivationsTable
        {...{
          setSearchedHashtagsIds,
          filteredActivations
        }}
      />
      {submitTargetActivations?.isLoading && (
        <Loader
          backdrop
          content={SUBMITTING_ACTIVATIONS}
          vertical
          className="z-10"
          speed="slow"
        />
      )}
    </SlideOver>
  )
}

export default ActivationManager
