import { useQuery } from '@tanstack/react-query'
import { rqKeys } from '@/ReactQueryKeyFactory'
import { useFlag } from '@unleash/proxy-client-react'
import config from '@/config'
import { userAccountAxios } from '@/axiosInstances'
import {
  ApiHookProps,
  SearchResult,
  SearchResultValidationSchema,
  Item,
  ItemDetail,
  itemsValidationSchema,
  itemsValidationWithDescriptionSchema,
  BoardSchema,
  BoardSchemaType
} from './types'
import { logError } from '@/utils'
import { GENERAL_BOARD_DEFAULT_TYPES, SUGGESTED_BOARD_NAMES } from '@/views/BrandProfiles/constants'

import trendingMomentsSvg from '@/assets/img/boardTemplate/trending_moments_board_template.svg'
import industrySvg from '@/assets/img/boardTemplate/industry_board_template.svg'
import competitorSvg from '@/assets/img/boardTemplate/competitor_board_template.svg'
import influencerSvg from '@/assets/img/boardTemplate/influencer_board_template.svg'
import safeGuardSvg from '@/assets/img/boardTemplate/safeGuard_board_template.svg'
import relevanceSvg from '@/assets/img/boardTemplate/relevance_template_icon.svg'

export const useSemanticSearch = (brandProfileId: number | undefined, searchTerm: string, boardType: string) => {
  const searchAylienIndustriesQuery = useQuery(
    rqKeys.searchedAylienIndustriesQueryKey(searchTerm, brandProfileId),
    async () => {
      if (!brandProfileId) return
      const url = `/brand-profile/${brandProfileId}/board-aylien-industries?boardType=${boardType}&searchTerm=${searchTerm}`
      const aylienIndustriesSearchResult = await userAccountAxios.get<SearchResult[]>(url)
      SearchResultValidationSchema.parse(aylienIndustriesSearchResult.data)
      return aylienIndustriesSearchResult.data
    },
    {
      onError: (err) => {
        logError(err)
      },
      enabled: false
    }
  )

  const searchAylienNewsQuery = useQuery(
    rqKeys.searchedAylienNewsQueryKey(searchTerm, brandProfileId),
    async () => {
      if (!brandProfileId) return
      const url = `/brand-profile/${brandProfileId}/board-aylien-news?boardType=${boardType}&searchTerm=${searchTerm}`
      const aylienNewsSearchResult = await userAccountAxios.get<SearchResult[]>(url)
      SearchResultValidationSchema.parse(aylienNewsSearchResult.data)
      return aylienNewsSearchResult.data
    },
    {
      onError: (err) => {
        logError(err)
      },
      enabled: false
    }
  )

  return {
    searchAylienIndustriesQuery,
    searchAylienNewsQuery
  }
}

export const useBoardsAPI = ({
  brandProfileId,
  boardId,
  boardType,
  setQueries,
  setAylienNews,
  setSearchedAylienNews,
  setAylienIndustries,
  setSearchedAylienIndustries,
  setAggregates,
  setBoard,
  setAreQueriesLoading,
  setAreAylienIndustriesLoading,
  setAreAylienNewsLoading
}: ApiHookProps) => {
  const brandRelevanceEnabled = useFlag(`enable_brand_relevance_${config.environment}`)

  const fetchBrandProfileQueries = async (board?: BoardSchemaType) => {
    try {
      const url = `/brand-profile/${brandProfileId}/queries`
      const bpQueries = await userAccountAxios.get<Item[]>(url)
      itemsValidationSchema.parse(bpQueries.data)
      bpQueries.data.map((data) => {
        return data.items.map((item) => {
          item.targeted = true
        })
      })
      //Edit Board
      if (board) {
        setQueries(setSelectedTaxonomy(board.queries, bpQueries.data))
        setAreQueriesLoading(false)
        return
      }
      setQueries(bpQueries.data)
      setAreQueriesLoading(false)
    } catch (error) {
      console.error(error)
    }
  }
  const fetchBrandProfileAylienNews = async (board?: BoardSchemaType) => {
    try {
      const url = `/brand-profile/${brandProfileId}/board-aylien-news?boardType=${boardType}`

      if (boardType === 'general') {
        const _aylienNews = await userAccountAxios.get<ItemDetail[]>(url)
        const _formatted = [
          {
            name: 'News',
            items: _aylienNews.data
          }
        ]
        setAylienNews(_formatted)
        setSearchedAylienNews(_formatted)
      } else {
        const aylienNewsResponse = await userAccountAxios.get<Item[]>(url)
        itemsValidationWithDescriptionSchema.parse(aylienNewsResponse.data)
        //Edit Board
        if (board) {
          const news = setSelectedTaxonomy(board.news, aylienNewsResponse.data)
          setAylienNews(news)
          setSearchedAylienNews(news)
          setAreAylienNewsLoading(false)
          return
        }
        setAylienNews(aylienNewsResponse.data)
        setSearchedAylienNews(aylienNewsResponse.data)
      }
      setAreAylienNewsLoading(false)
    } catch (error) {
      console.error(error)
    }
  }
  const fetchBrandProfileAylienIndustries = async (board?: BoardSchemaType) => {
    try {
      const url = `/brand-profile/${brandProfileId}/board-aylien-industries?boardType=${boardType}`
      if (boardType === 'general') {
        const _aylienIndustries = await userAccountAxios.get<ItemDetail[]>(url)
        const _formatted = [
          {
            name: 'Industries',
            items: _aylienIndustries.data
          }
        ]
        setAylienIndustries(_formatted)
        setSearchedAylienIndustries(_formatted)
      } else {
        const aylienIndustriesResponse = await userAccountAxios.get<Item[]>(url)
        itemsValidationWithDescriptionSchema.parse(aylienIndustriesResponse.data)
        //Edit Board
        if (board) {
          const industries = setSelectedTaxonomy(board.industries, aylienIndustriesResponse.data)
          setAylienIndustries(industries)
          setSearchedAylienIndustries(industries)
          setAreAylienIndustriesLoading(false)
          return
        }
        setAylienIndustries(aylienIndustriesResponse.data)
        setSearchedAylienIndustries(aylienIndustriesResponse.data)
      }
      setAreAylienIndustriesLoading(false)
    } catch (error) {
      console.error(error)
    }
  }
  const fetchBrandProfileAggregates = async () => {
    try {
      const url = `/brand-profile/${brandProfileId}/board/default-options`
      const res = await userAccountAxios.get(url)
      const _formatted = [
        {
          name: 'Suggested Boards',
          items: [
            {
              id: 4444444444,
              name: SUGGESTED_BOARD_NAMES.TRENDING_MOMENTS,
              defaultType: GENERAL_BOARD_DEFAULT_TYPES.TRENDING_MOMENTS,
              alwaysDisabled: false,
              targeted: true,
              description: 'A board displaying moments that have the highest amount of recently published articles',
              img: trendingMomentsSvg,
              hoverText: 'A board displaying moments that have the highest amount of recently published articles'
            },
            {
              id: 1111111111,
              name: SUGGESTED_BOARD_NAMES.INDUSTRY,
              defaultType: GENERAL_BOARD_DEFAULT_TYPES.INDUSTRY,
              alwaysDisabled: !res.data.hasIndustry,
              targeted: true,
              description: 'A board displaying moments that mention the brand or the industry',
              img: industrySvg,
              hoverText: 'A board displaying moments that mention the brand or the industry'
            },
            {
              id: 2222222222,
              name: SUGGESTED_BOARD_NAMES.COMPETITORS,
              defaultType: GENERAL_BOARD_DEFAULT_TYPES.COMPETITORS,
              alwaysDisabled: !res.data.hasCompetitors,
              targeted: true,
              description: `A board displaying moments that mention the brand's competitors`,
              img: competitorSvg,
              hoverText: `A board displaying moments that mention the brand's competitors`
            },
            {
              id: 3333333333,
              name: SUGGESTED_BOARD_NAMES.INFLUENCERS,
              defaultType: GENERAL_BOARD_DEFAULT_TYPES.INFLUENCERS,
              alwaysDisabled: !res.data.hasInfluencers,
              targeted: true,
              description: `A board displaying moments that mention the brand's influencers`,
              img: influencerSvg,
              hoverText: `A board displaying moments that mention the brand's influencers`
            },
            {
              id: 5555555555,
              name: SUGGESTED_BOARD_NAMES.SAFEGUARD,
              defaultType: GENERAL_BOARD_DEFAULT_TYPES.SAFEGUARD,
              alwaysDisabled: false,
              targeted: true,
              description: 'A board displaying moments that mention sensitive topics',
              img: safeGuardSvg,
              hoverText: 'A board displaying moments that mention sensitive topics'
            },
            ...(brandRelevanceEnabled ?
              [{
                id: 6666666666,
                name: SUGGESTED_BOARD_NAMES.RELEVANCE,
                defaultType: GENERAL_BOARD_DEFAULT_TYPES.RELEVANCE,
                alwaysDisabled: false,
                targeted: true,
                description: 'A board displaying moments that have the highest relevance to your brand',
                img: relevanceSvg,
                hoverText: 'A board displaying moments that have the highest relevance to your brand'
              }]:[])
          ]
        }
      ]

      setAggregates(_formatted)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchBoardById = async () => {
    try {
      const url = `/brand-profile/${brandProfileId}/board/${boardId}`
      const res = await userAccountAxios.get(url)
      const parsed = BoardSchema.parse(res.data)
      parsed && setBoard && setBoard(parsed)
      return parsed
    } catch (error) {
      console.error(error)
    }
  }

  return {
    fetchBrandProfileAggregates,
    fetchBrandProfileAylienIndustries,
    fetchBrandProfileAylienNews,
    fetchBrandProfileQueries,
    fetchBoardById
  }
}

const setSelectedTaxonomy = (items: number[], data: Item[]) => {
  const boardItem = new Set(items)
  return data.map((groupItem) => {
    return {
      ...groupItem,
      items: groupItem.items.map((item) => {
        return { ...item, selected: boardItem.has(item.id) }
      })
    }
  })
}
