import React from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import SightlyButton from '@/components/Sightly/SightlyButton'
import { ReactComponent as KeywordsIcon } from '@/assets/img/keywordsKey.svg'
import { ReactComponent as BlackYoutube } from '@/assets/img/blackYoutube.svg'
import { ClusterType } from './types'
import { useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '@/classes/utils'
import useBrandProfileInsertionOrders from '@/hooks/brandProfile/useBrandProfileInsertionOrders'
import SightlyTagInput from '@/components/Sightly/SightlyTagInput'
import { SightlyInput } from '@/components/Sightly/SightlyFormElements/SightlyInput'
import { SmallBadge } from '@/components/SmallClosableBadge'
import { TargetListProps } from './api'
import { TrashIcon } from '@heroicons/react/20/solid'
import { ConfirmationModalTailwind } from '@/components/ConfirmationModalTailwind'
import { FormError } from '@/components/Sightly/SightlyFormElements/SightlyFormError'
import { logError } from '@/utils'

const isSightlyEmail = (val: string) => {
    return val.toLowerCase().includes('@sightly.com');
}

interface IProps {
    open: boolean
    setOpen: (val: boolean) => void
    selectedMoments: ClusterType[]
    handleTarget: (args: TargetListProps) => void
    handleSelectMoment: (moment: ClusterType) => void
}

export function TargetSlideOver({ open, setOpen, selectedMoments, handleTarget, handleSelectMoment }: IProps) {
    const { brandProfileId } = useSearch<MyLocationGenerics>()
    const iosQuery = useBrandProfileInsertionOrders(brandProfileId)
    const [unselectedIos, setUnselectedIos] = React.useState<number[]>([])
    const [emails, setEmails] = React.useState<string[]>([])
    const [message, setMessage] = React.useState('')
    const [personas, setPersonas] = React.useState<{ persona: string; clusterId: number }[]>([])
    const [showingConfirmRemove, setShowingConfirmRemove] = React.useState<null | number>(null)
    const displayedIos = React.useMemo(() => {
        if (!iosQuery.data) return []
        if (process.env.REACT_APP_STAGE != 'production') return [{
            "ioId": 5873,
            "ioName": "Block List Test",
            "ioMapped": true
        }]
        return iosQuery.data.filter((io) => !unselectedIos.includes(io.ioId)) || []
    }, [iosQuery.data, unselectedIos])

    React.useEffect(() => {
        setUnselectedIos([])
    }, [open])

    const clustersWithPersonas = selectedMoments.map((_moment) => {
        return {
            id: _moment.clusterId,
            personas: personas.filter((persona) => persona.clusterId === _moment.clusterId).map((_persona) => _persona.persona),
            clusterName: _moment.clusterName
        }
    })

    const handleConfirmClick = () => {
        if (!brandProfileId) return
        const args: TargetListProps = {
            assignees: emails,
            brandProfileId,
            message,
            clusters: clustersWithPersonas,
            ioIds: displayedIos.map((io) => io.ioId)
        }
        handleTarget({ ...args })
        setOpen(false)
        setMessage('')
        setEmails([])
        setPersonas([])
    }

    return (
        <Transition.Root
            show={open}
            as={Fragment}
        >
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => setOpen(false)}
            >
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="w-screen max-w-3xl pointer-events-auto">
                                    <div className="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl">
                                        <div className="flex flex-col flex-1 min-h-0 pb-6">
                                            <div className="px-4 pt-6 pb-6 text-white sm:px-6 bg-sightly-blue">
                                                <div className="flex items-start justify-between">
                                                    <Dialog.Title className="text-lg font-mediu">
                                                        New Target List
                                                    </Dialog.Title>

                                                    <div className="flex items-center ml-3 h-7">
                                                        <button
                                                            type="button"
                                                            className="text-white rounded-md bg-sightly-blue hover:text-slate-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                            onClick={() => setOpen(false)}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon
                                                                className="w-6 h-6"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                                <Dialog.Description>
                                                    Get started by filling in the information below to create your new
                                                    target list.
                                                </Dialog.Description>
                                            </div>

                                            <div className="px-4 pt-6 sm:px-6 text-grey-900">
                                                <div className="mb-2 font-medium">Assignees</div>

                                                <SightlyTagInput
                                                    placeholder="Enter Sightly emails here"
                                                    setSelectedTags={(emails: string[]) => setEmails(emails)}
                                                    tags={emails}
                                                    inputIsValid={isSightlyEmail}
                                                    invalidWarningText="Must be a Sightly email address"
                                                />
                                                <FormError
                                                    id={'assigneeError'}
                                                    error={
                                                        emails.length === 0
                                                            ? 'At least one Sightly email is required'
                                                            : ''
                                                    }
                                                />
                                            </div>

                                            <div className="px-4 pt-6 sm:px-6 text-grey-900">
                                                <div className="mb-2 font-medium">Message</div>
                                                <SightlyInput
                                                    id="message"
                                                    textarea={true}
                                                    onChange={(val: string) => setMessage(val)}
                                                    value={message}
                                                />
                                            </div>

                                            <div className="px-4 pt-6 sm:px-6 text-grey-900">
                                                <div className="mb-2 font-medium">Insertion Orders</div>
                                                <div className="flex flex-wrap gap-4 pb-6 font-semibold text-grey-900">
                                                    {displayedIos.map((io) => {
                                                        return (
                                                            <SmallBadge
                                                                disabled={displayedIos.length < 2}
                                                                key={io.ioId}
                                                                text={io.ioName}
                                                                onClose={() =>
                                                                    setUnselectedIos((prev) => prev.concat(io.ioId))
                                                                }
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            </div>

                                            <div className="relative flex-1 mt-6 overflow-y-auto">
                                                <div className="inline-block min-w-full py-2 align-middle md:px-6">
                                                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                        <table className="min-w-full divide-y divide-gray-300">
                                                            <thead className="bg-gray-50">
                                                                <tr>
                                                                    <th
                                                                        scope="col"
                                                                        className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6"
                                                                    >
                                                                        Moment
                                                                    </th>

                                                                    <th
                                                                        scope="col"
                                                                        className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                                                                    >
                                                                        Persona
                                                                    </th>

                                                                    <th
                                                                        scope="col"
                                                                        className="relative py-3.5"
                                                                    >
                                                                        <span className="sr-only">Edit</span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="bg-white divide-y divide-gray-200 ">
                                                                {selectedMoments.map((moment) => (
                                                                    <tr key={moment.clusterId}>
                                                                        <td className="max-w-sm py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
                                                                            <div className="truncate">
                                                                                {moment.clusterName}
                                                                            </div>
                                                                            <div className="mt-2 statsContainer">
                                                                                <div
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center'
                                                                                    }}
                                                                                >
                                                                                    <KeywordsIcon />
                                                                                    <div className="pl-1 text-xs text-gray-500">
                                                                                        { moment.targetKeywordsCount === 1
                                                                                            ? '1 keyword'
                                                                                            : moment.targetKeywordsCount +
                                                                                              ' keywords'}
                                                                                    </div>
                                                                                </div>

                                                                                <div
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center'
                                                                                    }}
                                                                                >
                                                                                    <BlackYoutube />
                                                                                    <div className="pl-1 text-xs text-gray-500">
                                                                                        {moment.videoCount === 1
                                                                                            ? '1 video'
                                                                                            : moment.videoCount +
                                                                                              ' videos'}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                                                            <PersonaCell
                                                                                personas={personas
                                                                                    .filter(
                                                                                        (p) =>
                                                                                            p.clusterId ===
                                                                                            moment.clusterId
                                                                                    )
                                                                                    .map((pe) => pe.persona)}
                                                                                setPersonas={(_personas: string[]) => {
                                                                                    const oldPersonas = personas.filter(
                                                                                        (p) =>
                                                                                            p.clusterId !==
                                                                                            moment.clusterId
                                                                                    )
                                                                                    const newPersonas = _personas.map(
                                                                                        (_persona) => {
                                                                                            return {
                                                                                                clusterId:
                                                                                                    moment.clusterId,
                                                                                                persona: _persona
                                                                                            }
                                                                                        }
                                                                                    )
                                                                                    setPersonas(
                                                                                        oldPersonas.concat(newPersonas)
                                                                                    )
                                                                                }}
                                                                            />
                                                                        </td>

                                                                        <td className="relative py-4 pr-3 text-sm font-medium text-right whitespace-nowrap">
                                                                            <TrashIcon
                                                                                onClick={() =>
                                                                                    setShowingConfirmRemove(
                                                                                        moment.clusterId
                                                                                    )
                                                                                }
                                                                                className="h-4 rounded hover:cursor-pointer hover:bg-slate-100"
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex justify-end flex-shrink-0 gap-3 px-4 py-4">
                                            <SightlyButton
                                                type="secondary"
                                                text="Cancel"
                                                id="cancelTargetButton"
                                                handleClick={() => setOpen(false)}
                                            />

                                            <SightlyButton
                                                disabled={emails.length < 1 || displayedIos.length < 1}
                                                text="Create"
                                                id="confirmTargetButton"
                                                handleClick={() => setOpen(false)}
                                            />
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                    <ConfirmationModalTailwind
                        open={!!showingConfirmRemove}
                        title="Warning"
                        subtitle={'Are you sure you want to remove this moment?'}
                        handleConfirm={() => {
                            try {
                                const newMoment = selectedMoments.filter((m) => m.clusterId === showingConfirmRemove)[0]
                                handleSelectMoment(newMoment)
                                setShowingConfirmRemove(null)
                            } catch (err) {
                                logError(err, {
                                    info: 'const newMoment = selectedMoments.filter((m) => m.clusterId === showingConfirmRemove)[0]'
                                })
                            }
                        }}
                        handleCancel={() => setShowingConfirmRemove(null)}
                    />
                    <style>
                        {`
                          .statsContainer {
                              display: grid;
                              grid-template-columns: 130px 130px 130px;
                              grid-auto-flow: row;	
                          }
				                `}
                    </style>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

const PersonaCell = ({ personas, setPersonas }: { personas: string[]; setPersonas: (val: string[]) => void }) => {
    const [isAdding, setIsAdding] = React.useState(false)
    return (
        <div>
            {isAdding || personas.length > 0 ? (
                <SightlyTagInput
                    placeholder="Enter personas here"
                    setSelectedTags={(pers: string[]) => setPersonas(pers)}
                    tags={personas}
                    inputIsValid={(val: string) => !!val}
                />
            ) : (
                <div
                    className="text-sightly-blue hover:cursor-pointer"
                    onClick={() => setIsAdding(true)}
                >
                    Add persona
                </div>
            )}
        </div>
    )
}
