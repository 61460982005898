import {ProductQuotaTally} from "@/hooks/subscription/useProductUsage";

export const getProductUsageProgressValues = (productQuotaTally: ProductQuotaTally)=> {
  let progressPercentage: number
  let progressSubText: string

  if ((!productQuotaTally?.currentProductCount || !productQuotaTally?.productQuota)) {
    progressPercentage = 0
    progressSubText = 'Unknown User Quota'
  } else {
    const { currentProductCount, productQuota } = productQuotaTally

    progressPercentage = (currentProductCount / productQuota) * 100
    progressSubText = `${currentProductCount}/${productQuota} Users`
  }

  return {
    percentage: progressPercentage,
    text: progressSubText
  }
}
