import {
  ReportTemplate,
  REPORT_TYPE,
  Option,
  TEMPLATE_TYPE,
  templateGenericDimensionOptions,
  templateMetricOptions,
  templateConversionOptions,
  GENERIC_DIMENSION,
  METRIC,
  CONVERSION,
  GenericDimensionOption, MetricOption,
} from "@/views/ReportGenerator/types/report-generator.types";


export enum MOMENT_KEYWORD_DIMENSION {
  CLUSTER_ID = 'clusterId',
  CLUSTER_NAME = 'clusterName',
  DEVICE = 'device',
  KEYWORD_TEXT = 'keywordText',
}
interface MomentKeywordDimensionOption extends Omit<GenericDimensionOption, 'value'> {
  name: string
  value: GENERIC_DIMENSION | MOMENT_KEYWORD_DIMENSION
}

interface MomentKeywordReport extends Omit<ReportTemplate, 'columns' | 'dimensions'> {
  columns: (GENERIC_DIMENSION | MOMENT_KEYWORD_DIMENSION | METRIC | CONVERSION)[],
  dimensions: (GenericDimensionOption | MomentKeywordDimensionOption)[]
}

export const dimensionOptions: Option<MomentKeywordDimensionOption> = {
  [MOMENT_KEYWORD_DIMENSION.CLUSTER_ID]: {
    name: 'Cluster Id',
    value: MOMENT_KEYWORD_DIMENSION.CLUSTER_ID
  },
  [MOMENT_KEYWORD_DIMENSION.CLUSTER_NAME]: {
    name: 'Cluster Name',
    value: MOMENT_KEYWORD_DIMENSION.CLUSTER_NAME
  },
  [MOMENT_KEYWORD_DIMENSION.DEVICE]: {
    name: 'Device',
    value: MOMENT_KEYWORD_DIMENSION.DEVICE
  },
  [MOMENT_KEYWORD_DIMENSION.KEYWORD_TEXT]: {
    name: 'Keyword',
    value: MOMENT_KEYWORD_DIMENSION.KEYWORD_TEXT
  },
}

export const baseMomentKeywordReport: MomentKeywordReport = {
  name: 'Moment Keyword Report',
  description: '',
  type: REPORT_TYPE.DETAIL,
  templateType: TEMPLATE_TYPE.MOMENT_KEYWORD,
  columns: [],
  dimensions: [
    ...templateGenericDimensionOptions,
    ...Object.keys(dimensionOptions).map(key => dimensionOptions[key])
  ],
  metrics: templateMetricOptions,
  conversions: templateConversionOptions
}
