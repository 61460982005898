import React from 'react';
import Table from 'rsuite/lib/Table';
import Icon from 'rsuite/lib/Icon';
import Popover from 'rsuite/lib/Popover';
import Whisper from 'rsuite/lib/Whisper';
import Dropdown from 'rsuite/lib/Dropdown';
import IconButton from 'rsuite/lib/IconButton';
import {
  downloadBrandProfile,
  setDownloadingBrandProfileId,
  setIsDownloadingBrandProfile
} from '@/redux/actions/brandProfiles';
import { connect } from 'react-redux';
import DownloadOptionsModal from './DownloadOptionsModal';
import { accentColor } from '@/assets/jss/colorConstants';
import SightlyButton from '@/components/Sightly/SightlyButton';
import useBrandProfiles from '@/hooks/brandProfile/useBrandProfiles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { permissionView } from '@/utils';
import { userAccountAxios } from '@/axiosInstances';
import { useNavigate, Navigate } from '@tanstack/react-location';
import useUser from '@/hooks/useUser';
import { rqKeys } from '@/ReactQueryKeyFactory';
import { LoadingPage } from '@/components/LoadingPage';
import { productNamesForUI, products, userActions } from '@/staticData/products';
import ProductLimitedAccessInfo from '@/components/ProductLimitedAccessInfo';
import Tooltip from '@/components/TailwindTooltip';
import { ProductCountTracker } from '@/views/BrandProfiles/BrandProfile/components/ProductCountTracker';
import { useProductUsage } from '@/hooks/subscription/useProductUsage';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';

var dayjs = require('dayjs');

const date_format = 'MMMM DD, YYYY h:mm A';

const mapStateToProps = (state) => {
  return {
    isDownloadingBrandProfile: state.isDownloadingBrandProfile,
    downloadingBrandProfileId: state.downloadingBrandProfileId
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    downloadBrandProfile: (args) => dispatch(downloadBrandProfile(args)),
    setDownloadingBrandProfileId: (id) => dispatch(setDownloadingBrandProfileId(id)),
    setIsDownloadingBrandProfile: (bool) => dispatch(setIsDownloadingBrandProfile(bool))
  }
}

const StatusCell = ({ rowData, dataKey, ...props }) => {
  return (
    <Table.Cell
      {...props}
      className="link-group"
      style={{ align: 'center' }}
    >
      {rowData.submitted ? 'Submitted' : 'Draft'}
    </Table.Cell>
  )
}

const ActionCell = ({ rowData, dataKey, customProps, setShowModal, setBrandProfileDownloadClickedFor, ...props }) => {
  return (
    <Table.Cell
      {...props}
      className="link-group"
      style={{ align: 'center', padding: 1 }}
    >
      <CustomWhisper
        rowData={rowData}
        customProps={customProps}
        setShowModal={setShowModal}
        setBrandProfileDownloadClickedFor={setBrandProfileDownloadClickedFor}
      >
        <IconButton
          size="lg"
          appearance="subtle"
          icon={
            <Icon
              icon="more"
              color={accentColor}
            />
          }
        />
      </CustomWhisper>
    </Table.Cell>
  )
}

const Menu = (props) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { currentAccount, perms, products, userPermissions, userProducts, subscriptionQuota } = useUser();

  const { cloneBrandProfile } = useBrandProfiles({ submittedOnly: false })

  let quotaIsAllowed = false;
  if (subscriptionQuota.has(products.BRAND_PROFILES)) {
      const quota = subscriptionQuota.get(products.BRAND_PROFILES)
      quotaIsAllowed = quota.isAllowed
  }

  let profile = props.rowData

  async function deleteBp(brandProfileId) {
    let url = `/brand-profile/${brandProfileId}/archive`
    return await userAccountAxios.patch(url)
  }

  const deleteBpMutation = useMutation((brandProfileId) => deleteBp(brandProfileId), {
    onSuccess: async () => {
      queryClient.invalidateQueries(rqKeys.brandProfilesKey(currentAccount?.accountId))
      // to refresh product quota
      queryClient.fetchQuery(rqKeys.subscriptionQuotaQueryKey(currentAccount?.accountId, products.BRAND_PROFILES))
    }
  })

  const handleDeleteBrandProfileClick = (brandProfileId) => {
    deleteBpMutation.mutate(brandProfileId)
  }

  const handleViewBrandProfileClick = (profile) => {
    let url = `/app/brandProfiles/brandProfile/${profile.brandProfileId}/brandInformation`
    navigate({
      to: url,
      search: (prev) => {
        return {
          ...prev,
          viewOnly: true
        }
      }
    })
  }

  const handleEditBrandProfileClick = (profile) => {
    let url = `/app/brandProfiles/brandProfile/${profile.brandProfileId}/brandInformation`
    navigate({ to: url })
  }

  const handleEditTargetViewIosClick = (profile) => {
    const url = `/app/brandProfiles/brandProfile/${profile.brandProfileId}/targetview`
    navigate({ to: url })
  }

  return (
    <Dropdown.Menu onSelect={props.onSelect}>
      
      {(userCanPermissionProductQuota({
        requiredPermissionValue: perms.BRAND_PROFILE_UPDATE,
        requiredProductValue: products.BRAND_PROFILES,
        userPermissions: userPermissions,
        userProducts: userProducts,
        checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
       }) || userCanPermissionProductQuota({
        requiredPermissionValue: perms.BRAND_PROFILE_UPDATE,
        requiredProductValue: products.BRAND_PROFILE_INFO_ONLY,
        userPermissions: userPermissions,
        userProducts: userProducts,
        checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
       })) && (
        <Dropdown.Item
          id="view"
          onClick={() => handleViewBrandProfileClick(profile)}
        >
          View
          {permissionView && <div style={{ color: 'red' }}>{perms.BRAND_PROFILE_READ}</div>}
        </Dropdown.Item>
      )}


      {(userCanPermissionProductQuota({
        requiredPermissionValue: perms.BRAND_PROFILE_UPDATE,
        requiredProductValue: products.BRAND_PROFILES,
        userPermissions: userPermissions,
        userProducts: userProducts,
        checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
       }) || userCanPermissionProductQuota({
        requiredPermissionValue: perms.BRAND_PROFILE_UPDATE,
        requiredProductValue: products.BRAND_PROFILE_INFO_ONLY,
        userPermissions: userPermissions,
        userProducts: userProducts,
        checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
       })) && (
        <Dropdown.Item
          id="edit"
          onClick={() => handleEditBrandProfileClick(profile)}
        >
          Edit
          {permissionView && <div style={{ color: 'red' }}>{perms.BRAND_PROFILE_UPDATE}</div>}
        </Dropdown.Item>
      )}
      <Dropdown.Item onClick={() => cloneBrandProfile.mutate({ brandProfileId: profile?.brandProfileId })} disabled={!userCanPermissionProductQuota({
          requiredPermissionValue: perms.BRAND_PROFILE_UPDATE,
          requiredProductValue: products.BRAND_PROFILES,
          userPermissions: userPermissions,
          userProducts: userProducts,
          checkType: PermissionCheckType.PRODUCT_QUOTA_PERMISSION_CHECK,
          quota_is_allowed: quotaIsAllowed
        })}>
        Clone
        {permissionView && <div style={{ color: 'red' }}>{perms.BRAND_PROFILE_UPDATE}</div>}
      </Dropdown.Item>

      
      {userCanPermissionProductQuota({
          requiredPermissionValue: perms.BRAND_PROFILE_IOS_UPDATE,
          requiredProductValue: products.BRAND_PROFILES,
          userPermissions: userPermissions,
          userProducts: userProducts,
          checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
        })  && (
        <Dropdown.Item onClick={() => handleEditTargetViewIosClick(profile)}>
          Edit TV IOs
          {permissionView && <div style={{ color: 'red' }}>{perms.BRAND_PROFILE_IOS_UPDATE}</div>}
        </Dropdown.Item>
      )}

      {userCanPermissionProductQuota({
          requiredPermissionValue: perms.BRAND_PROFILE_DOWNLOAD,
          requiredProductValue: products.BRAND_PROFILES,
          userPermissions: userPermissions,
          userProducts: userProducts,
          checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
        }) && (
        <Dropdown.Item
          id="download"
          onClick={() => {
            props.setShowModal(true)
            props.setBrandProfileDownloadClickedFor(profile)
          }}
        >
          Download
          {permissionView && <div style={{ color: 'red' }}>{perms.BRAND_PROFILE_DOWNLOAD}</div>}
        </Dropdown.Item>
      )}

      {userCanPermissionProductQuota({
          requiredPermissionValue: perms.BRAND_PROFILE_DELETE,
          requiredProductValue: products.BRAND_PROFILES,
          userPermissions: userPermissions,
          userProducts: userProducts,
          checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
        }) && (
        <Dropdown.Item
          onClick={() => {
            handleDeleteBrandProfileClick(profile.brandProfileId)
          }}
        >
          Delete
          {permissionView && <div style={{ color: 'red' }}>{perms.BRAND_PROFILE_DELETE}</div>}
        </Dropdown.Item>
      )}
    </Dropdown.Menu>
  )
}

const MenuPopover = ({ onSelect, rowData, customProps, setShowModal, setBrandProfileDownloadClickedFor, ...rest }) => (
  <Popover
    {...rest}
    full
  >
    <Menu
      onSelect={onSelect}
      rowData={rowData}
      customProps={customProps}
      setShowModal={setShowModal}
      setBrandProfileDownloadClickedFor={setBrandProfileDownloadClickedFor}
    />
  </Popover>
)

let tableBody

class CustomWhisper extends React.Component {
  constructor(props) {
    super(props)
    this.handleSelectMenu = this.handleSelectMenu.bind(this)
  }
  handleSelectMenu() {
    this.trigger.hide()
  }
  render() {
    return (
      <Whisper
        placement="auto"
        trigger="click"
        triggerRef={(ref) => {
          this.trigger = ref
        }}
        container={() => {
          return tableBody
        }}
        speaker={
          <MenuPopover
            rowData={this.props.rowData}
            onSelect={this.handleSelectMenu}
            customProps={this.props.customProps}
            setShowModal={this.props.setShowModal}
            setBrandProfileDownloadClickedFor={this.props.setBrandProfileDownloadClickedFor}
          />
        }
      >
        {this.props.children}
      </Whisper>
    )
  }
}

const tableHeaderStyle = {
  fontWeight: 600,
  color: '#333D47'
}

function BrandProfiles(props) {
  const { currentAccount, perms, products, userPermissions, userProducts, subscriptionQuota } = useUser();

  let quotaIsAllowed = false;
  if (subscriptionQuota.has(products.BRAND_PROFILES)) {
      const quota = subscriptionQuota.get(products.BRAND_PROFILES)
      quotaIsAllowed = quota.isAllowed
  }

  const navigate = useNavigate()
  const {
    data: brandProfiles,
    isLoading,
    isFetched,
    createBrandProfile,
    createBrandProfileMutation
  } = useBrandProfiles({
    submittedOnly: false
  });

  const [showModal, setShowModal] = React.useState(false);
  const [scenarioDownloadSort, setScenarioDownloadSort] = React.useState('question');
  const [opinionDownloadSort, setOpinionDownloadSort] = React.useState('question');

  const [brandProfileDownloadClickedFor, setBrandProfileDownloadClickedFor] = React.useState(null);

  const { productQuotaTally: BrandProfileTally } = useProductUsage({ accountId: currentAccount?.accountId, productName: products.BRAND_PROFILES });

  const inSufficientPermission = !(userCanPermissionProductQuota({
    requiredPermissionValue: perms.BRAND_PROFILE_CREATE,
    requiredProductValue: products.BRAND_PROFILES,
    userPermissions: userPermissions,
    userProducts: userProducts,
    checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
  }));

  const createButtonPermCheck = !userCanPermissionProductQuota({
    requiredPermissionValue: perms.BRAND_PROFILE_CREATE,
    requiredProductValue: products.BRAND_PROFILES,
    userPermissions: userPermissions,
    userProducts: userProducts,
    checkType: PermissionCheckType.PRODUCT_QUOTA_PERMISSION_CHECK,
    quota_is_allowed: quotaIsAllowed
  });

  const notBrandProfileOnly = !userCanPermissionProductQuota({
    requiredPermissionValue: perms.BRAND_PROFILE_READ,
    requiredProductValue: products.BRAND_PROFILE_INFO_ONLY,
    userPermissions: userPermissions,
    userProducts: userProducts,
    checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
  });

  const dataIsLoading = isLoading ||
    !isFetched;

  const disableCreate = createBrandProfileMutation.isLoading ||
    createButtonPermCheck;

  //clean up on unmount
  React.useEffect(() => {
    return () => {
      props.setIsDownloadingBrandProfile(false)
      props.setDownloadingBrandProfileId(null)
      setBrandProfileDownloadClickedFor(null)
    }
  }, [])

  const handleDownloadBrandProfileClick = () => {
    props.setIsDownloadingBrandProfile(true)
    props.setDownloadingBrandProfileId(brandProfileDownloadClickedFor.brandProfileId)
    let args = {
      brandProfileId: brandProfileDownloadClickedFor.brandProfileId,
      brandProfileName: brandProfileDownloadClickedFor.brandProfileName,
      scenarioDownloadSort,
      opinionDownloadSort
    }
    props.downloadBrandProfile(args)
  }

  const productNames = [
    BrandProfileTally && BrandProfileTally.isMaxedOut && productNamesForUI.BRAND_PROFILES
  ];

  if (isLoading) {
    return <LoadingPage message="Fetching brand profiles" />
  }

  if (brandProfiles && brandProfiles.length < 1) {
    return <Navigate to="/app/brandProfiles/new" />
  }

  return (
    <div className="flex flex-col h-full max-h-full gap-4 p-6 overflow-hidden bg-gray-100 animate-in fade-in">
      {notBrandProfileOnly &&
        <ProductCountTracker
          productName={products.BRAND_PROFILES}
          title="Brand Profiles"
          additionalStyle="p-4 rounded-md"
          dataTestId='product-count-tracker-brand-profiles'
        />
      }
      <DownloadOptionsModal
        showModal={showModal}
        handleCloseClick={() => setShowModal(false)}
        scenarioDownloadSort={scenarioDownloadSort}
        opinionDownloadSort={opinionDownloadSort}
        setScenarioDownloadSort={setScenarioDownloadSort}
        setOpinionDownloadSort={setOpinionDownloadSort}
        handleDownloadClick={() => handleDownloadBrandProfileClick()}
        isDownloadingBrandProfile={props.isDownloadingBrandProfile}
      />

      <div className="flex justify-end w-full">
        {(disableCreate && <Tooltip
          content={<ProductLimitedAccessInfo
            inSufficientPermission={inSufficientPermission}
            productNames={productNames}
          />}
        >
          <SightlyButton
            id="createNewProfile2"
            text="Create New Profile"
            handleClick={() => {
              createBrandProfile({
                isAIGenerate: false,
              });
            }}
            roundedFull
            disabled={disableCreate || dataIsLoading}
          />
        </Tooltip>
          || <SightlyButton
            id="createNewProfile2"
            text="Create New Profile"
            handleClick={() => {
              navigate({ to: '/app/brandProfiles/new' })
            }}
            roundedFull
            disabled={disableCreate || dataIsLoading}
          />)}
      </div>
      {permissionView && <div style={{ color: 'red' }}>{perms.BRAND_PROFILE_CREATE}</div>}

      <Table
        virtualized
        style={{
          borderRadius: '10px',
          boxShadow: '0px 1px 2px 0px #0000000F',
          overflowY: 'auto',
          flexGrow: 1,
          width: '100%',
        }}
        autoHeight
        bordered
        loading={isLoading || !isFetched}
        data={brandProfiles}
      >
        <Table.Column>
          <Table.HeaderCell style={tableHeaderStyle}>Status</Table.HeaderCell>
          <StatusCell />
        </Table.Column>

        <Table.Column flexGrow={1}>
          <Table.HeaderCell style={tableHeaderStyle}>Name</Table.HeaderCell>
          <Table.Cell dataKey="brandProfileName" />
        </Table.Column>

        <Table.Column flexGrow={1}>
          <Table.HeaderCell style={tableHeaderStyle}>Website</Table.HeaderCell>
          <Table.Cell dataKey="websiteUrl" />
        </Table.Column>

        <Table.Column flexGrow={1}>
          <Table.HeaderCell style={tableHeaderStyle}>Created</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              return dayjs(rowData.createdDate).format(date_format)
            }}
          </Table.Cell>
        </Table.Column>

        <Table.Column flexGrow={1}>
          <Table.HeaderCell style={tableHeaderStyle}>Submitted</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              return rowData.submittedDate ? dayjs(rowData.submittedDate).format(date_format) : '-'
            }}
          </Table.Cell>
        </Table.Column>

        <Table.Column width={90}>
          <Table.HeaderCell style={tableHeaderStyle}>Actions</Table.HeaderCell>
          <ActionCell
            customProps={props}
            setShowModal={setShowModal}
            setBrandProfileDownloadClickedFor={setBrandProfileDownloadClickedFor}
          />
        </Table.Column>
      </Table>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandProfiles)
