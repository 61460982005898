import React from 'react';
import Panel from 'rsuite/lib/Panel';
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox';
import debounce from 'just-debounce-it';
import Tag from 'rsuite/lib/Tag';
import TagGroup from 'rsuite/lib/TagGroup';
import Divider from 'rsuite/lib/Divider';
import { useMatch, useSearch } from '@tanstack/react-location';
import useTargetViewInsertionOrders from '@/hooks/targetview/useTargetViewInsertionOrders';
import { LoadingPage } from '@/components/LoadingPage';
import { InfoPage } from '@/components/InfoPage';
import { SightlyInput } from '@/components/Sightly/SightlyFormElements/SightlyInput';
import { TvIoType } from '@/hooks/targetview/targetview';
import { api } from '@/api/api';
import { logError } from '@/utils';
import BrandProfileFooter from '@/views/BrandProfiles/BrandProfile/components/BrandProfileFooter';
import useSteps from '@/hooks/brandProfile/useSteps';

interface RowProps {
    io: TvIoType
    viewOnly: boolean
    isChecked: boolean
    handleChange: (val: number, checked: boolean) => void
}

const RowCat = (props: RowProps) => {
    const io = props.io
    return (
        <tr className="catRow">
            <td
                className="catCell"
                style={{ width: 400 }}
            >
                <SightlyCheckbox
                    id="checkbox"
                    disabled={props.viewOnly}
                    checked={props.isChecked}
                    handleChange={(checked: boolean) => {
                        props.handleChange(io.InsertionOrderId, checked)
                    }}
                />
            </td>

            <td
                className="catCell"
                style={{ width: 300 }}
            >
                {io.InsertionOrderId}
            </td>
            <td className="catCell">{io.OrderName}</td>
            <td className="catCell">{io.CampaignType}</td>
            <td className="catCell">{io.AdvertiserName}</td>
            <td className="catCell">{io.AccountName}</td>
        </tr>
    )
}

function Targetview() {
    const {
        activeStep,
        handlePreviousClick,
        handleContinueClick
    } = useSteps();
    const {
        params: { brandProfileId }
    } = useMatch()
    const { viewOnly } = useSearch()
    const viewOnlyParam = !!viewOnly

    const [searchTerm, setSearchTerm] = React.useState('')

    const { data: tvIos, isError: errorFetchingTvIos, isLoading: tvIosLoading } = useTargetViewInsertionOrders()
    const [bpIoIds, setBpIoIds] = React.useState<number[]>([])
    const [bpIosLoading, setBpIosLoading] = React.useState(true)
    const [errorFetchingBpIos, setErrorFetchingBpIos] = React.useState(false)
    React.useEffect(() => {
        const handleBpIos = async () => {
            setBpIosLoading(true)
            setErrorFetchingBpIos(false)
            try {
                const ios = await api.brandProfile.ios.get(Number(brandProfileId))
                setBpIoIds(ios.map((io) => io.ioId))
            } catch (err) {
                setErrorFetchingBpIos(true)
                logError(err)
            } finally {
                setBpIosLoading(false)
            }
        }
        handleBpIos()
    }, [])

    const filteredTvIos = React.useMemo(() => {
        if (!tvIos) {
            return []
        }
        const _searchTerm = String(searchTerm).toLowerCase()
        return tvIos.filter(
            (io) =>
                io?.AccountName?.toLowerCase().includes(_searchTerm) ||
                String(io.InsertionOrderId).toLowerCase().includes(_searchTerm) ||
                io?.OrderName.toLowerCase().includes(_searchTerm) ||
                io?.CampaignType.toLowerCase().includes(_searchTerm) ||
                io?.AdvertiserName.toLowerCase().includes(_searchTerm)
        )
    }, [tvIos, searchTerm])

    const handleSearch = debounce((val: string) => {
        setSearchTerm(val)
    }, 500)

    const handleChange = async (ioId: number, checked: boolean) => {
        let finalIos = []
        if (checked) {
            finalIos = [...bpIoIds, ioId]
        } else {
            finalIos = [...bpIoIds].filter((_io) => _io !== ioId)
        }
        setBpIoIds(finalIos)

        const args = {
            brandProfileId: Number(brandProfileId),
            ios: finalIos
        }

        await api.brandProfile.ios.patch(args)
    }

    const bpIos = React.useMemo(() => {
        if (!tvIos) return []
        return tvIos.filter((io) => bpIoIds.includes(io.InsertionOrderId))
    }, [tvIos, bpIoIds])

    if (errorFetchingBpIos) {
        return <InfoPage message="There was an error fetching Brand Profile IOs. Please contact support for help." />
    }

    if (errorFetchingTvIos) {
        return <InfoPage message="There was an error connecting to Targetview. Please contact support for help." />
    }

    if (tvIosLoading || bpIosLoading) {
        return <LoadingPage message="Fetching IOs" />
    }

    return (
        <Panel header={<div className="sightlyPanelHeader">Linked Targetview Insertion Orders</div>}>
            <TagGroup
                style={{
                    marginTop: 16,
                    marginBottom: 16
                }}
                id={'tagGroup'}
            >
                {bpIos &&
                    bpIos.map((item, index: number) => (
                        <Tag
                            key={index}
                            closable={!viewOnlyParam}
                            onClose={() => {
                                handleChange(item.InsertionOrderId, false)
                            }}
                        >
                            {item.InsertionOrderId} - {item.OrderName}
                        </Tag>
                    ))}
            </TagGroup>

            <Divider />

            <SightlyInput
                placeholder="Search any field..."
                onChange={handleSearch}
                id="search"
                hasSearchIcon
                defaultValue={''}
            />

            <div
                style={{
                    marginTop: 24
                }}
            >
                <table>
                    <thead>
                        <tr>
                            <th className="catHeader">Linked</th>
                            <th className="catHeader">IO ID</th>
                            <th className="catHeader">Order Name</th>
                            <th className="catHeader">Campaign Type</th>
                            <th className="catHeader">Advertiser Name</th>
                            <th className="catHeader">Account Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredTvIos &&
                            filteredTvIos.map((io, index) => {
                                const isChecked = bpIoIds?.includes(io.InsertionOrderId) || false
                                return (
                                    <RowCat
                                        io={io}
                                        key={index}
                                        handleChange={handleChange}
                                        isChecked={isChecked}
                                        viewOnly={viewOnlyParam}
                                    />
                                )
                            })}
                    </tbody>
                </table>
            </div>
            <BrandProfileFooter activeStep={activeStep}
                disabled={false}
                handlePrevious={handlePreviousClick}
                handleContinue={handleContinueClick}
            />
        </Panel>
    )
}

export default Targetview
