import React from 'react';
import { ReactComponent as NoNotifications } from '@/assets/img/noNotifications.svg';
import SightlyButton from '@/components/Sightly/SightlyButton';
import useBrandProfiles from '@/hooks/brandProfile/useBrandProfiles';
import useUser from '@/hooks/useUser';
import { products, userActions } from '@/staticData/products';
import Tooltip from '@/components/TailwindTooltip';
import ProductLimitedAccessInfo from '@/components/ProductLimitedAccessInfo';
import { useNavigate } from '@tanstack/react-location';

const NoBrandProfiles = () => {
  const { perms, userCanService } = useUser();
  const navigate = useNavigate();

  const { createBrandProfileMutation } = useBrandProfiles({ submittedOnly: false });

  let disableCreateButton;

  disableCreateButton = !userCanService(perms.BRAND_PROFILE_CREATE, products.BRAND_PROFILES, userActions.CREATE) || createBrandProfileMutation.isLoading;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <div style={{ width: 500, justifyContent: 'center', textAlign: 'center' }}>
        <NoNotifications />
        <h4 style={{ marginBottom: 48 }}>
          We currently have no brand profiles submitted and processed for this account.
        </h4>
        <Tooltip
          disabled={userCanService(perms.BRAND_PROFILE_CREATE, products.BRAND_PROFILES, userActions.CREATE)}
          content={<ProductLimitedAccessInfo />}
        >
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <SightlyButton
              disabled={disableCreateButton}
              id="createNewProfile"
              text="Create New Profile"
              handleClick={() =>{navigate({to:'/app/brandProfiles/new'})}}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default NoBrandProfiles;
