import React, {useState} from 'react';
import { DateRangePicker } from 'rsuite';
import { dateRanges } from '@/views/ReportGenerator/components/formSections/boardsDateRange-types'
const { combine, before, afterToday } = DateRangePicker;

const DateRangeSectionBoards = ({ onSelectDateRange, ranges, onCalendarStateChange, urlRanges }) => {

    const now = new Date();

    // Default value of calendar
    const getLast24Hrs = () => {
        const yesterday = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        return [yesterday, now]
    }

    // Lower bound of allowed selectable dates
    const sixtyDaysAgo = new Date(now.setDate(now.getDate() - 60));

    const handleOpen = () => {
        onCalendarStateChange(true);
    };

    const handleClose = () => {
        onCalendarStateChange(false);
    };

    const handleDateRangeChange = (dateRange) => {
        onSelectDateRange(dateRange)
    };

    return (
        <DateRangePicker
            onChange={handleDateRangeChange}
            disabledDate= {combine(before(sixtyDaysAgo), afterToday())}
            ranges={ranges}
            defaultValue={getLast24Hrs()}
            cleanable={false}
            onOpen={handleOpen}
            onClose={handleClose}
            value={urlRanges}
        />
    );
};

export default DateRangeSectionBoards;
