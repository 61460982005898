import * as Yup from 'yup'
import { getWikiStatus } from '@/views/BrandProfiles/BrandProfile/components/getWikiStatus'

export const brandProfilesObjValidation = Yup.array().of(
    Yup.object().shape({
        accountId: Yup.number().strict(true).required(),
        brandProfileName: Yup.string().required(),
        brandProfileId: Yup.number().required(),
        archived: Yup.bool().defined(),
        twitterProfileUrl: Yup.mixed().defined(),
        websiteUrl: Yup.mixed().defined(),
        submitted: Yup.bool().defined().required(),
        submittedDate: Yup.string().defined(),
        lastModifiedDate: Yup.string().defined(),
        createdDate: Yup.string().required()
    })
)

export const basicInfoObjValidation = Yup.object().shape({
    accountId: Yup.number().strict(true).required(),
    brandName: Yup.string().required(),
    brandInfoName: Yup.string().defined(),
    wikiUrl: Yup.string().defined(),
    brandProfileId: Yup.number().required(),
    aylienIndustryId: Yup.number().defined(),
    twitterProfileUrl: Yup.mixed().defined(),
    websiteUrl: Yup.mixed().defined()
})

const wikiUrlObj = Yup.string()
    .nullable()
    .test('checkWikiExists', 'This wiki does not exist', async (wiki) => {
        if (!wiki) {
            return true
        }
        if (wiki.length < 1) {
            return true
        }

        if (
            wiki.toLowerCase() === 'na' ||
            wiki.toLowerCase() === 'n/a' ||
            wiki.toLowerCase() === 'none' ||
            wiki.toLowerCase() === 'not applicable' ||
            wiki.length < 3
        ) {
            return false
        }

        const exists = await getWikiStatus(wiki)
        return exists
    })

export const competitorObjValidation = Yup.object().shape({
    id: Yup.number().strict(true).required(),
    name: Yup.string().required('Required'),
    relationshipId: Yup.number().defined(),
    brandType: Yup.string(),
    twitterProfileUrl: Yup.mixed().defined(),
    websiteUrl: Yup.mixed().defined(),
    wikiUrl: wikiUrlObj,
    isAIGenerated: Yup.bool(),
    reason: Yup.string().nullable()
})

export const competitorsObjValidation = Yup.array().of(competitorObjValidation)

export const socialObjValidation = Yup.object().shape({
    id: Yup.number().strict(true).required(),
    name: Yup.string().required('Required'),
    description: Yup.string().defined(),
    relationshipId: Yup.number().defined(),
    relationshipName: Yup.string().nullable(),
    aylienId: Yup.number().defined(),
    wikiUrl: wikiUrlObj,
    causeIsNotListed: Yup.bool().required()
})

export const socialApiCallObjValidation = Yup.object().shape({
    id: Yup.number().strict(true).required(),
    name: Yup.string().required('Required'),
    description: Yup.string().defined(),
    relationshipId: Yup.number().defined(),
    relationshipName: Yup.string().nullable(),
    aylienIndustriesId: Yup.number().defined(),
    aylienNewsId: Yup.number().defined(),
    wikiUrl: wikiUrlObj,
    causeIsNotListed: Yup.bool().required(),
    isAIGenerated: Yup.bool(),
    reason: Yup.string().nullable()
})

export const socialsObjValidation = Yup.array().of(socialApiCallObjValidation)

export const influencerObjValidation = Yup.object().shape({
    id: Yup.number().strict(true).required(),
    name: Yup.string().required('Required'),
    relationshipId: Yup.number().defined(),
    relationshipName: Yup.string().nullable(),
    twitterProfileUrl: Yup.string().defined(''),
    wikiUrl: wikiUrlObj,
    isAIGenerated: Yup.bool(),
    reason: Yup.string().nullable()
})

export const influencersObjValidation = Yup.array().of(influencerObjValidation)

export const eventObjValidation = Yup.object().shape({
    id: Yup.number().strict(true).required(),
    name: Yup.string().required('Required'),
    twitterHandle: Yup.string().defined(''),
    website: Yup.string().defined(''),
    relationshipId: Yup.number().required('Relationship is required'),
    wikiUrl: wikiUrlObj
})

export const eventsObjValidation = Yup.array().of(eventObjValidation)

export const keywordsObjValidation = Yup.array().of(
    Yup.object().shape({
        keywordId: Yup.number().strict(true).required(),
        keyword: Yup.string().required(),
        action: Yup.string().required(),
        brandProfileId: Yup.number().required()
    })
)

export const iabCategoriesObjValidation = Yup.array().of(
    Yup.object().shape({
        actionId: Yup.number().defined(),
        children: Yup.array().nullable(),
        iabCategoryId: Yup.mixed().strict(true).required(),
        iabCategoryName: Yup.string().required(),
        brandProfileId: Yup.number().defined()
    })
)

export const topicsObjValidation = Yup.array()
    //	.min(1, 'brand profiles should have at least one topics object')
    .of(
        Yup.object().shape({
            children: Yup.array().of(
                Yup.object().shape({
                    parentIds: Yup.array().required(),
                    topicId: Yup.number().required(),
                    topicName: Yup.string().required(),
                    topicResponseId: Yup.mixed().defined()
                })
            ),
            parentIds: Yup.array().required(),
            topicId: Yup.number().required(),
            topicName: Yup.string().required(),
            topicResponseId: Yup.mixed().defined()
        })
    )

export const targetviewIos = Yup.array().of(
    Yup.object().shape({
        ioId: Yup.number().required(),
        ioName: Yup.string().required(),
        ioMapped: Yup.boolean().required()
    })
)

export const opinionsObjValidation = Yup.array()
    .min(1)
    .of(
        Yup.object().shape({
            opinionTypeName: Yup.string().required(),
            opinions: Yup.array().of(
                Yup.object().shape({
                    opinionId: Yup.number().strict(true).required(),
                    question: Yup.string().required(),
                    opinionType: Yup.string().required(),
                    brandProfileId: Yup.mixed().defined(),
                    opinionResponseId: Yup.mixed().defined(),
                    opinionResponseName: Yup.mixed().defined()
                })
            )
        })
    )

export const questionsObjValidation = Yup.array()
    .min(1)
    .of(
        Yup.object().shape({
            question: Yup.string().required(),
            questionId: Yup.number().strict(true).required(),
            responseText: Yup.mixed().defined(),
            keywords: Yup.array().of(Yup.string()).defined()
        })
    )

export const boardsObjValidation = Yup.array().of(
    Yup.object().shape({
        id: Yup.number().required(),
        brandProfileId: Yup.number().required(),
        createdByName: Yup.string().required(),
        createdDate: Yup.string().required(),
        name: Yup.string().required(),
        operator: Yup.string().required(),
        type: Yup.string().required(),
        updatedByName: Yup.string().required(),
        updatedDate: Yup.string().required(),
        starred: Yup.boolean().required(),
        selectedItems: Yup.array().of(Yup.string()).optional()
    })
)
