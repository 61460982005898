import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import { unregister } from './ServiceWorker/registerServiceWorker'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import React from 'react'

if (window.location.href.indexOf('localhost') > -1) {
} else {
    console.log('initiating sentry')
    Sentry.init({
        dsn: 'https://3edf2719326948f4bf0b1e4e7e2c9601@o1336841.ingest.sentry.io/6616867',
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0
    })
}

const container = document.getElementById('root')
const root = createRoot(container!) // createRoot(container!) if you use TypeScript
unregister()

root.render(<App />)

//
//ReactDOM.render(<App />, document.getElementById('root'))
