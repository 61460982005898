import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query'
import { rqKeys } from './rqKeys'
import { MomentInsightsProps } from './types'
import { getMomentInsights } from './api'
import { logError } from '@/utils'
import { MomentInsightsListType } from '@/views/Discover/Moments/v2/MomentInsightsModal/types'

export const useMomentInsights = ({ brandProfileId, clusterId, timeRange }: MomentInsightsProps) => {
  const insightsQuery = useQuery(
    rqKeys.momentInsightsKey(brandProfileId, clusterId),
    () => getMomentInsights({ brandProfileId, clusterId, timeRange }),
    {
      enabled: false,
      onError: (err) => {
        logError(err, { info: 'insightsQuery' })
      }
    }
  )
  return {
    insightsQuery
  }
}

export const useBulkMomentInsights = (props: { brandProfileId?: number; clusterIds: number[]; timeRange: number }) => {
  const { brandProfileId, clusterIds, timeRange } = props
  const queryClient = useQueryClient()

  const queries = clusterIds.map((clusterId: number) => ({
    queryKey: rqKeys.momentInsightsKey(brandProfileId, clusterId),
    queryFn: () => getMomentInsights({ brandProfileId, clusterId, timeRange }),
    enabled: false,
    onError: (err: unknown) => {
      logError(err, { info: 'insightsQuery' })
    }
  }))
  const bulkMomentInsightsQueries = useQueries({ queries })

  const getBulkMomentInsightsData = () => {
    if (!bulkMomentInsightsQueries.length) return undefined
    bulkMomentInsightsQueries.forEach((query) => {
      if (query.fetchStatus === 'idle' && query.isLoading) query.refetch()
    })
    return new Map(
      clusterIds.map((clusterId) => [
        clusterId,
        queryClient.getQueryData<MomentInsightsListType>(rqKeys.momentInsightsKey(brandProfileId, clusterId))
      ])
    )
  }
  
  return {
    bulkMomentInsightsQueries,
    bulkMomentInsightsData: getBulkMomentInsightsData()
  }
}
