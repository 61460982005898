import dayjs from 'dayjs';
import * as Sentry from '@sentry/react';
import React from 'react';
import Table from 'rsuite/lib/Table';

var calendar = require('dayjs/plugin/calendar');
dayjs.extend(calendar);

export function logError(err, info) {
  if (process.env.REACT_APP_STAGE === 'production') {
    Sentry.captureException(err, { extra: { info } });
  } else {
    console.error('Logging Error: ', err, { extra: { info } });
  }
}

export function joinClasses(...classes) {
  return classes.join(' ');
}

export function openInNewTab(url) {
  if (!url || url.length < 1) {
    console.error('url must be present');
    return;
  }
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
}

export function getCalendarDate(_date) {
  if (!_date) return '';
  return dayjs(_date).calendar();
}

 export function formatDateyyyyMMdd(date) {
  if (!date) return ''

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export const permissionView = localStorage.getItem('permissionView') === '1'

export const TableColumnJs = (props) => {
  return <Table.Column flexGrow={props.flexGrow} width={props.width}>{props.children}</Table.Column>
}
