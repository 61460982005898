import React from 'react'
import { Link, useSearch } from '@tanstack/react-location'
import { Loader } from 'rsuite'

import { MyLocationGenerics } from '@/classes/utils'
import { useInProcessActivations } from './hooks/activations-hooks'
import { InfoPage } from '@/components/InfoPage'
import ToolBar from '@/views/Discover/Activations/v2/ToDo/ToolBar'
import ActivationsTable from '@/views/Discover/Activations/v2/ActivationsTable/index'
import { ConfirmationDialog } from '../../Moments/v2/TargetMoment/ConfirmationDialog'
import PortalModal from '@/components/PortalModal/PortalModal'
import { ActivationManagerProvider } from './ActivationsManager/ActivationManagerContext'
import { ActivationsManager } from './ActivationsManager'
import useAssignees from '../../Moments/v2/TargetMoment/hooks/useAssignees'
import useUser from '@/hooks/useUser'
import { logError } from '@/utils'
import { ActivationItemType } from '@/views/Discover/Activations/v2/activations-types'
import { useStoredTargetedActivations } from '@/views/Discover/Activations/v2/ActivationsManager/useStoredTargetedActivations'

export const InProcess = () => {
  const { brandProfileId } = useSearch<MyLocationGenerics>()
  
  const { syncActivationListWithTodo } = useStoredTargetedActivations()

  const getLast60Days = (): [Date, Date] => {
    const now = new Date()
    const sixtyDaysAgo = new Date(now)
    sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
    sixtyDaysAgo.setHours(0, 0, 0, 0);
    return [sixtyDaysAgo, now]
  }

  const startingRange = getLast60Days();
  const [customStartDate, setCustomStartDate] =  React.useState<Date>(startingRange[0]);
  const [customEndDate, setCustomEndDate] =  React.useState<Date>(startingRange[1]);

  const { startDate, endDate } = React.useMemo(() => {
    const today = new Date();
    let startDate: Date;
    let endDate: Date;

    startDate = customStartDate || today;
    endDate = customEndDate || today;
    if (startDate > endDate) {
      endDate = startDate;
    }

    return { startDate, endDate };
  }, [customStartDate, customEndDate]);

  const activationsQuery = useInProcessActivations({
    brandProfileId: Number(brandProfileId),
    startDate,
    endDate
  })

  const handleDateRangeChange = (value: any) => {
    if (value && value.length === 2) {
      const [start, end] = value;
      setCustomStartDate(start);
      setCustomEndDate(end);
    }
  };

  const handleTimeRangeChange = () => {
    setCustomStartDate(startingRange[0]);
    setCustomEndDate(startingRange[1]);
  };

  const getStoredSelectedActivations = () => {
    const storedSelectedMoments = sessionStorage.getItem('selectedInProcessActivations')
    return storedSelectedMoments && storedSelectedMoments !== 'undefined' && JSON.parse(storedSelectedMoments)
  }

  const getDefaultSelectedActivations = (brandProfileId: number | undefined): number[] => {
    const selectedActivationsAll = getStoredSelectedActivations()
    return (brandProfileId && selectedActivationsAll && selectedActivationsAll[brandProfileId]) || []
  }
  
  const [searchedMomentsIds, setSearchedMomentsIds] = React.useState<number[]>()
  const [deletingActivations, setDeletingActivations] = React.useState(false)
  const [activationToBeDeleted, setActivationToBeDeleted] = React.useState<number | null>(null)
  const [isEditOpen, setIsEditOpen] = React.useState(false)
  const [isBulkDelete, setIsBulkDelete] = React.useState<boolean>(false)
  const [showTooManyActivationsError, setShowTooManyActivationsError] = React.useState(false)

  const handleCancelDeleteMoment = () => setActivationToBeDeleted(() => null)
  const handleCancelBulkDeleteMoment = () => setIsBulkDelete(false)
  

  const [selectedActivationIds, setSelectedActivationIds] = React.useState<number[]>(
    getDefaultSelectedActivations(brandProfileId)
  )

  const [assignees, setAssignees] = React.useState<string[]>([])

  const { accountId } = useSearch<MyLocationGenerics>()
  const { user } = useUser()

  const { assigneesQuery } = useAssignees(accountId)
  const assigneesOptions = React.useMemo(() => {
    if (!assigneesQuery.data) return []

    const assigneeDetailLookup = new Map(
      assigneesQuery.data.map(({ email, fullName }) => [email.toLowerCase(), fullName])
    )
    const assigneesEmails =
      activationsQuery.data
        ?.filter((activation) => {
          if (searchedMomentsIds?.length) {
            return searchedMomentsIds.includes(activation.clusterId)
          }
          return true
        })
        .flatMap(({ assignees }) => assignees) ?? []
    const uniqueAssigneesEmails = Array.from(new Set(assigneesEmails))

    const options = uniqueAssigneesEmails.map((email) => {
      const lowerCaseEmail = email.toLowerCase()
      const fullName = assigneeDetailLookup.get(lowerCaseEmail) ?? lowerCaseEmail
      return { email: lowerCaseEmail, fullName }
    })
    return options
  }, [activationsQuery.data, assigneesQuery.data, searchedMomentsIds])

  const activationsList = React.useMemo(() => {
    if (!activationsQuery.data) return []
    const filteredList = activationsQuery.data.filter((activation) => {
      const hasPersonas = activation.personas.length > 0
      const hasFilterAssigneeValue =
        assignees.length <= 0
          ? true
          : activation.assignees.some((assigneeEmail) => assignees.includes(assigneeEmail.toLowerCase()))
      const hasSearchedValue = searchedMomentsIds?.includes(activation.clusterId) ?? true

      return hasFilterAssigneeValue && hasSearchedValue && hasPersonas
    })
    return filteredList
  }, [activationsQuery.data, assignees, searchedMomentsIds, brandProfileId])


  const handleBulkDelete = () => {
    setDeletingActivations(() => true)
    setSelectedActivationIds(() => [])
    setIsBulkDelete(false)
  }

  const handleDeleteActivationsGroupedByIo = () => {
    setDeletingActivations(() => true)
  }

  const isUserInAssigneesOptions = React.useMemo(
    () => assigneesOptions.some((option) => option.email === user?.email),
    [assigneesOptions, user]
  )

  const areBulkOpsDisabled =
  (selectedActivationIds && selectedActivationIds?.length < 1) ||
  activationsList.length === 0 ||
  activationsQuery.isLoading

  const handleDelete = () => {
    if (!activationToBeDeleted) return
    setDeletingActivations(() => true)
    setActivationToBeDeleted(() => null)
  }
  
  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedActivationIds(() => activationsList?.map((i) => i.activationItemId) || [])
    } else {
      setSelectedActivationIds([])
    }
  }

  const handleSelect = (checked: boolean, activationItem: ActivationItemType) => {
    const newItems = checked
      ? selectedActivationIds?.concat(activationItem.activationItemId)
      : selectedActivationIds?.filter((id) => id !== activationItem.activationItemId)
    setSelectedActivationIds(newItems)
  }

  React.useEffect(() => {
    let selectedActivationsAll = getStoredSelectedActivations()
    // Merges previous stored value with new selectedMoments
    if (selectedActivationsAll && brandProfileId) {
      const prevSelectedActivations: number[] = selectedActivationsAll[brandProfileId]
      if (prevSelectedActivations) {
        if (!selectedActivationIds) {
          setSelectedActivationIds(() => prevSelectedActivations)
        } else {
          selectedActivationsAll[brandProfileId] = [...selectedActivationIds]
        }
      } else {
        selectedActivationsAll[brandProfileId] = selectedActivationIds
      }
    } else
      selectedActivationsAll = (brandProfileId && { [brandProfileId]: selectedActivationIds }) || selectedActivationsAll

    let selectedActivationsSet: Set<number>
    if (brandProfileId) {
      selectedActivationsSet = new Set(selectedActivationsAll[brandProfileId])
      selectedActivationsAll[brandProfileId] = Array.from(selectedActivationsSet)
    }
    syncActivationListWithTodo()
    sessionStorage.setItem('selectedInProcessActivations', JSON.stringify(selectedActivationsAll))
  }, [selectedActivationIds])

  React.useEffect(() => {
    /* 
    As assigneeOptions is dynamic value which changes with the search result,
    !searchedMomentsIds : ensures that we are not setting default assignee when the search is active.
    !deletingActivations : ensures that deleting activations don't trigger the default assignee 
    */
    const canCurrentUserBeDefaultAssignee =
      user && isUserInAssigneesOptions && !deletingActivations && !searchedMomentsIds
    setAssignees((prev) => (canCurrentUserBeDefaultAssignee ? [user?.email] : prev))
    setDeletingActivations(() => false)
  }, [assigneesOptions])

  React.useEffect(() => {
    if (customStartDate && customEndDate) {
      activationsQuery.refetch();
    }
  }, [customStartDate, customEndDate])

  React.useEffect(() => {
    setAssignees(() => (user && isUserInAssigneesOptions ? [user?.email] : []))
    setSearchedMomentsIds(() => undefined)
  }, [brandProfileId])

  const allMomentsOnPageAreSelected = React.useMemo(() => {
    if (selectedActivationIds?.length === 0) {
      return false
    }

    const notIncludedInSelectedItems = activationsQuery.data
      ?.map(({ clusterId }) => clusterId)
      ?.filter((id) => !selectedActivationIds?.includes(id))
    if (notIncludedInSelectedItems === undefined) {
      return true
    }
    if (notIncludedInSelectedItems.length > 0) {
      return false
    }
    return true
  }, [activationsQuery.data, selectedActivationIds])
  
  const handleSearchMoments = React.useCallback(
    (searchKey: string) => {
      try {
        if (!activationsQuery.data) return;
  
        const searchResult = activationsQuery.data?.filter(({  clusterName, insertionOrderName }) =>
          clusterName.toLowerCase().includes(searchKey.toLowerCase()) ||
          insertionOrderName.toLowerCase().includes(searchKey.toLowerCase())
        );

        setSearchedMomentsIds(searchResult?.map(({ clusterId }) => clusterId) ?? []);
      } catch (error) {
        logError(error);
      }
    },
    [brandProfileId, activationsQuery.data]
  );
  

  const selectedActivationsList = React.useMemo(() => {
    return (
      activationsQuery.data?.filter((activations) => selectedActivationIds?.includes(activations.activationItemId)) ??
      []
    )
  }, [activationsQuery.data, selectedActivationIds])
  
  const activationsGroupedByPersonas = React.useMemo(() => {
    const groupedActivations = new Map<string, ActivationItemType[]>()
    if (!selectedActivationsList.length) return groupedActivations
    selectedActivationsList.forEach((activation) => {
      activation.personas.forEach((persona) => {
        if (groupedActivations.has(persona)) {
          const relatedActivations = groupedActivations.get(persona) ?? []
          if (
            relatedActivations.length > 0 &&
            relatedActivations.find(({ activationItemId }) => activation.activationItemId === activationItemId)
          ) {
            return
          }
          groupedActivations.set(persona, [...relatedActivations, activation])
          return
        }
        groupedActivations.set(persona, [activation])
      })
    })
    return groupedActivations
  }, [activationsQuery.data, selectedActivationIds])

  if (activationsQuery.isLoading) {
    return (
      <div className="absolute top-1/2 left-1/2">
        <Loader
          content="Fetching Activations..."
          className="z-10"
          speed="slow"
        />
      </div>
    )
  }

  return (
    <div
      data-testid="activations-inprocess-table"
      id="pendo-activations-inprocess"
      className="flex flex-col mt-8 fade-in animate-in"
    >
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full align-middle md:px-6 lg:px-8">
          <ToolBar
            {...{
              handleSearchMoments,
              assigneesOptions,
              assignees,
              setAssignees,
              actionsEnabled: false,
              downloadEnabled: false,
              brandProfileId,
              setIsEditOpen,
              handleTimeRangeChange,
              handleDateRangeChange,
              customStartDate,
              customEndDate,
              handleBulkDelete: () => setIsBulkDelete(true),
              handleDownload : () => null,
              areBulkOpsDisabled
            }}
          />
          {activationsQuery?.data && activationsQuery?.data?.length === 0 && activationsQuery.isFetched ? (
              <div className="w-full h-80">
                <InfoPage
                  message={
                    <div>
                      <span>
                        No items found.{' '}
                        <span>
                          <Link to={'/app/discover/moments/v2'}>Click here</Link>
                        </span>{' '}
                        to add some
                      </span>
                    </div>
                  }
                />
              </div>
          ) : <ActivationsTable
            {...{
              activationsList,
              isToDoActivation: false,
              handleDelete: (id: number) => setActivationToBeDeleted(id),
              handleDeleteActivationsGroupedByIo,
              handleSelect,
              handleSelectAll,
              selectedActivationIds,
              setSelectedActivationIds,
              allMomentsOnPageAreSelected
            }}
          /> }
          <ConfirmationDialog
            show={!!activationToBeDeleted}
            onHide={handleCancelDeleteMoment}
            handleConfirm={handleDelete}
            handleCancel={handleCancelDeleteMoment}
            body={'Are you sure you want to remove this moment?'}
            action="Delete"
          />
          <ConfirmationDialog
            show={isBulkDelete}
            onHide={handleCancelBulkDeleteMoment}
            handleConfirm={handleBulkDelete}
            handleCancel={handleCancelBulkDeleteMoment}
            body={`Are you sure you want to remove ${selectedActivationIds?.length} moments?`}
            action="Delete"
          />
          <PortalModal
            hasClose
            open={showTooManyActivationsError}
            handleClose={() => setShowTooManyActivationsError(false)}
            footerButtons={[]}
          >
            <div className="w-[500px] p-6 flex flex-col gap-12">
              <h4>Unable to download</h4>
              <div>
                Our system can only handle downloading 1,000 moments maximum. Please add more filters and try again.
              </div>
            </div>
          </PortalModal>
        </div>
      </div>
      {isEditOpen && (
        <ActivationManagerProvider activationsGroupedByPersonas={activationsGroupedByPersonas}>
          <ActivationsManager
            activationsGroupedByPersonas={activationsGroupedByPersonas}
            activationsToIoIds={
              new Map(selectedActivationsList.map(({ activationItemId, insertionOrderId }) => [activationItemId, insertionOrderId]))
            }
            open={isEditOpen}
            setOpen={setIsEditOpen}
            setSelectedActivationIds={setSelectedActivationIds}
          />
        </ActivationManagerProvider>
      )}
    </div>
  )
}
