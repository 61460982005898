import React from 'react'
import { SELECTION_DROP_DOWN_WIDTH } from '@/views/TikTok/ActivationManager/constants'
import { CheckPicker, Icon, Placeholder, SelectPicker } from 'rsuite'
import { CampaignOptionsType } from '@/views/TikTok/ActivationManager/types'
import { XMarkIcon } from '@heroicons/react/20/solid'
import DataPickerFooter from '@/components/DataPickerFooter'
import { useTargetedActivationList } from '@/views/TikTok/ActivationManager/ActivationManagerContext'
import { CAMPAIGN_VALUE_EXISTS } from '@/views/Discover/Activations/v2/ActivationsManager/constants'
import { TARGETED_ACTIVATION_ACTIONS } from '@/views/TikTok/ActivationManager/reducers/activationManager.reducer'
import useUser from '@/hooks/useUser'
import { perms } from '@/staticData/permissions'
import { products } from '@/staticData/products'

type CampaignAdGroupRowProps = {
  rowKey: string
  hashtagId: string
  campaignOptions: CampaignOptionsType | undefined
  adGroupOptions: {
    adGroupId: number
    adGroupName: string
  }[]
  campaignValue: number
  adGroupValue: number[]
  setKeyToCampaignIdMapper: React.Dispatch<React.SetStateAction<Record<string, number>>>
  areCampaignOptionsLoading: boolean
  handleCampaignChange: (
    elementKey: string,
    hashtagId: string,
    nextCampaignId: number,
    prevCampaignId: number,
    setDoesValueExists: React.Dispatch<React.SetStateAction<boolean>>
  ) => void
}

const CampaignAdGroupRow = ({
  hashtagId,
  adGroupOptions,
  adGroupValue,
  campaignOptions,
  campaignValue,
  handleCampaignChange,
  rowKey,
  setKeyToCampaignIdMapper,
  areCampaignOptionsLoading
}: CampaignAdGroupRowProps) => {
  const [campaignValueExists, setCampaignValueExists] = React.useState(false)
  const targetedActivationList = useTargetedActivationList()
  const selectPickerStyle = campaignValueExists
    ? { width: SELECTION_DROP_DOWN_WIDTH - 30, border: '1px solid red', borderRadius: '6px' }
    : { width: SELECTION_DROP_DOWN_WIDTH - 30 }

  const handleAdGroupChange = (values: number[]) => {
    targetedActivationList.dispatch({
      type: TARGETED_ACTIVATION_ACTIONS.ADD_AD_GROUPS,
      payload: {
        campaignId: campaignValue,
        hashtagId: hashtagId,
        adGroups: values
      }
    })
  }

  const handleRemoveRow = () => {
    setKeyToCampaignIdMapper((prev) => {
      const next = { ...prev }
      delete next[rowKey]
      return next
    })
    targetedActivationList.dispatch({
      type: TARGETED_ACTIVATION_ACTIONS.REMOVE_CAMPAIGN,
      payload: {
        campaignId: campaignValue,
        hashtagId
      }
    })
  }

  const renderMenu = (menu: React.ReactNode) => {
    if (!campaignOptions) {
      return (
        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          <Icon
            icon="spinner"
            spin
          />{' '}
          Loading...
        </p>
      )
    }
    if (campaignOptions.length === 0) {
      return <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>No campaigns Found</p>
    }
    return menu
  }

  const renderAdGroupFooter = (adGroupOptions: any[]) => {
    if (!adGroupOptions.length) return
    return (
      <DataPickerFooter
        rowCount={adGroupOptions.length}
        handleClear={() => {
          handleAdGroupChange([])
        }}
        handleSelectAll={() => {
          handleAdGroupChange(adGroupOptions.map(({ adGroupId }) => adGroupId))
        }}
      />
    )
  }

  const { userCanService } = useUser()
  
  if (areCampaignOptionsLoading) {
    return (
      <>
        <Placeholder.Grid
          rows={1}
          columns={1}
          rowHeight={20}
          active
          className="p-1"
        />
        <Placeholder.Grid
          rows={1}
          columns={1}
          rowHeight={20}
          active
          className="p-1"
        />
      </>
    )
  }

  return (
    <>
      <div
        data-testid="tiktok-act-mgr-target-moments-table-io-adgroup-io-selector"
        className="py-1 text-sm text-gray-500 whitespace-nowrap"
      >
        {campaignValueExists && <p className="text-xs text-red-500">{CAMPAIGN_VALUE_EXISTS}</p>}
        <SelectPicker
          data={campaignOptions || []}
          renderMenu={renderMenu}
          style={selectPickerStyle}
          placement="autoVerticalStart"
          id="campaignSelector"
          labelKey="campaignName"
          valueKey="campaignId"
          disabled={!userCanService(perms.TIKTOK_ACTIVATIONS_UPDATE, products.TIKTOK_ACTIVATIONS)}
          cleanable={false}
          placeholder="Campaign"
          onChange={(value: string) => {
            handleCampaignChange(rowKey, hashtagId, Number(value), campaignValue, setCampaignValueExists)
          }}
          value={campaignValue}
        />
      </div>
      <div>
        {campaignValueExists && <div>&nbsp;</div>}
        <div
          data-testid="tiktok-act-mgr-adgroup-selector"
          className="flex w-full gap-2 py-1 text-sm text-gray-500 whitespace-nowrap"
        >
          <CheckPicker
            data={adGroupOptions}
            disabled={!campaignValue || campaignValueExists || !adGroupOptions.length}
            appearance="default"
            placement="autoVerticalStart"
            placeholder="AdGroups"
            valueKey="adGroupId"
            labelKey="adGroupName"
            className={`${(campaignValue && !(campaignValue % 1)) && adGroupValue.length < 1 && 'rounded-md ring-1 ring-red-600'}`}
            style={{ width: SELECTION_DROP_DOWN_WIDTH }}
            value={adGroupValue}
            onChange={handleAdGroupChange}
            onClean={() => handleAdGroupChange([])}
            renderExtraFooter={() => renderAdGroupFooter(adGroupOptions || [])}
          />
          <RemoveButton {...{ handleRemoveRow, rowKey }} />
        </div>
      </div>
      
    </>
  )
}

export default CampaignAdGroupRow

const RemoveButton = (props: { handleRemoveRow: () => void; rowKey: string }) => {
  const { handleRemoveRow, rowKey } = props
  return (
    <>
      {/* Remove button if it's not the first row */}
      {!rowKey.endsWith('_0') ? (
        <button
          data-testid="tiktok-act-mgr-campaign-adGroup-remove-btn"
          type="button"
          className="rounded-md focus:outline-none "
          onClick={() => {
            handleRemoveRow()
          }}
        >
          <span className="sr-only">Close panel</span>
          <XMarkIcon
            className="w-8 h-8"
            aria-hidden="true"
          />
        </button>
      ) : (
        // Placeholder if it's the first row
        <div className="w-8 h-8 flex-0">&nbsp;</div>
      )}
    </>
  )
}
