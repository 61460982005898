import React, { useEffect, useMemo, useState } from 'react';
import SearchBox from '@/components/SearchBox';
import useUser from '@/hooks/useUser';
import { useActivations, useTargetStatus } from '@/views/TikTok/useTikTok';
import { ActivationStatus, IActivation, ICampaignOption, IAdGroupOption, ITabs } from '@/views/TikTok/Activations/types';
import ActivationsTable from '@/views/TikTok/Activations/components/ActivationsTable';
import { LoadingPage } from '@/components/LoadingPage';
import { InfoPage } from '@/components/InfoPage';
import Tabs from '@/views/TikTok/Activations/components/Tabs';
import { SelectPicker } from 'rsuite';
import { useFlag } from '@unleash/proxy-client-react';
import activationsPlaceholder from '@/assets/img/activationsPlaceholder.svg';
import SightlyButton from '@/components/Sightly/SightlyButton';
import { TrashIcon } from '@heroicons/react/20/solid';
import { api } from '@/api/api';
import { MessageTailwind } from '@/components/MessageTailwind';
import { isAxiosError } from '@/utils_ts';
import { AlertModal } from '../Hashtags/components/AlertModal'

export const TikTokActivations: React.FC = () => {
    const { currentAccount } = useUser()
    const [currentTab, setCurrentTab] = useState<string>(ActivationStatus.pending.id)
    const [currentTabCount, setCurrentTabCount] = useState(0)
    const [activations, setActivations] = useState<IActivation[]>([])
    const [allSelected, setAllSelected] = useState(false)
    const [selected, setSelected] = useState<Set<number>>(new Set())
    const [updateResult, setUpdateResult] = useState<{ success: boolean, message: string }>()
    const [updatingActivations, setUpdatingActivations] = useState(false)

    // Filters options
    const [campaignOptions, setCampaignOptions] = useState<ICampaignOption[]>([])
    const [adGroupOptions, setAdGroupOptions] = useState<IAdGroupOption[]>([])
    const hashtagStatusOptions = [{ label: 'Offline', value: 'OFFLINE' }, { label: 'Online', value: 'ONLINE' }]
    const timeRangeOptions = [
        { id: 1, label: 'Last 24 hrs' },
        { id: 7, label: 'Last 7 days' },
        { id: 30, label: 'Last 30 days' },
        { id: 60, label: 'Last 60 days' }
    ]

    // Filters applied

    const [status, setStatus] = useState<string>(ActivationStatus.pending.id)
    const [searchInput, setSearchInput] = useState<string>()
    const [campaign, setCampaign] = useState<number>()
    const [adGroup, setAdGroup] = useState<number>()
    const [hashtagStatus, setHashtagStatus] = useState<string>()
    const [selectedTimeRange, setSelectedTimeRange] = useState<number>(7)

    const minCreatedDate = useMemo(() => {
        const today = new Date()
        const date = new Date(today)
        date.setDate(today.getDate() - selectedTimeRange)
        return date
    }, [selectedTimeRange])

    const tabs = ActivationStatus
    const { tikTokActivationsQuery } = useActivations(status, currentAccount?.accountId!, searchInput, campaign, adGroup, hashtagStatus, minCreatedDate)

    const [openAlertModal, setOpenAlertModal] = useState(false)
    const [authenticationError, setAuthenticationError] = useState(false)
    const { authenticationStatusQuery } = useTargetStatus(currentAccount?.accountId!)

    useEffect(() => {
        if (authenticationStatusQuery?.isFetching === false) {
            handleHashtagsTarget();
        }
    }, [authenticationStatusQuery?.isFetching])

    useEffect(() => {
        setStatus(currentTab)
        setSelected(new Set())
        setAllSelected(false)
        setSearchInput(undefined)
        setCampaign(undefined)
        setAdGroup(undefined)
        setHashtagStatus(undefined)
    }, [currentTab])

    useEffect(() => {
        if (tikTokActivationsQuery.data) {
            const counts = tikTokActivationsQuery.data.counts
            const config = tikTokActivationsQuery.data.config

            setActivations(tikTokActivationsQuery.data.activations)

            tabs.pending.count = counts.pending
            tabs.active.count = counts.active
            tabs.removed.count = counts.removed
            tabs.failed.count = counts.failed
            const currentTabKey = currentTab.toLowerCase() as keyof ITabs;
            setCurrentTabCount(tabs[currentTabKey].count || 0)

            setCampaignOptions(config.campaignOptions)
            setAdGroupOptions(config.adGroupOptions)
        }
    }, [tikTokActivationsQuery.data])

    const handleSelectAll = (checked: boolean) => {
        setAllSelected(checked)
        if (checked) {
            setSelected(new Set(activations.map((activation) => activation.activationId)))
        } else {
            setSelected(new Set())
        }
    }

    const handleCheckboxChange = (activationId: number) => {
        setSelected((prevSelected) => {
            const updatedSelected = new Set(prevSelected)
            if (updatedSelected.has(activationId)) {
                updatedSelected.delete(activationId)
            } else {
                updatedSelected.add(activationId)
            }

            setAllSelected(updatedSelected.size === activations.length && activations.every((activation) => updatedSelected.has(activation.activationId)))

            return updatedSelected
        })
    }

    const changeActivationsStatus = async () => {
        if (!currentAccount) return;

        setUpdatingActivations(true);

        try {
            const res = await api.tikTok.changeActivationsStatus(currentAccount.accountId, selected);
            const message = `${res.affectedRows} ${res.affectedRows > 1 ? 'Activations were' : 'Activation was'} updated.`;

            tikTokActivationsQuery.refetch();
            setUpdateResult({ success: true, message });
        } catch (error) {
            if (isAxiosError(error) && error.response?.data) {
                const connectivityIssuesMessage = 'We’re currently experiencing connectivity issues with TikTok. Please retry or contact support for help.';
            
                const data = error.response.data as { error?: any };
            
                let message: string;
            
                if (typeof data.error === 'string') {
                    message = data.error;
                } else if (data.error instanceof Error) {
                    message = data.error.message || connectivityIssuesMessage;
                } else {
                    message = connectivityIssuesMessage;
                }
            
                setUpdateResult({ success: false, message });
            }
            
        } finally {
            setUpdatingActivations(false);
        }
    };

    const handleHashtagsTarget = () => {
        if (authenticationStatusQuery?.data?.error) {
            setAuthenticationError(authenticationStatusQuery.data.source == 'authentication')
            setOpenAlertModal(true)
        }
    }

    const tikTokActivationsFeatureFlag = useFlag(`enable_tiktok_activations`)
    if (tikTokActivationsFeatureFlag) {
        const isActivationsEmpty = currentTabCount < 1
        return (
            <div data-testid="activations-tiktok" className="p-14 h-full bg-white rounded-md mx-auto flex flex-col">
                <div className="font-bold text-2xl mb-5">
                    <div data-testid="activations-tiktok-title" className="flex flex-row font-bold main-title">TikTok Activations</div>
                </div>
                {openAlertModal && (
                    <AlertModal
                    open={openAlertModal}
                    handleClose={() => setOpenAlertModal(false)}
                    authentication={authenticationError}
                    />
                )}
                <div className="h-[70%] flex flex-col gap-4">
                    <Tabs
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        tabs={Object.values(tabs)}
                    />
                    <div className="flex justify-between">
                        <div className="flex gap-2">
                            <SelectPicker
                                data-testid="campaign-select"
                                preventOverflow
                                labelKey="campaignName"
                                valueKey="campaignId"
                                placeholder="Campaign"
                                data={campaignOptions}
                                onSelect={(val) => setCampaign(val)}
                                onClean={() => { setCampaign(() => undefined) }}
                                cleanable
                                width={600}
                                searchable
                                disabled={isActivationsEmpty}
                            />
                            <SelectPicker
                                data-testid="adgroup-select"
                                preventOverflow
                                labelKey="adGroupName"
                                valueKey="adGroupId"
                                placeholder="Adgroup"
                                data={adGroupOptions}
                                onSelect={(val) => setAdGroup(val)}
                                onClean={() => { setAdGroup(() => undefined) }}
                                cleanable
                                width={600}
                                searchable
                                disabled={isActivationsEmpty}
                            />
                            <SelectPicker
                                data-testid="status-select"
                                preventOverflow
                                labelKey="label"
                                valueKey="value"
                                placeholder="Status"
                                data={hashtagStatusOptions}
                                onSelect={(val) => setHashtagStatus(val)}
                                cleanable
                                width={600}
                                disabled={isActivationsEmpty}
                                onClean={() => { setHashtagStatus(() => undefined) }}
                                searchable={false}
                            />
                            <SelectPicker
                                defaultValue={selectedTimeRange}
                                data-testid="time-range-select"
                                preventOverflow
                                labelKey="label"
                                valueKey="id"
                                placeholder="Date Range"
                                data={timeRangeOptions}
                                onSelect={(val) => setSelectedTimeRange(val)}
                                width={600}
                                cleanable={false}
                                searchable={false}
                                disabled={isActivationsEmpty}
                            />
                        </div>
                        <div className="w-[20rem]">
                            <SearchBox
                                handleSearch={(searchKey) => setSearchInput(searchKey)}
                                placeholder="Search hashtags"
                                dataTestId="hashtag-name-search"
                                disabled={isActivationsEmpty}
                            />
                        </div>
                    </div>
                    {currentTab === ActivationStatus.failed.id && (
                        <div className="flex flex-row-reverse">
                            <SightlyButton
                                type="sightlyPurple"
                                datatestid="retry-activation-button"
                                handleClick={() => changeActivationsStatus()}
                                text={"Retry activation"}
                                disabled={selected.size === 0 || updatingActivations} />
                        </div>)
                    }
                    {currentTab === ActivationStatus.active.id && (
                        <div className="flex flex-row-reverse">
                            <SightlyButton
                                type="sightlyPurple"
                                datatestid="remove-activation-button"
                                icon={<TrashIcon className="h-4 rounded hover:bg-slate-100 mr-0.5" />}
                                handleClick={() => changeActivationsStatus()}
                                text={"Remove"}
                                disabled={selected.size === 0 || updatingActivations} />
                        </div>)
                    }
                    {currentTab === ActivationStatus.removed.id && (
                        <div className="flex flex-row-reverse">
                            <SightlyButton
                                type="sightlyPurple"
                                datatestid="restore-activation-button"
                                handleClick={() => changeActivationsStatus()}
                                text={"Restore"}
                                disabled={selected.size === 0 || updatingActivations} />
                        </div>)
                    }
                    <div className="flex flex-col gap-4">

                        <MessageTailwind
                            show={!!updateResult}
                            message={updateResult?.message!}
                            type={updateResult?.success ? 'success' : 'error'}
                            handleClose={() => setUpdateResult(undefined)}
                        />

                        <MessageTailwind
                            show={updatingActivations}
                            message="Updating Activations."
                            type="loading"
                            handleClose={() => setUpdatingActivations(false)}
                        />

                        {
                            tikTokActivationsQuery.isLoading ? (
                                <LoadingPage message="Fetching activations" />
                            ) : tikTokActivationsQuery.isError ? (
                                <InfoPage message="There was an unknown error fetching Activations. Please refresh the page to try again. If the problem persists, please contact support." />
                            ) : [ActivationStatus.active.id, ActivationStatus.removed.id, ActivationStatus.failed.id].includes(currentTab) ? (
                                <ActivationsTable
                                    activations={activations}
                                    checkboxesConfig={
                                        {
                                            handleSelectAll: handleSelectAll,
                                            handleCheckboxChange: handleCheckboxChange,
                                            selected: selected,
                                            allSelected: allSelected,
                                            disableOfflineHashtags: ActivationStatus.active.id !== currentTab
                                        }
                                    }
                                />
                            ) : (
                                <ActivationsTable
                                    activations={activations} />
                            )
                        }
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div data-testid="activations-tiktok" className="p-7 mx-5 rounded-md">
                <div data-testid="activations-tiktok-title" className="font-bold main-title">TikTok</div>
                <div data-testid="activations-tiktok-subtitle" className='mt-20'>
                    <span data-testid="activations-tiktok-subtitle-first" className='flex justify-center text-xl font-bold text-black'>Exciting New Features Coming Soon!</span>
                    <span data-testid="activations-tiktok-subtitle-second" className='flex justify-center mt-2 font-semibold'>Activate Ad Groups directly from TikTok</span>
                </div>
                <img src={activationsPlaceholder} alt="Activations Placeholder" className="absolute bottom-0 w-full" />
            </div>
        )
    }
}
