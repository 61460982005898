import React, { useState, useMemo } from 'react';
import { ChevronDownIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { routeWithParamsIsActive } from '@/utils_ts';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';
import { Divider } from 'rsuite';
import useUser from '@/hooks/useUser';
import { accountHasAccess } from '@/services/checkPaymentStatus';


/**
 * An array of navigation item components will be supplied to the parent navigation bar.
Arguments
 * displayText: (string) the text copy shown to the user.
 * itemKey: (string) the unique key for the list item.
 * icon: (string, optional) the hero icon name, asset name, or path to asset for the image shown within the navigation item.
 * navigationTarget: (string, optional if navigationItems are provided) path to the page in the platform or external link that the navigation item will direct the user to. This is not necessary to provide when sub navigationItems are supplied.
 * isMinimized: (boolean, optional, default false) when true, the navigation item is in its minimized state, showing only the icon. When false, the navigation bar is in its full size state. If no icon is provided to the navigation item, a default icon will appear.
 * navigationItems: (navigationItem[], optional) the list of sub-navigation items to display. This will appear as an expandable menu of indented navigation items, which by default is collapsed. If the parent navigation item receives this argument, it will have a down/up toggle chevron that toggles according to the collapsed/expanded state of the  sub navigationItems list.
 * requiredPermissions: (string[], optional) List of required permissions the authenticated user must have. If all of these are not met, the navigation item is disabled.
 * requiredProducts: (string[], optional) List of required products the authenticated user’s account must have. If all of these are not met, the navigation item is disabled.
 * hideIfUnauthorized: (boolean, optional, default false) when true, the navigation item will be hidden instead of disabled when required permissions and products aren’t met. This is to facilitate admin options being hidden from non-admin users.
 * isActive: (boolean, optional) when true, apply the styling of an active item
 * dataTestId: (string, optional) the data-testid to apply
 * activeTargets: (string[], optional) array of route locations. When the current page location is one of these, the NavigationItem will apply its active class styling to itself
 * searchCallback: (function, optional) useSearch support for navigation
 **/

const DEFAULT_SEARCH_CALLBACK = (prev: any) => {
  return { accountId: prev!.accountId }
};



export interface NavigationItemDataProps {
  displayText: string
  itemKey?: string
  icon?: React.ReactElement
  navigationTarget?: string
  isMinimized?: boolean
  navigationItems?: NavigationItemProps[]
  requiredPermissions?: string[]
  requiredProducts?: string[]
  permissionCheckOperator?: string
  productCheckOperator?: string
  hideIfUnauthorized?: boolean
  isActive?: boolean
  dataTestId?: string
  activeTargets?: string[]
  searchCallback?: (search: any) => any
  onClick?: Function
  authorizedIfAnyChild?: boolean
  childIndex?: number
  authorizedChildrenList?: boolean[]
  setIsChildAuthorized?: Function,
  isChild?: boolean,
  displayIfInactiveAccount?:boolean
}

export interface NavigationItemProps extends NavigationItemDataProps {
  userPermissions?: string[]
  userProducts?: string[]
  navigate?: (arg0: any, arg1: any) => void
  activeParams?: any
}

export default function NavigationItem({
  displayText,
  itemKey = `${displayText} key`,
  icon,
  navigationTarget,
  isMinimized,
  navigationItems,
  requiredPermissions = [],
  requiredProducts = [],
  permissionCheckOperator = 'AND',
  productCheckOperator = 'AND',
  hideIfUnauthorized,
  isActive,
  dataTestId,
  activeTargets,
  userPermissions = [],
  userProducts = [],
  navigate = () => { },
  activeParams,
  onClick,
  authorizedIfAnyChild = false,
  childIndex = NaN,
  authorizedChildrenList = [],
  setIsChildAuthorized,
  isChild = false,
  displayIfInactiveAccount = false,
  searchCallback = DEFAULT_SEARCH_CALLBACK }: NavigationItemProps) {
  // authorized if both the user has required permissions
  // and if user's account has required products

  const authorizedPermissions: boolean = useMemo(
    () => checkPerms(requiredPermissions, permissionCheckOperator, userPermissions),
    [requiredPermissions, permissionCheckOperator, userPermissions]
  );

  const { currentAccount } = useUser();
  const accountIsActive = accountHasAccess(currentAccount);

  const authorizedProducts: boolean = useMemo(
    () => checkProducts(requiredProducts, productCheckOperator, userProducts),
    [requiredProducts, productCheckOperator, userProducts]
  );

  let authorized: boolean = authorizedPermissions && authorizedProducts;

  // The parent navigation item of sub items manages the state of
  // showing or hiding itself if any children are authorized
  // (enabled by setting authorizedIfAnyChild = true on the parent)
  // This if block will use the supplied setter to update whether
  // itself (the child) is authorized
  if (setIsChildAuthorized && childIndex && authorizedChildrenList.length) {
    const authList = authorizedChildrenList?.slice() || [];
    authList[childIndex] = authorized;
    setIsChildAuthorized(authList);
  }

  // Parent navigation items of sub items maintain a state array of
  // whether their children are authorized (set by a setter passed to children)
  // This block of code initializes that array and defintes the state variables
  const initialAuthorizedChildren = (authorizedIfAnyChild && navigationItems?.length)
    ? Array.apply(null, Array(navigationItems?.length)).map(() => true)
    : [true];
  const [authorizedChildren, setAuthorizedChildren] = useState(initialAuthorizedChildren);

  // Don't display anything when unauthorized and need to hide item
  // disable functionality when there's no need to hide the item
  let disableButton: boolean = (!hideIfUnauthorized && !authorized);

  // need to set isExpanded to true when one of the navigationItems is active
  const defaultExpanded = navigationItems?.some((item) => {
    return routeWithParamsIsActive({
      myRoutes: item.activeTargets || [],
      myParams: item.activeParams
    })
  });
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    setIsHovered(false)
    if (navigationItems) {
      setIsExpanded(!isExpanded);
    }
    if (navigationTarget) {
      navigate(navigationTarget, searchCallback);
    }
    if (onClick) onClick()
  };



  let activeClass = 'navItem';
  if (isActive) {
    activeClass = 'navItemActive';
  }
  if (isActive && !!navigationItems) {
    activeClass = 'navItemParentActive';
  }

  const widthClass = isMinimized
    ? 'w-[94px]'
    : (isChild ? 'w-[269px]' : 'w-[187px]');


  const unauthorizedCondition = (hideIfUnauthorized && !authorized);
  const subItemUnauthorizedCondition = (authorizedIfAnyChild && !authorizedChildren.some((authed) => authed));


  if (subItemUnauthorizedCondition || unauthorizedCondition || (!displayIfInactiveAccount && !accountIsActive)) {
    return <></>;
  }

  return <div key={itemKey} className={'relative button-menu'}
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
  >
    <button
      data-testid={dataTestId}
      id={dataTestId}
      data-isexpanded={isExpanded}
      onClick={handleClick}
      disabled={disableButton}
      className={`${widthClass} transition-all flex flex-row min-h-[54px] items-center  ${isChild ? 'px-3' : 'mb-3' } min-w-full ${activeClass} ${isMinimized && "justify-center"}`}
    >
      {!isChild &&
        <div className={`${!isMinimized && " mr-2 ml-4"} w-6 justify-center`}>
          {icon}
        </div>}
      {
        !isMinimized && <span className={`text-xs text-left flex-grow ${isChild && 'pl-9'} ${isActive ? 'font-black' : ''}`}>
          {displayText}
        </span>
      }
      {
        !isMinimized && isChild && <ArrowRightIcon data-testid={`right-arrow`} className={`w-6`} />
      }
      {!isMinimized && !isChild && <div className="w-4 flex-1">
        {navigationItems && (<ChevronDownIcon className="mr-8 ml-auto w-5" />)}
      </div>}
    </button>
    {!isMinimized ?
      (isExpanded && navigationItems?.map((navItem, i) => {
        return <NavigationItem
          displayText={navItem.displayText}
          itemKey={navItem.itemKey}
          key={navItem.itemKey}
          navigationTarget={navItem.navigationTarget}
          navigationItems={navItem.navigationItems}
          icon={navItem.icon}
          searchCallback={navItem.searchCallback}
          navigate={navigate}
          onClick={navItem.onClick}
          dataTestId={navItem.dataTestId}
          hideIfUnauthorized={navItem.hideIfUnauthorized}
          requiredPermissions={navItem.requiredPermissions}
          requiredProducts={navItem.requiredProducts}
          userPermissions={userPermissions}
          userProducts={userProducts}
          childIndex={i}
          setIsChildAuthorized={setAuthorizedChildren}
          isActive={routeWithParamsIsActive({
            myRoutes: navItem.activeTargets || [],
            myParams: navItem.activeParams
          })}
        />
      }))
      : (
        <div className={`${isHovered ? 'visible' : 'hidden'} absolute w-auto  top-0 ml-[94px]`} style={{ zIndex: 1 }} >
          <div className={`h5 w-auto rounded-md ml-[10px] sightlyNavBar z-[1]`}>
            <div>
              {navigationItems?.length! > 0 &&
                <div className={`flex items-center ${widthClass} transition-all flex flex-row min-h-[54px] py-1 min-w-full`}>
                  <div className={`ml-3 w-6`}>
                    {icon}
                  </div>
                  <span className={`text-sm text-left ml-3 ${widthClass} ${isActive ? 'font-black' : ''}`}>
                    {displayText}
                  </span>
                </div>
              }
            </div>
              <Divider className='p-0 !m-0'/>
            <div >
              {navigationItems?.map((navItem, i) => {
                return <div className='flex items-center' key={`${navItem.itemKey}-wrapper`} >
                  <NavigationItem
                    displayText={navItem.displayText}
                    itemKey={navItem.itemKey}
                    key={navItem.itemKey}
                    navigationTarget={navItem.navigationTarget}
                    navigationItems={navItem.navigationItems}
                    icon={navItem.icon}
                    searchCallback={navItem.searchCallback}
                    navigate={navigate}
                    onClick={navItem.onClick}
                    dataTestId={navItem.dataTestId}
                    hideIfUnauthorized={navItem.hideIfUnauthorized}
                    requiredPermissions={navItem.requiredPermissions}
                    requiredProducts={navItem.requiredProducts}
                    userPermissions={userPermissions}
                    userProducts={userProducts}
                    childIndex={i}
                    setIsChildAuthorized={setAuthorizedChildren}
                    isActive={routeWithParamsIsActive({
                      myRoutes: navItem.activeTargets || [],
                      myParams: navItem.activeParams
                    })}
                    isChild={true}
                  />
                </div>
              })}
            </div>
          </div>
        </div>
      )}
  </div>
}



/**
 * HELPER FUNCTIONS
 */

interface CheckPermsProps {
  (
    permList: string[],
    operator: string,
    userPermissions?: string[]
  ): boolean
}

const checkPerms: CheckPermsProps = (permList, operator, userPermissions = []) => {
  if (!permList.length) return true;
  return operator === 'AND'
    ? permList.every((perm) => userCanPermissionProductQuota({
      requiredPermissionValue: perm,
      userPermissions: userPermissions,
      checkType: PermissionCheckType.PERMISSION_CHECK
    }))
    : permList.some((perm) => userCanPermissionProductQuota({
      requiredPermissionValue: perm,
      userPermissions: userPermissions,
      checkType: PermissionCheckType.PERMISSION_CHECK
    }));
}

interface CheckProductsProps {
  (
    productList: string[],
    operator: string,
    accountProducts: string[]
  ): boolean
}

const checkProducts: CheckProductsProps = (productList, operator, accountProducts) => {
  if (!productList.length) return true;

  return operator === 'AND'
    ? productList.every((product, i) => userCanPermissionProductQuota({
      requiredProductValue: product,
      userProducts: accountProducts,
      checkType: PermissionCheckType.PRODUCT_CHECK
    }))
    : productList.some((product, i) => userCanPermissionProductQuota({
      requiredProductValue: product,
      userProducts: accountProducts,
      checkType: PermissionCheckType.PRODUCT_CHECK
    }));
}
