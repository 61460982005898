import { SightlyInput } from '@/components/Sightly/SightlyFormElements/SightlyInput'
import SightlyGradientCard from '@/components/Sightly/SightlyGradientCard'
import Tabs from '@/components/Tabs/Tabs'
import { SparklesIcon } from '@heroicons/react/20/solid'
import React, { useState } from 'react'
import { SelectPicker } from 'rsuite'
import GraphsOverview from '@/views/Trends/Results/components/GraphsOverview'
import { IResult, RelevanceClassification } from '@/views/Trends/types'
import ResultCardList from './components/ResultCardList'
import { LoadingPage } from '@/components/LoadingPage'
import { InfoPage } from '@/components/InfoPage'
import { useNavigate } from '@tanstack/react-location'
import { useFlag } from '@unleash/proxy-client-react'

const dummyResults: IResult[] = [
    {
        title: "Growth Trends in 2024",
        description: "An analysis of growth trends for social media engagement in 2024.",
        imageUrl: "https://example.com/image1.jpg", // Optional imageUrl
        totalTrends: 15,
        totalMoments: 25,
        totalSocialPosts: 100,
        growthData: [
            { date: "2024-01-01", posts: 20, growth: 5 },
            { date: "2024-01-02", posts: 25, growth: 3 },
            { date: "2024-01-03", posts: 15, growth: null },
            { date: "2024-01-04", posts: 30, growth: 7 },
        ],
        relevance: RelevanceClassification.High,
        growth: 5, // Added root-level growth
    },
    {
        title: "Social Media Engagement Report",
        description: "Overview of social media engagement for Q1 2024.",
        imageUrl: "https://example.com/image2.jpg", // Optional imageUrl
        totalTrends: 20,
        totalMoments: 30,
        totalSocialPosts: 150,
        growthData: [
            { date: "2024-02-01", posts: 40, growth: 10 },
            { date: "2024-02-02", posts: 35, growth: 2 },
            { date: "2024-02-03", posts: 50, growth: 8 },
            { date: "2024-02-04", posts: 60, growth: null },
        ],
        relevance: RelevanceClassification.Medium,
        growth: 10, // Added root-level growth
    },
    {
        title: "Monthly Performance Analysis",
        description: "A detailed performance analysis of monthly trends.",
        totalTrends: 12,
        totalMoments: 22,
        totalSocialPosts: 90,
        growthData: [
            { date: "2024-03-01", posts: 15, growth: 1 },
            { date: "2024-03-02", posts: 20, growth: 4 },
            { date: "2024-03-03", posts: 25, growth: 3 },
            { date: "2024-03-04", posts: 30, growth: 5 },
        ],
        relevance: RelevanceClassification.Low,
        growth: 5, // Added root-level growth
    },
    {
        title: "Trends in Digital Marketing",
        description: "Exploring the latest trends in digital marketing.",
        imageUrl: "https://example.com/image3.jpg", // Optional imageUrl
        totalTrends: 18,
        totalMoments: 28,
        totalSocialPosts: 120,
        growthData: [
            { date: "2024-04-01", posts: 12, growth: 2 },
            { date: "2024-04-02", posts: 18, growth: 6 },
            { date: "2024-04-03", posts: 25, growth: null },
            { date: "2024-04-04", posts: 30, growth: 8 },
        ],
        relevance: RelevanceClassification.High,
        growth: 8, // Added root-level growth
    },
];


export const TrendsResult = ({ search = 'Mobile gaming' }) => {
    const navigate = useNavigate();
    const trendsEnabled = useFlag('enable_trends_feature');
    if (!trendsEnabled) navigate({ to: '/app/discover/moments/v2' });
  
    const [activeTab, setActiveTab] = useState('0')
    const [category, setCategory] = useState<number>(-1)
    const [timeRange, setTimeRange] = useState<number>(1)
    const [results, setResults] = useState(dummyResults)
    const [loadingResults, setLoadingResults] = useState(false)

    const tabs = [
        { id: '0', title: 'Topics' },
        { id: '1', title: 'Trends' },
        { id: '2', title: 'Moments' },
        { id: '3', title: 'Narratives' }
    ]

    const categoryOptions = [
        { value: -1, label: 'All' },
        { value: 123, label: 'Category 1' },
        { value: 456, label: 'Category 2' },
        { value: 789, label: 'Category 3' }
    ]

    const timeRangeOptions = [
        { value: 1, label: '15 Days' },
        { value: 2, label: '30 Days' },
        { value: 4, label: '60 Days' }
    ]

    const handleSearch = (val: string) => {
        console.log(val)
    }

    const handleTabChange = (tab: string) => {
        setLoadingResults(true)
        setActiveTab(tab)
        setTimeout(() => {
            setLoadingResults(false);
        }, 2000);
    }

    return (
        <div className='px-12 py-8 h-[90%] bg-white rounded-md mx-auto flex flex-col gap-4'>
            <div className="flex gap-8">
                <p className='text-2xl'>Explore</p>
                <SightlyInput
                    placeholder="Search"
                    onChange={handleSearch}
                    id="search"
                    hasSearchIcon
                    defaultValue={search}
                />
            </div>
            <div className='w-full h-full flex animate-in fade-in'>
                <div className="w-2/3 flex flex-col pr-4 border-r border-gray-200">
                    <div className='flex flex-col gap-2'>
                        <p className='text-2xl'>{search}</p>
                        <SightlyGradientCard>
                            <h3 className="text-highlightPurple text-sm">
                                <SparklesIcon
                                    className="h-5 w-5 text-highlightPurple inline mr-2"
                                    aria-hidden="true"
                                />
                                Summary
                            </h3>
                            <p className="text-sm">
                                Conversations around mobile gaming focus on PUBG and BGMI, highlighting gameplay techniques, device performance tests, and sensitivity settings. Users share their experiences with various devices like the iQOO Z9S Pro and Samsung Galaxy Tab, while also promoting live streams and gameplay tips.
                            </p>
                        </SightlyGradientCard>
                    </div>
                    <div className='h-[70%] flex flex-col gap-2'>
                        <div className="w-full flex flex-col gap-4">
                            <Tabs
                                width={'100%'}
                                marginRight={40}
                                active={activeTab}
                                onChange={(active: string) => {
                                    handleTabChange(active)
                                }}
                            >
                                {tabs.map((tab) => (
                                    <div className="flex flex-row items-center" key={tab.id}>
                                        <div>{tab.title}</div>
                                    </div>
                                ))}
                            </Tabs>
                            <div className='flex gap-6'>
                                <div className='flex gap-2 items-center'>
                                    <span className="text-sm">Category</span>
                                    <SelectPicker
                                        data={categoryOptions}
                                        value={category}
                                        onChange={(value) => setCategory(value)}
                                        searchable={true}
                                        cleanable={true}
                                        appearance='subtle'
                                    />
                                </div>
                                <div className='flex gap-2 items-center'>
                                    <span className="text-sm">Date Range</span>
                                    <SelectPicker
                                        data={timeRangeOptions}
                                        value={timeRange}
                                        onChange={(value) => setTimeRange(value)}
                                        searchable={false}
                                        cleanable={false}
                                        appearance='subtle'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='h-full flex overflow-y-auto'>
                            {
                                loadingResults ?
                                    <div className='w-full justify-center items-center'>
                                        <LoadingPage message="Loading results" />
                                    </div>
                                : activeTab === '2' ?
                                    <div className='w-full justify-center items-center'>
                                        <InfoPage message="There are no results for your filters" />
                                    </div>
                                :
                                    <ResultCardList results={results} />
                            }
                        </div>
                    </div>
                </div>
                <div className='w-1/3 flex flex-col gap-4 pl-4'>
                    <GraphsOverview />
                </div>
            </div>
        </div>
    )
}
