import { z } from 'zod';

const areaChartDataSchema = z.object({
  date: z.string(),
  posts: z.number(),
  growth: z.number().nullable(),
});

export type IAreaChartData = z.infer<typeof areaChartDataSchema>;

export enum RelevanceClassification {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low'
}

const resultSchema = z.object({
  title: z.string(),
  description: z.string(),
  imageUrl: z.string().optional(),
  totalTrends: z.number(),
  totalMoments: z.number(),
  totalSocialPosts: z.number(),
  growthData: z.array(areaChartDataSchema),
  relevance: z.enum([RelevanceClassification.High, RelevanceClassification.Medium, RelevanceClassification.Low]),
  growth: z.number()
});

export type IResult = z.infer<typeof resultSchema>;
