import { Navigate } from '@tanstack/react-location';
import React from 'react';
import { LoadingPage } from '@/components/LoadingPage';
import useUser from '@/hooks/useUser';
import { UnauthorizedPage } from '@/auth/UnauthorizedPage'
import { useFlag } from '@unleash/proxy-client-react';
import { routes } from '@/routes';
import config from '@/config';
import {isIframe} from "@/validations";
import {accountHasAccess} from "@/services/checkPaymentStatus";
import {StripePricing} from "@/views/Stripe/pricing/page";
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';

const canSeeWithPermissionsAndProducts = (userCanService:(requiredPermission: string, requiredProduct?: string, action?: string)=>boolean, requiredAccessList:accessListType[]) => {
    if (!requiredAccessList.length) {
        return true;
    }

    for (const requiredAccess of requiredAccessList) {
        const { requiredPermission, requiredProduct, userAction } = requiredAccess;
        if (!userCanService(requiredPermission, requiredProduct, userAction)) {
          return false;
        }
    }
    return true;
}

const canSeeWithAtLeastOnePermissionsAndProducts= (userCanService:(requiredPermission: string, requiredProduct?: string, action?: string)=>boolean, atleastOneAccessList:accessListType[]) => {
  for (const requiredAccess of atleastOneAccessList) {
      const { requiredPermission, requiredProduct, userAction } = requiredAccess;
      if (userCanService(requiredPermission, requiredProduct, userAction)) {
        // if at least one combination of requiredPermission, requiredProduct, and userAction is good, let the user see the component
        return true;
      }
  }
  return false;
}

/**
 *
 * @param {Component} component The view or page to render when user meets permissions requirements
 * @param {requiredAccessList[]} requiredPermission, requiredProduct(optional),userAction(optional) The permissions a user must posses to be granted access.
 * @param {atLeastOneAccessList[]} requiredPermission, requiredProduct(optional),userAction(optional) At least one of these permissions must be met to be granted access.
 * A user has permissions through roles.
 * A user has products through their account's package, which is joined to products through package_products
 * @returns needPermissionsPage or error copy
 */

export type accessListType = {
    requiredPermission: string
    requiredProduct?:string
    userAction?: string
}
type ProtectedRoutePropType = {
    requiredAccessList?: accessListType[]
    atleastOneAccessList?: accessListType[]
    component: React.ComponentType<any>
    checkAccountActive?: boolean
}

const ProtectedRoute = ({ component : Component, requiredAccessList=[], atleastOneAccessList=[], checkAccountActive = true}:ProtectedRoutePropType) => {
  const { accounts, user, currentAccount, perms, isLoading, isInvalidUser, userCanService, products, userProducts,  } = useUser();

  const socialDashboardEnabled = userCanPermissionProductQuota({
    requiredProductValue: products.KRAFT_SOCIAL_METABASE_DASHBOARD,
    userProducts,
    checkType: PermissionCheckType.PRODUCT_CHECK
  }) || userCanPermissionProductQuota({
    requiredProductValue: products.LULULEMON_SOCIAL_METABASE_DASHBOARD,
    userProducts,
    checkType: PermissionCheckType.PRODUCT_CHECK
  })
  
  const accountIsActive = accountHasAccess(currentAccount)

  const canSee = React.useMemo(
    () => {
      if (atleastOneAccessList.length) {
        return canSeeWithAtLeastOnePermissionsAndProducts(userCanService, atleastOneAccessList);
      }
      return canSeeWithPermissionsAndProducts(userCanService, requiredAccessList);
    },
    [user, perms, accounts, currentAccount, requiredAccessList, atleastOneAccessList, userCanService]
  )

  if (isLoading) {
    return <LoadingPage message="Loading User Data" />
  }

  if (isInvalidUser) {
    return <Navigate to={'/userNotFound'} />
  }

  if (!accountIsActive) {
    const routeToAccount = currentAccount?.stripeSubscriptionId && location.pathname !== routes.app.settings.account.path
    const routeToStripe = !currentAccount?.stripeSubscriptionId && location.pathname !== routes.stripe.path

    if (isIframe() && routeToStripe) {
      return <StripePricing />
    }

    if (routeToAccount) {
      return <Navigate to={routes.app.settings.account.path} />
    }
  }

  if (location.pathname === routes.app.socialTopics.path && !socialDashboardEnabled) {
    return <UnauthorizedPage/>
  }

  if(checkAccountActive && !accountIsActive) {
    return <UnauthorizedPage/>
  }

  if (canSee) {
    return <Component />
  }

  return <UnauthorizedPage/>
}
export default ProtectedRoute;
