import React from "react";
import { TagPicker } from 'rsuite'

interface Item {
    label: string;
    value: string;
  }

interface IProps {
    insertionOrders: Item[]
    handleOnChange: Function 
}

export default function InsertionOrder({ insertionOrders=[{ label: 'p1', value: 'p1'}], handleOnChange }: IProps) {

    return (
        <div data-testid='div-report-generator-select-insertion-orders'>
            <h5 className='mt-3 mb-2 text-base'>Insertion Orders</h5>
            <div className='mb-4 custom-tag-picker-color custom-tag-picker-color-checked'>
                <TagPicker
                    data={insertionOrders}
                    onChange={(value) => handleOnChange('insertionOrderIds', value)}
                    virtualized
                    searchable={false}
                />
            </div>
        </div>
    )
}