import { account } from '@/api/account'
import { adminIabCategories } from '@/api/adminIabCategories'
import { apiVersion } from '@/api/apiVersion'
import { aylienIndustries } from '@/api/aylienIndustries'
import { brandProfile } from '@/api/brandProfile'
import { boardsV1 } from '@/api/boardsV1'
import { user } from '@/api/user'
import { userCompletionSteps } from '@/api/userCompletionSteps'
import { socialCluster } from '@/api/socialCluster'
import { tikTok } from '@/api/tiktok'

export type KeywordType = {
  keyword: string
  archived: boolean
}

export interface AdminIabCategory {
  disregardLeafNode: boolean
  iabCategoryId: string
  iabCategoryName: string
  keywords: KeywordType[]
}

export type MapKeywordsProps = {
  keywords: KeywordType[]
  iabCategoryId: string
}

export const api = {
  apiVersion,
  user,
  account,
  aylienIndustries,
  brandProfile,
  adminIabCategories,
  userCompletionSteps,
  boardsV1,
  socialCluster,
  tikTok
}
