import React from 'react'
import { useQuery, useMutation, useQueryClient, useInfiniteQuery } from '@tanstack/react-query'
import { listBuilderAxios } from '@/axiosInstances'
import { useNavigate } from '@tanstack/react-location'
import { rqKeys } from '@/ReactQueryKeyFactory'
import { logError } from '@/utils'
import { z } from 'zod'
import {ClusterSchema, ClusterType, MomentSortBy} from '@/views/Discover/Moments/v2/types'
import { api, RecActionFeedbackProps } from '@/views/Discover/Moments/v2/api'
import { useAlert } from '@/hooks/useAlert'
import { rqKeys as MomentViewRqKeys } from "@/views/Discover/Moments/v2/rqKeys";
import useTargetMomentsInfo from '@/views/Discover/Moments/v2/TargetMoment/hooks/useTargetMomentsInfo'

type GetMomentsProps = {
    brandProfileId: number | undefined
    boardIds: number[] | undefined
    sortBy: MomentSortBy
    timeRange: number | undefined
    startDate: string
    endDate: string
    actions: string[]
    targetStatus: any[]
    searchTerm: string
    aylienNews: string[]
    aylienIndustries: string[]
    page: number
    signal: AbortSignal | undefined // React query gives us this to use as axios cancel signal, to prevent duplicate calls,

}

const GetMomentsResultSchema = z.object({
    data: z.array(ClusterSchema),
    metaData: z.object({
        totalResultCount: z.number(),
        page: z.number(),
        requestedPageSize: z.number()
    })
})

const getMoments = async ({
    brandProfileId,
    boardIds,
    sortBy,
    timeRange,
    startDate,
    endDate,
    actions,
    targetStatus,
    searchTerm,
    aylienIndustries,
    aylienNews,
    page,
    signal //axios cancel
}: GetMomentsProps) => {
    if (typeof brandProfileId === undefined) {
        return Promise.reject(new Error('undefined brandprofileId'))
    }
    if (typeof boardIds === undefined) {
        return Promise.reject(new Error('undefined boardIds'))
    }
    if (typeof timeRange === undefined) {
        return Promise.reject(new Error('undefined timeRange'))
    }

    const locale = navigator.language
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const url = `/brand-profile/${brandProfileId}/moment-clusters`;

    const { data } = await listBuilderAxios.post(
    url,
    {
        boardIds,
        timeRange,
        locale,
        timeZone,
        startDate,
        endDate,
        actions,
        targetStatus,
        size: 20,
        page,
        sortBy,
        searchTerm,
        aylienNews,
        aylienIndustries
    },
    {
        signal
    }
    );

    return GetMomentsResultSchema.parse(data);
}

type IProps = {
    brandProfileId: number | undefined
    boardIds: number[]
    timeRange: number
    startDate: string
    endDate: string
    searchTerm: string
    sortBy: MomentSortBy
    actions: string[]
    targetStatus: any[]
    showTargetSuccess: boolean
}

const getLocalSelectedMoments = () => {
    const storedSelectedMoments = sessionStorage.getItem('selectedMoments')

    return storedSelectedMoments && storedSelectedMoments !== 'undefined' && JSON.parse(storedSelectedMoments)
}

const getDefaultSelectedMoments = (brandProfileId: number | undefined):ClusterType[] => {
    const selectedMomentsAll = getLocalSelectedMoments()
    return  (brandProfileId && selectedMomentsAll && selectedMomentsAll[brandProfileId] ) || []
}

export const useMoments = ({ brandProfileId, boardIds, timeRange, startDate, endDate, searchTerm, sortBy, actions, targetStatus, showTargetSuccess }: IProps) => {
    const { showAlert, renderAlert } = useAlert()
    const [selectedMoments, setSelectedMoments] = React.useState<ClusterType[]>(getDefaultSelectedMoments(brandProfileId) || [])
    const [aylienNews, setAylienNews] = React.useState<string[]>([])
    const [aylienIndustries, setAylienIndustries] = React.useState<string[]>([])
    const {resetTargetMomentsInfo} = useTargetMomentsInfo()

    const selectedMomentIds = React.useMemo(() => {
        // Resets the TargetMomentsSlideOver Info
        if(!selectedMoments.length) resetTargetMomentsInfo()
        return (selectedMoments && selectedMoments.map((trend: ClusterType) => trend.clusterId)) || []
    }, [selectedMoments])

    const resetSelectedMoments = (brandProfileId:number | undefined) => {
        const selectedMomentsAll = getLocalSelectedMoments()
        brandProfileId && delete selectedMomentsAll[brandProfileId]
        sessionStorage.setItem('selectedMoments', JSON.stringify(selectedMomentsAll))
        setSelectedMoments([])
    }

    const queryKey = rqKeys.moments({
        boardIds,
        brandProfileId,
        sortBy,
        timeRange,
        startDate,
        endDate,
        actions,
        targetStatus,
        searchTerm,
        aylienIndustries,
        aylienNews
    })

    const taxonomiesArgs = { brandProfileId, timeRange, startDate, endDate, boardIds, actions, searchTerm }

    const taxonomies = useQuery(
        rqKeys.taxonomies(taxonomiesArgs),
        ({ signal }) => api.moments.taxonomies.get({ ...taxonomiesArgs, signal }),
        {
            onSettled: () => {
                setAylienNews([])
                setAylienIndustries([])
            },
            onError: (err) => {
                logError(err, taxonomiesArgs)
            },
            enabled: !!timeRange && !!brandProfileId && !!boardIds && boardIds.length > 0
        }
    )

    const queryClient = useQueryClient()

    const getMomentsArgs = {
        brandProfileId,
        boardIds,
        sortBy,
        timeRange,
        startDate,
        endDate,
        actions,
        targetStatus,
        searchTerm,
        aylienIndustries,
        aylienNews
    }

    const queryIsEnabled =
        brandProfileId !== undefined &&
        !!brandProfileId &&
        !!boardIds &&
        boardIds.length > 0 &&
        actions &&
        actions.length > 0 &&
        !!timeRange
        const momentsQuery = useInfiniteQuery(
        queryKey,
        ({ pageParam = 1, signal }) => getMoments({ ...getMomentsArgs, page: pageParam, signal }),
        {
            getNextPageParam: (lastPage, pages) => (lastPage.data.length < 10 ? undefined : pages.length + 1),
            enabled: queryIsEnabled,
            onError: (err) => {
                logError(err, { info: 'error getting moments' })
            }
        }
    )

    const {
        data,
        error,
        fetchNextPage,
        //   hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
        isFetched: momentsIsFetched,
        refetch
    } = momentsQuery

    const moments: ClusterType[] = []

    if (momentsQuery?.data?.pages) {
        for (const page of momentsQuery.data.pages) {
            moments.push(...page.data)
        }
    }
    React.useEffect(()=>{
        showTargetSuccess && refetch()
    },[showTargetSuccess])

    const lastMetaData = data?.pages[data.pages.length - 1].metaData

    const totalResultCount = data?.pages[data.pages.length - 1].metaData.totalResultCount || 0 // momentsQuery?.metaData.totalResultCount || 0
    const metaData = lastMetaData || {
        totalResultCount: 0,
        requestedPageSize: 0,
        page: 0
    }
    const hasNextPage = metaData ? metaData?.totalResultCount > metaData?.page * metaData?.requestedPageSize : false

    const getMoment = (clusterId: number, pages: any) => {
        for (const page of pages) {
            for (const moment of page.data) {
                if (moment.clusterId === clusterId) return moment
            }
        }
        return queryClient.getQueryData(MomentViewRqKeys.momentInViewSlideOver(brandProfileId, clusterId))
    }

    const handleSelectMoment = (moment: ClusterType) => {
        setSelectedMoments((prev: ClusterType[]) => {
            return selectedMomentIds.includes(moment.clusterId)
                ? prev.filter((i: ClusterType) => i.clusterId !== moment.clusterId)
                : prev.concat(moment)
        })
    }

    React.useEffect(() => {
      let selectedMomentsAll = getLocalSelectedMoments()
      // Merges previous stored value with new selectedMoments

      if (selectedMomentsAll && brandProfileId) {
        const prevSelectedMoments: ClusterType[] = selectedMomentsAll[brandProfileId]
        if (prevSelectedMoments) {
          const prevMomentMap = new Map(prevSelectedMoments.map((moment) => [moment.clusterId, moment]))
          selectedMomentsAll[brandProfileId] = selectedMoments.map((moment) => {
            const prevValue = prevMomentMap.get(moment.clusterId)
            if (prevValue) return prevValue
            return moment
          })
        } else{
            selectedMomentsAll[brandProfileId] = selectedMoments
        }
      } else selectedMomentsAll = (brandProfileId && { [brandProfileId]: selectedMoments }) || selectedMomentsAll
      sessionStorage.setItem('selectedMoments', JSON.stringify(selectedMomentsAll))
    }, [selectedMoments]);

    const selectAllOnPage = useMutation(
        () => {
            return Promise.resolve(true)
        },
        {
            onMutate: async (isSelected: boolean) => {
                if (isSelected) {
                    const currentTrends = moments?.map((i: ClusterType) => i.clusterId) || []
                    setSelectedMoments((prev: ClusterType[]) => {
                        const final = prev
                            .filter((t: ClusterType) => !currentTrends.includes(t.clusterId))
                            .concat(moments || [])
                        return final
                    })
                } else {
                    setSelectedMoments([])
                }
            }
        }
    )

    const navigate = useNavigate()

    const recActionFeedback = useMutation(api.moments.feedback.recommendedActionFeedback, {
        onMutate: async (args) => {
            queryClient.setQueryData(queryKey, (momentsResult: any) => {
                const newMoments = momentsResult
                const moment = getMoment(args.clusterId, newMoments.pages)
                moment.recommendedActionFeedback = args.feedback
                return newMoments
            })
        },
        onError: (err) => {
            logError(err, { info: 'error setting rec action feedback' })
        },
        onSuccess: () => {
            showAlert()
        }
    })

    const keywordsFeedback = useMutation(
        (args: RecActionFeedbackProps) => {
            const url = `/brand-profile/${brandProfileId}/moment-clusters/${args.clusterId}/feedback-keywords`
            return listBuilderAxios.post(url, {
                feedback: args.feedback
            })
        },
        {
            onMutate: async (args) => {
                queryClient.setQueryData(queryKey, (momentsResult: any) => {
                    const newMoments = momentsResult
                    const moment = getMoment(args.clusterId, newMoments.pages)
                    moment.keywordsFeedback = args.feedback
                    return newMoments
                })
            },
            onSuccess: () => {
                showAlert()
            },
            onError: (err) => {
                logError(err, { info: 'error on keyword feedback mutation' })
            }
        }
    )

    return {
        moments,
        fetchNextPage,
        momentsIsFetched,
        isFetchingNextPage,
        isMomentsError: momentsQuery.isError,
        handleSelectMoment,
        handleSelectAllOnPage: selectAllOnPage.mutate,
        momentsIsLoading:
            (momentsQuery.isLoading || taxonomies.isLoading || taxonomies.isFetching) &&
            (momentsQuery.fetchStatus !== 'idle' || taxonomies.fetchStatus !== 'idle'),
        sortBy,
        setSortBy: (val: MomentSortBy) => {
            navigate({
                search: (prev) => {
                    return {
                        ...prev,
                        sortBy: val
                    }
                }
            })
        },
        selectedMomentIds,
        postTrendRecActionFeedback: recActionFeedback.mutate,
        postTrendKeywordsFeedback: keywordsFeedback.mutate,
        aylienNewsOptions: taxonomies?.data?.newsCategories || [],
        aylienIndustriesOptions: taxonomies?.data?.industryCategories || [],
        setAylienNews,
        setAylienIndustries,
        aylienNews,
        aylienIndustries,
        totalResultCount,
        hasNextPage,
        requestedPageSize: metaData?.requestedPageSize || 0,
        selectedMoments,
        resetSelectedMoments,
        setSelectedMoments,
        getDefaultSelectedMoments,
        invalidateMomentsQuery: () => queryClient.invalidateQueries(queryKey),
        invalidateAylienTaxonomiesQuery: () => queryClient.invalidateQueries(rqKeys.taxonomies(taxonomiesArgs)),
        renderAlert
    }
}
