import { useNavigate } from '@tanstack/react-location';
import { useFlag } from '@unleash/proxy-client-react';
import React from 'react';

const Trends = () => {
  const navigate = useNavigate();
  const trendsEnabled = useFlag('enable_trends_feature');
  if (!trendsEnabled) navigate({ to: '/app/discover/moments/v2' });

  return (
    <div className="p-14 h-full bg-white rounded-md mx-auto flex flex-col">
      <h1>Trends</h1>
    </div>)
}

export default Trends;