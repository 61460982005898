import React from 'react';
import MomentPreviewCard from '@/views/Discover/Boards/components/MomentPreviewCard';
import { MomentPreviewType } from '@/views/Discover/Boards/types';
import Loader from 'rsuite/lib/Loader';
import noMomentsFound from '@/assets/img/no-moments-found.svg';
import { useFlag } from '@unleash/proxy-client-react';
import config from '@/config';
import {TotalPreviewsCard} from "@/views/Discover/Boards/components/TotalPreviewsCard";

interface IProps {
  previewMoments: MomentPreviewType[];
  isLoading?: boolean;
  previewsCount: number;
  previewsCountLongRange: number;
  previewsCountMediumRange: number;
  setMomentsThumbUp: Function,
  momentsThumbUp: []
  setMomentsThumbDown: Function,
  momentsThumbDown: []
  thumbUpLimitReached: Boolean
  thumbDownLimitReached: Boolean
}

const MomentPreviewList: Function = ({
  previewMoments = [],
  isLoading,
  previewsCount,
  previewsCountLongRange,
  previewsCountMediumRange,
  setMomentsThumbUp,
  momentsThumbUp,
  setMomentsThumbDown,
  momentsThumbDown,
  thumbUpLimitReached,
  thumbDownLimitReached
}: IProps) => {
  const hasMoments = !!previewMoments.length;
  const filteredMoments = previewMoments.filter((preview) => !!preview);
  const socialPostsEnabled = useFlag(`enable_social_posts_${config.environment}`);

  if (isLoading) {
    return <div className="flex justify-center items-center grow">
      <Loader size="lg" />
    </div>
  }

  const containerHeightClass = socialPostsEnabled
  ? 'max-h-[calc(100%-48px)]'
  : 'max-h-full';

  const heightClass = socialPostsEnabled
    ? 'max-h-[calc(200px - 12px)]'
    : 'max-h-[calc(100%-132px)]';

  return (
    <div data-testid="preview-moment-list" className={`${containerHeightClass} bg-slate-50 flex flex-col`}>
      <TotalPreviewsCard
        title={'Total Moments'}
        isLoading={isLoading}
        previewsCount={previewsCount}
        previewsCountMediumRange={previewsCountMediumRange}
        previewsCountLongRange={previewsCountLongRange}
      />

      {(hasMoments && <div data-testid="nl-preview-moments-list" className={`overflow-scroll bg-slate-50 h-full ${heightClass}`}>
        {filteredMoments.map((momentPreview) => {
          return <MomentPreviewCard
            key={momentPreview.name}
            momentPreview={momentPreview}
            momentsThumbUp={momentsThumbUp}
            setMomentsThumbUp={setMomentsThumbUp}
            momentsThumbDown={momentsThumbDown}
            setMomentsThumbDown={setMomentsThumbDown}
            thumbUpLimitReached={thumbUpLimitReached}
            thumbDownLimitReached={thumbDownLimitReached}
          />
        })}
        <p className="my-4">
          Displaying {
            filteredMoments.length >= 30
              ? 30
              : filteredMoments.length
          } moments
        </p>
      </div>)}
      {(!hasMoments && <div className='flex mt-20 mr-8 justify-center'>
        <img src={noMomentsFound} alt="noMomentsFound" className="mr-2" />
      </div>)}
    </div>
  )
}

export default MomentPreviewList;
