import React, { useState, useRef } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import Dropdown from 'rsuite/lib/Dropdown';
import {Loader, Popover, Whisper} from 'rsuite';
import PlatformIcon from '@/views/Discover/Boards/components/conversations/PlatformIcon';
import { ConversationPanelSectionLayout } from '../PanelSectionLayout.';

interface Post {
  id: string
  platformName: string
  post: string
  url: string
  publishedDate: string
}

const SocialPostsTabContents = ({ posts }: { posts: Post[]}) => {
  const socialPostsData = posts;
  const [filteredPosts, setFilteredPosts] = useState(socialPostsData.slice());
  const [selectedSourceType, setSelectedSourceType] = useState('All');
  const [popoverOpen, setPopoverOpen] = useState(false);

  const uniqueValues: { [key: string]: boolean } = {};
  const sourceTypeOptions: string[] = socialPostsData
    .flatMap(obj => obj.platformName)
    .reduce((acc, currentValue) => {
      if (!uniqueValues[currentValue]) {
        uniqueValues[currentValue] = true;
        acc.push(currentValue);
      }
      return acc;
    }, [] as string[]);

  const dropdownOptions = [
    'All',
    ...sourceTypeOptions
  ];

  const handleDropdownSelect = (selectedSourceType: string) => {
    setSelectedSourceType(selectedSourceType);
    setPopoverOpen(false);

    if (selectedSourceType === 'All') {
      setFilteredPosts(posts);
    } else {
      const postsFilteredBySource = posts.filter(post =>
        post.platformName.includes(selectedSourceType)
      );
      setFilteredPosts(postsFilteredBySource);
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const getContainer = () => {
    if (containerRef.current) {
      return containerRef.current;
    }
    return document.body;
  };

  if (!posts.length) {
    return <Loader center/>
  }

  return (
    <ConversationPanelSectionLayout headerName="Posts" headerCount={posts.length}>
      <div className="ml-8">
        {/* Source Type Dropdown Selector */}
        <Whisper
          container={getContainer}
          placement="bottomEnd"
          trigger="click"
          speaker={
            <Popover full>
              <Dropdown.Menu data-testid={'post-source-dropdown'} onSelect={handleDropdownSelect}>
                {dropdownOptions.map(option => (
                  <Dropdown.Item
                    key={`post-source-option-${option}`}
                    eventKey={option}
                  >
                    {option}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Popover>
          }
          onHide={() => setPopoverOpen(false)}
          open={popoverOpen}
        >
          <span onClick={() => setPopoverOpen(!popoverOpen)}>
            {selectedSourceType}
            <ChevronDownIcon className="h-3 inline-block ml-2"/>
          </span>
        </Whisper>

        {/* Filtered Posts List */}
        <div className="mt-4">
          {filteredPosts.map(post => (
            <div key={post.id} className="flex flex-row items-center mb-4">
              <div className="inline-block mr-2 shrink-0 w-8">
                <PlatformIcon platformName={post.platformName.toLowerCase()} height='2.25rem'></PlatformIcon>
              </div>
              <div className="inline-block font-semibold">
                <a href={post.url} target="_blank" rel="noopener noreferrer">
                  {post.post}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </ConversationPanelSectionLayout>
  )
};

export default SocialPostsTabContents;
