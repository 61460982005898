import React from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import SightlyToggle from '@/components/Sightly/SightlyFormElements/SightlyToggle'
import SightlyButton from '@/components/Sightly/SightlyButton'
import { ReactComponent as KeywordsIcon } from '@/assets/img/keywordsKey.svg'
import { ReactComponent as BlackYoutube } from '@/assets/img/blackYoutube.svg'
import { ClusterActivationType, ClusterType } from './types'
import { useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '@/classes/utils'
import useBrandProfileInsertionOrders from '@/hooks/brandProfile/useBrandProfileInsertionOrders'
import { SmallBadge } from '@/components/SmallClosableBadge'

interface IProps {
    open: boolean
    setOpen: (val: boolean) => void
    selectedMoments: ClusterType[]
    handleBlock: ({
                      keywordClusters,
                      placementClusters,
                      ioIds
                  }: {
        keywordClusters: ClusterActivationType[]
        placementClusters: ClusterActivationType[]
        ioIds: number[]
    }) => void
    handleSelectMoment: (moment: ClusterType) => void
}

export function BlockSlideOver({ open, setOpen, selectedMoments, handleBlock }: IProps) {
    const { brandProfileId } = useSearch<MyLocationGenerics>()
    const iosQuery = useBrandProfileInsertionOrders(brandProfileId)
    const [uncheckedPlacements, setUncheckedPlacements] = React.useState<number[]>([])
    const [uncheckedKeywords, setUncheckedKeywords] = React.useState<number[]>([])
    const [unselectedIos, setUnselectedIos] = React.useState<number[]>([])

    const displayedIos = React.useMemo(() => {
        if (!iosQuery.data) return []
        return iosQuery.data.filter((io) => !unselectedIos.includes(io.ioId)) || []
    }, [iosQuery.data, unselectedIos])

    const selectedKeywordClusters = selectedMoments
        .filter((moment) => !uncheckedKeywords.includes(moment.clusterId))
        .map(({clusterId, clusterName}) => ({clusterId, clusterName}))

    const selectedPlacementClusters = selectedMoments
        .filter((moment) => !uncheckedPlacements.includes(moment.clusterId))
        .map(({clusterId, clusterName}) => ({clusterId, clusterName}))

    React.useEffect(() => {
        setUncheckedKeywords([])
        setUncheckedPlacements([])
        setUnselectedIos([])
    }, [open])

    const handleConfirmClick = () => {
        const args = {
            keywordClusters: selectedKeywordClusters,
            placementClusters: selectedPlacementClusters,
            ioIds: displayedIos.map((io) => io.ioId)
        }
        handleBlock({ ...args });
        setOpen(false);
    }

    return (
        <Transition.Root
            show={open}
            as={Fragment}
        >
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => {
                    setOpen(false);
                  }}
            >
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="w-screen max-w-3xl pointer-events-auto">
                                    <div className="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl">
                                        <div className="flex flex-col flex-1 min-h-0 pb-6">
                                            <div className="px-4 pt-6 pb-6 text-white bg-red-500 sm:px-6">
                                                <div className="flex items-start justify-between">
                                                    <Dialog.Title className="text-lg font-mediu">
                                                        Block Moments
                                                    </Dialog.Title>

                                                    <div className="flex items-center ml-3 h-7">
                                                        <button
                                                            type="button"
                                                            className="text-white bg-red-500 rounded-md hover:text-slate-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                            onClick={() => setOpen(false)}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon
                                                                className="w-6 h-6"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                                <Dialog.Description>
                                                    Get started by filling in the information below to block your
                                                    moments.
                                                </Dialog.Description>
                                            </div>

                                            <div className="px-4 pt-6 pb-6 font-semibold sm:px-6 text-grey-900">
                                                Insertion Orders
                                            </div>

                                            <div className="flex flex-wrap gap-4 px-4 pb-6 font-semibold sm:px-6 text-grey-900">
                                                {displayedIos.map((io) => {
                                                    return (
                                                        <SmallBadge
                                                            disabled={displayedIos.length < 2}
                                                            key={io.ioId}
                                                            text={io.ioName}
                                                            onClose={() =>
                                                                setUnselectedIos((prev) => prev.concat(io.ioId))
                                                            }
                                                        />
                                                    )
                                                })}
                                            </div>
                                            <div className="w-full border-t border-grey-200" />
                                            <div className="relative flex-1 mt-6 overflow-y-auto">
                                                <div className="inline-block min-w-full py-2 align-middle md:px-6">
                                                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                        <table className="min-w-full divide-y divide-gray-300">
                                                            <thead className="bg-gray-50">
                                                            <tr>
                                                                <th
                                                                    scope="col"
                                                                    className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6"
                                                                >
                                                                    Moment
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                                                                >
                                                                    Keywords
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                                                                >
                                                                    Videos
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody className="bg-white divide-y divide-gray-200 ">
                                                            <tr>
                                                                <td className = "max-w-sm py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
                                                                    <div className="truncate"
                                                                         style={{
                                                                             fontWeight: 'bold',
                                                                             color: '#7F868D'
                                                                         }}
                                                                    >
                                                                        Set for all Moments
                                                                    </div>
                                                                </td>
                                                                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                                                    <SightlyToggle
                                                                        id="keywords-select-all-toggle"
                                                                        enabled={!uncheckedKeywords.length}
                                                                        setEnabled={(toggled: boolean) =>
                                                                            setUncheckedKeywords(() =>
                                                                                toggled
                                                                                    ? []
                                                                                    : selectedMoments.map((moment) => moment.clusterId)
                                                                            )
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                                                    <SightlyToggle
                                                                        id="videos-select-all-toggle"
                                                                        enabled={!uncheckedPlacements.length}
                                                                        setEnabled={(toggled: boolean) =>
                                                                            setUncheckedPlacements(() =>
                                                                                toggled
                                                                                    ? []
                                                                                    : selectedMoments.map((moment) => moment.clusterId)
                                                                            )
                                                                        }
                                                                    />
                                                                </td>
                                                            </tr>
                                                            {selectedMoments.map((moment) => (
                                                                <tr key={moment.clusterId}>
                                                                    <td className="max-w-sm py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
                                                                        <div className="truncate">
                                                                            {moment.clusterName}
                                                                        </div>
                                                                        <div className="mt-2 statsContainer">
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center'
                                                                                }}
                                                                            >
                                                                                <KeywordsIcon />
                                                                                <div className="pl-1 text-xs text-gray-500">
                                                                                    {moment.blockKeywordsCount === 1
                                                                                        ? '1 keyword'
                                                                                        : moment.blockKeywordsCount +
                                                                                        ' keywords'}
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center'
                                                                                }}
                                                                            >
                                                                                <BlackYoutube />
                                                                                <div className="pl-1 text-xs text-gray-500">
                                                                                    {moment.videoCount === 1
                                                                                        ? '1 video'
                                                                                        : moment.videoCount +
                                                                                        ' videos'}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                                                        <SightlyToggle
                                                                            id="kw"
                                                                            enabled={
                                                                                !uncheckedKeywords.includes(
                                                                                    moment.clusterId
                                                                                )
                                                                            }
                                                                            setEnabled={(toggled: boolean) =>
                                                                                setUncheckedKeywords((prev) =>
                                                                                    toggled
                                                                                        ? prev.filter(
                                                                                            (v) =>
                                                                                                v !==
                                                                                                moment.clusterId
                                                                                        )
                                                                                        : prev.concat(
                                                                                            moment.clusterId
                                                                                        )
                                                                                )
                                                                            }
                                                                        />
                                                                    </td>
                                                                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                                                        <SightlyToggle
                                                                            id="placements"
                                                                            enabled={
                                                                                !uncheckedPlacements.includes(
                                                                                    moment.clusterId
                                                                                )
                                                                            }
                                                                            setEnabled={(toggled: boolean) =>
                                                                                setUncheckedPlacements((prev) =>
                                                                                    toggled
                                                                                        ? prev.filter(
                                                                                            (v) =>
                                                                                                v !==
                                                                                                moment.clusterId
                                                                                        )
                                                                                        : prev.concat(
                                                                                            moment.clusterId
                                                                                        )
                                                                                )
                                                                            }
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex justify-end flex-shrink-0 gap-3 px-4 py-4">
                                            <SightlyButton
                                                type="secondary"
                                                text="Cancel"
                                                id="pendo-cancel-block-button"
                                                handleClick={() => setOpen(false)}
                                            />
                                            <SightlyButton
                                                disabled={
                                                    selectedKeywordClusters.length +
                                                    selectedPlacementClusters.length ===
                                                    0
                                                }
                                                text="Confirm"
                                                id="confirmBlockButton"
                                                handleClick={handleConfirmClick}
                                            />
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                    <style>
                        {`
                          .statsContainer {
                              display: grid;
                              grid-template-columns: 130px 130px 130px;
                              grid-auto-flow: row;	
                          }
				                `}
                    </style>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
