import React, { useEffect, useRef, useState } from 'react';
import Input from 'rsuite/lib/Input';

import { useNavigate, useSearch } from '@tanstack/react-location'
import { useQuery } from '@tanstack/react-query'
import {PencilSquareIcon, ClockIcon, ChevronDownIcon, ChevronRightIcon, ChevronLeftIcon} from '@heroicons/react/24/outline';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid'

import {Button, Notification, Dropdown, Icon, Modal, Popover, Whisper} from 'rsuite';
import Tabs from '@/components/Tabs/Tabs';
import { ActivationReportForm } from './ActivationReportForm';
import {
    ActivationReportConfiguration,
    ConversionOption,
    GenericDimensionOption,
    GRANULARITY,
    GROUPING,
    MetricOption,
    ReportTemplate,
    templateConversionOptions,
    templateGenericDimensionOptions,
    templateMetricOptions
} from '@/views/ActivationReports/types/activation-report.types';
import { ValueType } from 'rsuite/lib/DateRangePicker'
import InsertionOrder from '@/views/ActivationReports/components/InsertionOrder';
import DateRange from '@/views/ActivationReports/components/DateRange';
import GroupBy from '@/views/ActivationReports/components/GroupBy';
import { MyLocationGenerics } from '@/classes/utils'
import { getLastWeek } from '@/views/ReportGenerator/reportGenerator-types';
import { getInsertionOrdersByAccount } from '@/views/ReportGenerator/reportGenerator-api'
import { activationReportController } from '@/views/ActivationReports/controller/ActivationReportController';
import Tooltip from '@/components/TailwindTooltip'
import CreateModal from './components/CreateModal';
import { InformationModal } from './components/InformationModal';
import LoadingModal from './components/LoadingModal';
import { AllReportTemplates, allTemplateReports, CustomDimensionOption, templateCustomDimensionOptions } from '@/views/ActivationReports/data/ReportTemplates';
import { routes } from '@/routes';
import Granularity from "@/views/ActivationReports/components/Granularity";
import { ActivationReportReport } from './schemas/activation-report.schema';

export interface multiSelectValues {
    label: string | null
    value: number | null
}

interface IInsertionOrder {
    insertionOrderName: string
    insertionOrderId: number
}

export const ActivationReportsNewReport = () => {

    const [reportName, setReportName] = useState('New Activation Report');
    const [activeEditName, setActiveEditName] = useState(false);
    const [isInformatioModalVisible, setIsInformationModalVisible] = useState(false)
    const [errorMessages, setErrorMessages] = useState<string[]>([])
    const [insertionOrders, setInsertionOrders] = useState<multiSelectValues[]>([])
    const inputRef = useRef<HTMLInputElement>(null);
    const { accountId, reportId, copy } = useSearch<MyLocationGenerics>()
    const [reportsTemplate, setReportTemplate] = useState<ReportTemplate[]>([])
    const [activeTab, setActiveTab] = useState(reportsTemplate[0]?.name ?? '');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false)
    const [loadingTitleModal, setLoadingTitleModal] = useState('');
    const [titleInfomationModal, setTitleInfomationModal] = useState('');
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [reportToEdit, setReportToEdit] = useState<ReportTemplate | null>(null);
    const [newReportName, setNewReportName] = useState('');

    const [showScrollButton, setShowScrollButton] = useState(false);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const [formValues, setFormValues] = useState<ActivationReportConfiguration>({
        name: '',
        insertionOrders: [],
        reports: reportsTemplate.map((report) => {
            return {
                name: report.name,
                type: report.type,
                templateType: report.templateType,
                columns: []
            }
        }),
        grouping: GROUPING.INSERTION_ORDER,
        granularity: GRANULARITY.SUMMARY,
        startDate: getLastWeek()[0],
        endDate: getLastWeek()[1],
    })

    const navigate = useNavigate()

    const {
        data: accountInsertionOrders
    } = useQuery(['getInsertionOrdersByAccount', accountId], getInsertionOrdersByAccount)

    useEffect(() => {
        if (reportId != undefined) {
            setDataForEditCopy(reportId);
        } else {
            setIsModalOpen(true);
        }

        const resizeObserver = new ResizeObserver(() => {
            handleScroll();
        });

        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
            handleScroll();
            resizeObserver.observe(scrollContainer);
        }

        return () => {
            scrollContainer?.removeEventListener('scroll', handleScroll);
            resizeObserver.disconnect()
        };
    }, [])


    useEffect(() => {
        if (activeEditName && inputRef.current) {
            inputRef.current.focus(); // Focus the input when editing the report name
            inputRef.current.select(); // Select all text when focus
            inputRef.current.style.borderColor = '#E4E7E7';
        }
    }, [activeEditName]);

    useEffect(() => {
        const loadInsertionOrders = async () => {
            try {
                const reformattedInsertionOrders = accountInsertionOrders?.map((insertionOrder: IInsertionOrder) => ({
                    label: insertionOrder.insertionOrderName,
                    value: insertionOrder.insertionOrderId
                }))
                if (reformattedInsertionOrders) {
                    setInsertionOrders(reformattedInsertionOrders)
                }
            } catch (error) {
                console.error(`Error trying to load Insertion Orders by account for account: ${accountId}`, error)
            }
        }
        loadInsertionOrders()
    }, [accountInsertionOrders])

    useEffect(() => {
        const reports = [...formValues.reports]

        setFormValues((prevValues) => ({
            ...prevValues,
            ['reports']: reportsTemplate.map((report) => {
                return {
                    name: report.name,
                    type: report.type,
                    templateType: report.templateType,
                    columns: reports.find((r) => r.name === report.name)?.columns || report.columns
                }
            }),
        }))
        if (reportsTemplate.length > 0) {
            const activeTabExists = reportsTemplate.some((report) => report.name === activeTab);

            setActiveTab(activeTabExists ? activeTab : newReportName != '' ? newReportName : reportsTemplate[0].name);
        }
    }, [reportsTemplate])

    const mappedReportForEdit = (reports: ReportTemplate[]) => {
        const result = reports.map((report: ReportTemplate) => {
            const mappedColumns = report.columns.map((column ) => {
                const conversionMatch = templateConversionOptions.find((field: ConversionOption) => field.value === column);
                if (conversionMatch) {
                    return { name: conversionMatch.name, value: conversionMatch.value, category: 'Conversions' };
                }

                const customDimensionMatch = templateCustomDimensionOptions.find((field: CustomDimensionOption) => field.value === column);
                if (customDimensionMatch) {
                    return { name: customDimensionMatch.name, value: customDimensionMatch.value, category: 'Dimensions' };
                }
                const genericDimensionMatch = templateGenericDimensionOptions.find((field: GenericDimensionOption) => field.value === column);
                if (genericDimensionMatch) {
                    return { name: genericDimensionMatch.name, value: genericDimensionMatch.value, category: 'Dimensions' };
                }

                const metricMatch = templateMetricOptions.find((field: MetricOption) => field.value === column);
                if (metricMatch) {

                    return { name: metricMatch.name, value: metricMatch.value, category: 'Metrics' };
                }
            });

            return {
                ...report,
                columns: mappedColumns,
            };
        })

        return result;
    }
    const setDataForEditCopy = async (reportId: number) => {
        try {
            setLoadingTitleModal('Loading Information')
            setIsDownloading(true)

            const response = await activationReportController.getReportConfigurationsById(reportId);

            handleOnReportNameChange(response.configuration.name);
            handleOnChangeFields('insertionOrders', response.configuration.insertionOrders);

            const newStartDate = new Date(response.configuration.startDate);
            const newEndDate = new Date(response.configuration.endDate);
            handleDateRangeChange([newStartDate, newEndDate]);

            const reports = response.configuration.reports
            const matchingReports = allTemplateReports.filter(reportTemplate =>
                reports.some((report: ActivationReportReport) => report.templateType === reportTemplate.templateType)
            );
            const updatedMatchingReports = reports.map( report => {
                const matchingReport = matchingReports.find(( matchingReport: AllReportTemplates) => report.templateType=== matchingReport.templateType);               
                if (matchingReport) {
                    return { 
                        ...report, 
                        dimensions: matchingReport.dimensions,
                        metrics: matchingReport.metrics,
                        conversions: matchingReport.conversions,
                    };
                }
                return report;
            });
            const finalMappedReportForEdit = mappedReportForEdit(updatedMatchingReports as ReportTemplate[]);

            setReportTemplate(finalMappedReportForEdit)

            setFormValues((prevValues) => ({
                ...prevValues,
                reports: finalMappedReportForEdit
            }))

            handleOnChangeFields('granularity', response.configuration.granularity);
            handleOnChangeFields('grouping', response.configuration.grouping);

            setIsDownloading(false)
        } catch (error) {
            setIsDownloading(false)
            showErrorModal('Error', ['Error bring the information from the report'])
        }
    };

    const handleOnReportNameChange = (val: string) => {
        setReportName(val)
        handleOnChangeFields('name', val)
    }

    const handleOnChangeFields = (field: string, value: any) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [field]: value
        }))
    }

    const handleOnReportChange = (reportName: string, newColumns: any[]) => {

        setFormValues((prevValues) => ({
            ...prevValues,
            reports: prevValues.reports.map((report) =>
                report.name === reportName ? { ...report, columns: newColumns } : report
            ),
        }))

        setReportTemplate(reportsTemplate.map(( report ) => {
            if( report.name === reportName ){
                return {
                    name: report.name,
                    type: report.type,
                    templateType: report.templateType,
                    columns: newColumns,
                    description: report.description,
                    dimensions: report.dimensions,
                    metrics: report.metrics,
                    conversions: report.conversions
                }
            }
            return report
        }));

    }

    const handleDateRangeChange = (dates: ValueType) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            startDate: dates[0]!,
            endDate: dates[1]!
        }))
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            setActiveEditName(false); // Exit on "Enter" key
        }
    };

    const showErrorModal = (title: string, messages: string[]) => {
        setTitleInfomationModal(title);
        setErrorMessages(messages)
        setIsInformationModalVisible(true)
    }

    const handleCreateReport = async () => {

        const error = activationReportController.validateForm(formValues);
        if (error.length > 0) {
            showErrorModal('Error', error)
        } else {
            try {
                setLoadingTitleModal('Saving')
                setIsDownloading(true)

                await activationReportController.handleCreateReport(formValues, accountId!);
                handleBack();

            } catch (error) {
                showErrorModal('Error', ['Error while creating the report.'])
            } finally {
                setIsDownloading(false)
            }
        }
    }

    const handleEditReport = async () => {
        const error = activationReportController.validateForm(formValues);
        if (error.length > 0) {
            showErrorModal('Error', error)
        } else {
            try {
                setLoadingTitleModal('Saving')
                setIsDownloading(true)

                const response = await activationReportController.handleEditReport(formValues, accountId!, reportId!);

                if (response.status !== 200) {
                    showErrorModal('Error', ['Error while editing the report.'])
                }

                handleBack();
            } catch (error) {
                showErrorModal('Error', ['Error while editing the report.'])
            } finally {
                setIsDownloading(false)
            }
        }
    }

    const handleDownloadReport = async () => {
        const error = activationReportController.validateForm(formValues);
        if (error.length > 0) {
            showErrorModal('Error', error)
        } else {
            try {
                setLoadingTitleModal('Downloading')
                setIsDownloading(true)

                const response = await activationReportController.downloadReport(formValues, true);

                if (response?.status == 200) {
                    Notification.open({
                        title: 'Success',
                        description: <div>Report downloaded successfully</div>
                    });
                } else {
                    showErrorModal('Error', ['Error while downloading the report.'])
                }

            } catch (error) {
                showErrorModal('Error', ['Error while downloading the report.'])
            } finally {
                setIsDownloading(false)
            }
        }

    }

    const handleEditName = (report: ReportTemplate) => {
        setReportToEdit(report);
        setNewReportName(report.name);
        setEditModalVisible(true);
    };

    const saveEditedName = () => {
        const updatedReports = reportsTemplate.map((r) =>
            reportToEdit && r.name === reportToEdit.name ? { ...r, name: newReportName } : r
        );

        setReportTemplate(updatedReports);
        setEditModalVisible(false);
    };

    const handleDelete = (report: ReportTemplate) => {
        const filteredReports = reportsTemplate.filter((r) => r.name !== report.name);
        setReportTemplate(filteredReports);
        if (activeTab === report.name && filteredReports.length > 0) {
            setActiveTab(filteredReports[0].name);
        } else if (filteredReports.length === 0) {
            setActiveTab('');
        }
    };

    const handleAddReport = () => {
        setIsModalOpen(true)
    }

    const handleBack = () => {
        navigate({
            to: routes.app.activationReport.path,
            search: (prev) => {
                return { accountId: prev!.accountId }
            }
        })
    };

    const handleScroll = () => {

        const scrollContainer = scrollContainerRef.current;

        if (scrollContainer) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;
            setShowScrollButton(scrollLeft + clientWidth < scrollWidth - 5 || scrollLeft > 0);
        }
    };

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: -100, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: 100, behavior: 'smooth' });
        }
    }


    return (
        <div className='h-full relative flex flex-col' data-testid="activation-reports-form">
            <div className='flex flex-row w-full items-center justify-center mt-4' data-testid="activation-reports-form-header">
                <div className=' w-1/2 flex flex-row' data-testid="activation-reports-form-report-name">
                    {
                        activeEditName ? <Input
                            inputRef={inputRef}
                            className='ml-6 custom-input-editable-report-name'
                            id="report-name"
                            defaultValue={reportName}
                            onChange={handleOnReportNameChange}
                            placeholder="New Activation Report"
                            onBlur={() => setActiveEditName(false)} // Handle unfocus to exit editing mode
                            onKeyDown={handleKeyDown} // Handle "Enter" key to exit editing mode
                            style={{ borderColor: 'white' }}
                        />
                            : <div className='ml-6 mr-2 text-2xl font-medium'>
                                {reportName}
                            </div>
                    }
                    {
                        !activeEditName &&
                        <PencilSquareIcon className="w-6 inline-block mr-2 cursor-pointer" onClick={() => setActiveEditName(true)} />
                    }
                </div>

                <div className="border-l border-[#D9D9D9] h-10 mx-2" data-testid="activation-reports-form-border"></div>
                <div className='w-1/3 flex flex-row place-content-evenly' data-testid="activation-reports-form-action-buttons">
                    <div className='flex flex-col items-center cursor-pointer' onClick={handleDownloadReport} data-testid="activation-reports-form-download">
                        <ArrowDownTrayIcon
                            className="flex-shrink-0 w-6 h-6 text-indigo-600 mb-2"
                            aria-hidden="true"
                        />
                        <div>Download</div>
                    </div>

                    <Tooltip content={<div> Coming Soon!</div>}>
                        <div className='flex flex-col items-center cursor-pointer' onClick={() => { }} data-testid="activation-reports-form-schedule">
                            <ClockIcon
                                className="flex-shrink-0 w-6 h-6 text-indigo-600 mb-2"
                                aria-hidden="true"
                            />
                            <div>Schedule</div>
                        </div>
                    </Tooltip>

                </div>
                <div className="border-l border-[#D9D9D9] h-10 mx-2"></div>
                <div className=' w-1/3 flex flex-row place-content-evenly mr-16' data-testid="activation-reports-form-navigation-buttons">
                    <Button
                        id='cancel-report-button'
                        data-testid={'cancel-report-button'}
                        className='rounded-md w-[129px] h-[40px]'
                        appearance="primary"
                        style={{ backgroundColor: '#FFFFFF', color: '#7B61FF' }}
                        onClick={handleBack}
                    >
                        Back
                    </Button>
                    <Button
                        id='create-report-button'
                        data-testid={'create-report-button'}
                        className='rounded-md w-[129px] h-[40px]'
                        appearance="primary"
                        style={{ backgroundColor: '#7B61FF' }}
                        onClick={() => { copy != undefined ? (copy ? handleCreateReport() : handleEditReport()) : handleCreateReport() }}
                        disabled={!formValues.reports.length}
                    >
                        Save
                    </Button>

                </div>
            </div>
            <div className='ml-6 flex flex-row mt-3 custom-tab-active-color' data-testid="activation-reports-form-tabs">
                <div className='max-w-[56vw] relative h-[56px] overflow-x-auto flex custom-tabs-activation-report scrollable-container' id='scrollContainer' ref={scrollContainerRef} data-testid="activation-reports-form-tabs-name">
                    <Tabs
                        width={'100%'}
                        marginRight={40}
                        active={activeTab}
                        onChange={(active: string) => {
                            setActiveTab(active)
                        }}

                    >
                        {reportsTemplate.map((tab) => {
                            return <div className="flex flex-row items-center" key={tab.name} data-testid={tab}>
                                <div className='flex flex-row text-sm whitespace-nowrap ml-2'>
                                    {tab.name}
                                    <div
                                    data-testid='div-report-generator-actions-dropdown'
                                    className="more"
                                >
                                    <Whisper
                                        placement="bottomStart"
                                        trigger="click"
                                        data-testid="activation-reports-form-whisper"
                                        speaker={
                                            <Popover full>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        data-testid='div-report-generator-edit-button'
                                                        onClick={() => handleEditName(tab)}>
                                                        Edit
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        data-testid='div-report-generator-delete-button'
                                                        onClick={() => handleDelete(tab)}>
                                                        Delete
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Popover>
                                        }
                                    >
                                        <ChevronDownIcon
                                          className="w-4 mx-4"
                                          aria-hidden="true"
                                        />
                                    </Whisper>
                                </div>

                                </div>
                            </div>
                        })}
                    </Tabs>

                </div>
                <div className='flex flex-row items-center cursor-pointer ml-2' data-testid="activation-reports-form-tabs-scroll">
                    {showScrollButton && <div>
                        <button onClick={scrollLeft} data-testid="activation-reports-form-scroll-left">
                            <ChevronLeftIcon
                                className="w-5 h-5 ml-2"
                                aria-hidden="true"
                                color={'#7B61FF'}
                            />
                        </button>

                        <button onClick={scrollRight} data-testid="activation-reports-form-scroll-right">
                            <ChevronRightIcon
                                className="w-5 h-5 ml-2"
                                aria-hidden="true"
                                color={'#7B61FF'}
                            />
                        </button>
                    </div>}
                    <div className='font-semibold text-base ml-4' onClick={handleAddReport} data-testid="activation-reports-form-addReport">
                        + Add a Report
                    </div>
                </div>
            </div>
            <div className='min-h-[104px] w-full bg-[#EFE3FF80] flex flex-row justify-start' data-testid="activation-reports-form-filters">
                <div className='w-1/4 mx-8' data-testid="activation-reports-form-filters-insertion">
                    <InsertionOrder formValues={formValues} handleOnChange={handleOnChangeFields} insertionOrders={insertionOrders}/>
                </div>
                <div className='w-1/4 mx-8' data-testid="activation-reports-form-filters-date">
                    <DateRange formValues={formValues} handleOnChange={handleDateRangeChange}/>
                </div>
                <div className='w-1/4 mx-8' data-testid="activation-reports-form-filters-granularity">
                    <Granularity formValues={formValues} handleOnChange={handleOnChangeFields}/>
                </div>
                <div className='w-1/4 mx-8' data-testid="activation-reports-form-filters-groupby">
                    <GroupBy formValues={formValues} handleOnChange={handleOnChangeFields}/>
                </div>
            </div>
            {
                reportsTemplate.map((report) => {
                    if (report.name == activeTab) {
                        return <div className={`data-active-activation-report-${report} mt-1 flex w-full flex-1 min-h-0`} key={report.name} data-testid="activation-reports-form-component">
                            {
                                <ActivationReportForm reportTemplate={report} handleOnChangeField={handleOnReportChange} formData={formValues} />
                            }
                        </div>
                    }
                })
            }

            <CreateModal open={isModalOpen} handleClose={setIsModalOpen} reportsTemplate={setReportTemplate} initialReport={reportsTemplate}></CreateModal>
            <InformationModal title={titleInfomationModal} errorMessages={errorMessages} isErrorModalVisible={isInformatioModalVisible} setIsErrorModalVisible={setIsInformationModalVisible} />
            <LoadingModal isDownloading={isDownloading} title={loadingTitleModal} />
            <Modal show={editModalVisible} onHide={() => setEditModalVisible(false)}>
                <Modal.Header>
                    <Modal.Title>Edit Report Name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input value={newReportName} onChange={setNewReportName} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={saveEditedName} appearance="primary">Save</Button>
                    <Button onClick={() => setEditModalVisible(false)} appearance="subtle">Cancel</Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}
