import React, { useState } from 'react'
import InsertionOrder from '@/views/ActivationReports/components/InsertionOrder'
import DateRange from '@/views/ActivationReports/components/DateRange'
import AdFormatSection from '@/views/ActivationReports/components/AdFormatSection'
import { ReportTable } from '@/views/ActivationReports/components/ReportTable'
import { ReportFilters } from '@/views/ActivationReports/components/ReportFilters'


export const ActivationReportForm = () => {

    const tableData2 = [
        { id: 1, name: 'John Doe 0' },
        { id: 2, name: 'Jane Smith 1' },
        { id: 1, name: 'John Doe' },
        { id: 2, name: 'Jane Smith' },
        { id: 1, name: 'John Doe' },
        { id: 2, name: 'Jane Smith' },
        { id: 1, name: 'John Doe' },
        { id: 2, name: 'Jane Smith' },
        { id: 1, name: 'John Doe' },
        { id: 2, name: 'Jane Smith' },
        { id: 1, name: 'John Doe' },
        { id: 2, name: 'Jane Smith' },
        { id: 1, name: 'John Doe' },
        { id: 2, name: 'Jane Smith' },
        { id: 1, name: 'John Doe' },
        { id: 2, name: 'Jane Smith' },
        { id: 1, name: 'John Doe' },
        { id: 2, name: 'Jane Smith' },
        { id: 1, name: 'John Doe' },
        { id: 2, name: 'Jane Smith' },
        { id: 1, name: 'John Doe' },
        { id: 2, name: 'Jane Smith' }
    ];
    const [tableData, setTableData] = useState([])

    return (
        <div>
            <div className='h-[104px] w-full bg-[#EFE3FF80] flex flex-row'>
                <div className='w-2/3 flex flex-row'>
                    <div className='w-[330px] ml-11'>
                        <InsertionOrder insertionOrders={[{ label: 'p1', value: 'p1' }]} handleOnChange={() => { }} />
                    </div>
                    <div className='w-[330px] ml-11'>
                        <DateRange handleDateRangeChange={() => { }} />
                    </div>

                </div>
                <div className='w-1/3 flex justify-center'>
                    <div className='w-[217px]'>
                        <AdFormatSection handleOnChange={() => { }} />
                    </div>

                </div>
            </div>
            <div className='w-full flex flex-row'>
                <div className='w-2/3'>
                    <ReportTable tableData={tableData} handleTableData={setTableData} />
                </div>
                <div className='w-1/3 border-l-4 border-l-[#D4D9D9]+ p-4'>
                    <ReportFilters tableData={tableData} handleTableData={setTableData} />
                </div>
            </div>
        </div>
    )
}
