import React from 'react';
import { IResult } from '@/views/Trends/types';
import ResultCard from '@/views/Trends/Results/components/ResultCard';

interface IProps {
  results: IResult[];
}

const ResultCardList = ({ results }: IProps) => {
  return (
    <div className="grid grid-flow-row auto-rows-[minmax(200px,_2fr)] gap-4">
      {results.map((result, index) => {
        return (
          <ResultCard key={index} result={result} />
        );
      })}
    </div>
  );
};

export default ResultCardList;
