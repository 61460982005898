import React, { useEffect, useState } from 'react'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { PlusCircleIcon } from '@heroicons/react/24/outline'

interface IProps {
    filters: any[]
    filterName: string
    tableData: any[]
    handleDataTable: Function
}



export const ReportFiltersItems = ({ filters, filterName, tableData, handleDataTable }: IProps) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [filtersToDisplay, setFiltersToDisplay] = useState<string[]>([])

    useEffect(() => {
        handleFiltersToDisplay();
    }, [])

    useEffect(() => {
        handleFiltersToDisplay();
    }, [tableData])
    
    const handleFiltersToDisplay = () => {
        const filtersNotInTable = filters.filter(
            filter => !tableData.some(item => item.name === filter)
          );
          setFiltersToDisplay([...filtersNotInTable])
    }    

    const handleAddDatatoTable = (value: string) => {
        handleDataTable([...tableData, { 'name': value}]);
    };

    return (
        <div className='ml-2'>
            <div className='flex flex-row align-middle cursor-pointer' onClick={() => { setIsCollapsed(!isCollapsed) }}>

                {isCollapsed ? <ChevronRightIcon
                    className="h-6 w-6"
                    aria-hidden="true"
                /> : <ChevronDownIcon
                    className="h-6 w-6"
                    aria-hidden="true"
                />}

                <div className='text-lg	font-semibold ml-1'>
                    {filterName}

                </div>
            </div>
            {
                !isCollapsed &&
                <div className='mt-3'>
                    {
                        filtersToDisplay.map((value) => {
                            return <div className='flex flex-row text-base mb-3 cursor-pointer' onClick={() => handleAddDatatoTable(value)}>
                                <PlusCircleIcon
                                    className="h-6 w-6 mr-1"
                                    aria-hidden="true"
                                />
                                {value}
                            </div>
                        })
                    }
                </div>
            }
        </div>
    )
}
