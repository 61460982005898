import React, { useEffect, useMemo } from 'react';
import Container from 'rsuite/lib/Container';
import useWindowDimensions from '@/useWindowDimensions';
import { HorizontalNavBar } from '@/components/Navbars/Horizontal/Navbar';
import NavigationBar from '@/components/Navbars/NavigationBar';
import styled from 'styled-components';
import ZendeskWidget, { openZendeskChat } from '@/components/HelpWidgets/ZendeskWidget';
import { openJiraHelpdeskWidget } from '@/useInitializeThirdParty.js';
import { perms } from '@/staticData/permissions';
import { products } from '@/staticData/products';
import { isIframe } from '@/validations';

import { NavigationItemDataProps } from '@/components/Navbars/NavigationItem';
import {
  PATH_MOMENTS_V2,
  PATH_TO_ONBOARDING_HUB,
  PATH_BRAND_PROFILES,
  PATH_ENGAGE_LISTS,
  PATH_ACTIVATIONS,
  PATH_ACTIVATIONS_CAMPAIGNS,
  PATH_MOMENT_INTELLIGENCE,
  PATH_REPORTING,
  DISCOVER_REPORTING,
  PATH_ADMIN_ROLES,
  PATH_SETTINGS_PROFILE,
  LOGIN_PATH,
  PATH_MOMENTS_V2_SETTINGS_REDESIGN,
  PATH_INTEGRATIONS,
  PATH_TIKTOK_HASHTAGS,
  PATH_ACTIVATIONS_TIKTOK
} from '@/routes';
import {
  ChartBarIcon,
  FingerPrintIcon,
  GlobeAltIcon,
  ViewColumnsIcon,
  WrenchScrewdriverIcon,
  CheckBadgeIcon,
  ComputerDesktopIcon,
  DocumentIcon,
  ArrowRightOnRectangleIcon,
  Cog8ToothIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/24/outline';
import { ReactComponent as BrandMentalityRSlateLogo } from '@/assets/img/BrandMentalityRSlateLogo.svg';
import { ReactComponent as SightlyLogo } from '@/assets/img/sightly-logo.svg';
import smartlistIcon from '@/assets/img/navigationBar/smartlist_icon.svg'
import { PuzzlePieceIcon } from '@heroicons/react/20/solid';
import { useFlag } from '@unleash/proxy-client-react';

const StyledContainerDiv = styled.div<{ height: number }>`
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: auto;
    height: ${(props) => props.height - 57}px;
`

const StyledContainer = styled(Container)`
    display: flex;
    flex-direction: column;
`

let navigationItems: NavigationItemDataProps[] = [
  {
    displayText: 'Onboarding Hub',
    dataTestId: 'onboarding-hub-nav-item',
    icon: <CheckBadgeIcon />,
    activeTargets: [PATH_TO_ONBOARDING_HUB],
    navigationTarget: PATH_TO_ONBOARDING_HUB,
    requiredPermissions: [perms.ONBOARDING_HUB_READ],
    requiredProducts: [products.ONBOARDING_HUB],
    hideIfUnauthorized: true,
    displayIfInactiveAccount: true
  },
  {
    displayText: 'Brand Profile',
    dataTestId: 'brand-profile-nav-item',
    icon: <FingerPrintIcon />,
    navigationTarget: PATH_BRAND_PROFILES,
    activeTargets: ['/app/brandProfiles'],
    searchCallback: (search) => ({
      brandProfileId: search!.brandProfileId,
      accountId: search!.accountId
    }),
    requiredPermissions: [perms.ACCOUNT_SETTINGS_READ],
    hideIfUnauthorized: true
  },
  {
    displayText: 'Boards',
    dataTestId: 'boards-nav-item',
    icon: <ViewColumnsIcon />,
    navigationTarget: undefined,
    requiredPermissions: [perms.AYLIEN_TRENDS_READ, perms.SMARTLIST_READ, perms.REPORTING_READ, perms.MOMENTS_INTELLIGENCE_READ],
    permissionCheckOperator: 'OR',
    requiredProducts: [products.BOARDS],
    hideIfUnauthorized: true,
    searchCallback: (prev) => {
      return {
        boardsView: 'My Boards',
        brandProfileId: prev?.brandProfileId,
        accountId: prev?.accountId
      }
    },
    navigationItems: [
      {
        displayText: 'My Boards',
        dataTestId: 'my-boards-nav-item',
        itemKey: 'My Boards key',
        navigationTarget: PATH_MOMENTS_V2,
        searchCallback: (prev) => {
          return {
            boardsView: 'My Boards',
            brandProfileId: prev?.brandProfileId,
            accountId: prev?.accountId
          }
        },
        hideIfUnauthorized: true,
        activeTargets: [PATH_MOMENTS_V2],
        activeParams: { boardsView: 'My Boards' }
      },
      {
        displayText: 'Manage Boards',
        dataTestId: 'manage-boards-nav-item',
        itemKey: 'Manage Boards key',
        navigationTarget: PATH_MOMENTS_V2_SETTINGS_REDESIGN,
        searchCallback: (old) => {
          return {
            boardsView: 'Manage Boards',
            brandProfileId: old?.brandProfileId,
            accountId: old?.accountId
          }
        },
        hideIfUnauthorized: true,
        activeTargets: [PATH_MOMENTS_V2_SETTINGS_REDESIGN],
        activeParams: { boardsView: 'Manage Boards' }
      }
    ]
  },
  {
    displayText: 'Activations',
    dataTestId: 'activations-nav-item',
    icon: <ComputerDesktopIcon />,
    navigationTarget: undefined,
    activeTargets: [PATH_ACTIVATIONS, PATH_ACTIVATIONS_CAMPAIGNS],
    requiredPermissions: [perms.ACTIVATIONS_READ, perms.TIKTOK_ACTIVATIONS_READ],
    requiredProducts: [products.GOOGLE_ADS_ACTIVATIONS, products.TIKTOK_ACTIVATIONS],
    permissionCheckOperator: 'OR',
    productCheckOperator: 'OR',
    hideIfUnauthorized: true,
    navigationItems: [] // Nav items added in conditional below
  },
  {
    displayText: 'Intelligence',
    dataTestId: 'moment-intelligence-nav-item',
    icon: <GlobeAltIcon />,
    navigationTarget: undefined,
    requiredPermissions: [perms.MOMENTS_INTELLIGENCE_READ, perms.TIKTOK_HASHTAGS_DASHBOARD_READ],
    requiredProducts: [products.MOMENTS_INTELLIGENCE, products.TIKTOK_HASHTAGS_DASHBOARD],
    permissionCheckOperator: 'OR',
    productCheckOperator: 'OR',
    hideIfUnauthorized: true,
    navigationItems: [
      {
        displayText: 'Moments',
        dataTestId: 'moments-nav-item',
        itemKey: 'Moment Intelligence key',
        navigationTarget: PATH_MOMENT_INTELLIGENCE,
        hideIfUnauthorized: true,
        activeTargets: [PATH_MOMENT_INTELLIGENCE],
        requiredPermissions: [perms.MOMENTS_INTELLIGENCE_READ],
        requiredProducts: [products.MOMENTS_INTELLIGENCE],
      },
      {
        displayText: 'TikTok Hashtags',
        dataTestId: 'tiktok-hashtags-nav-item',
        itemKey: 'TikTok Hashtags key',
        navigationTarget: PATH_TIKTOK_HASHTAGS,
        hideIfUnauthorized: true,
        requiredPermissions: [perms.TIKTOK_HASHTAGS_DASHBOARD_READ],
        requiredProducts: [products.TIKTOK_HASHTAGS_DASHBOARD],
        activeTargets: [PATH_TIKTOK_HASHTAGS]
      }]
  },
  {
    displayText: 'Reporting',
    dataTestId: 'reporting-nav-item',
    icon: <ChartBarIcon />,
    activeTargets: [DISCOVER_REPORTING, PATH_REPORTING],
    authorizedIfAnyChild: true,
    hideIfUnauthorized: true,
    requiredPermissions: [perms.REPORTING_READ],
    requiredProducts: [products.REPORTING],
    navigationItems: [
      {
        displayText: 'Usage Report',
        dataTestId: 'usage-report-reports-nav-item',
        itemKey: 'Usage Report key',
        navigationTarget: DISCOVER_REPORTING,
        searchCallback: (prev) => {
          return { accountId: prev!.accountId, reportingView: 'Brand Mentality' }
        },
        hideIfUnauthorized: true,
        requiredPermissions: [perms.REPORTING_BRAND_MENTALITY_USAGE_READ, perms.REPORTING_READ],
        activeTargets: [DISCOVER_REPORTING],
        activeParams: { reportingView: 'Brand Mentality' }
      },
      {
        displayText: 'Profile Insights',
        dataTestId: 'reporting-profile-insights-nav-item',
        itemKey: 'Profile Insights key',
        navigationTarget: DISCOVER_REPORTING,
        searchCallback: (prev) => {
          return { accountId: prev!.accountId, reportingView: 'Brand Profile Insights' }
        },
        hideIfUnauthorized: true,
        requiredPermissions: [perms.REPORTING_BRAND_MENTALITY_INSIGHTS_READ, perms.REPORTING_READ],
        activeTargets: [DISCOVER_REPORTING],
        activeParams: { reportingView: 'Brand Profile Insights' }
      },
      {
        displayText: 'Activation Reports',
        dataTestId: 'activation-reports-insights-nav-item',
        itemKey: 'Activation Reports key',
        navigationTarget: PATH_REPORTING,
        searchCallback: (prev) => {
          return { accountId: prev!.accountId }
        },
        hideIfUnauthorized: true,
        requiredPermissions: [perms.REPORTING_GENERATOR_CREATE],
        activeTargets: [PATH_REPORTING]
      }
    ]
  },
  {
    displayText: 'Smartlists',
    dataTestId: 'smartlists-nav-item',
    icon: <img src={smartlistIcon} className='w-[30px] h-[30px]' />,
    navigationTarget: PATH_ENGAGE_LISTS,
    requiredPermissions: [perms.SMARTLIST_READ],
    requiredProducts: [products.SMARTLISTS],
    hideIfUnauthorized: true
  },
  {
    displayText: 'Support',
    itemKey: 'Support Item',
    dataTestId: 'support-nav-item',
    icon: <QuestionMarkCircleIcon />,
    navigationItems: [
      {
        displayText: 'Contact Support',
        dataTestId: 'zendesk-opener-item',
        itemKey: 'contact support key',
        requiredPermissions: [perms.EXTERNAL_SUPPORT_CHAT_PERMISSION],
        onClick: openZendeskChat,
      },
      {
        displayText: 'JIRA Help Center',
        dataTestId: 'jira-help-center-item',
        itemKey: 'internal support key',
        onClick: openJiraHelpdeskWidget,
        requiredPermissions: [perms.INTERNAL_SUPPORT_CHAT_PERMISSION],
        hideIfUnauthorized: true,
      }
    ]
  },
  {
    displayText: 'Admin',
    dataTestId: 'admin-nav-item',
    icon: <WrenchScrewdriverIcon />,
    activeTargets: ['/app/admin'],
    navigationTarget: PATH_ADMIN_ROLES,
    requiredPermissions: [perms.ADMIN_READ],
    hideIfUnauthorized: true
  }
];

const handleLogout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userId');
  window.location.href = LOGIN_PATH;
}

const subNavigationItems: NavigationItemDataProps[] = [
  {
    displayText: 'Settings',
    dataTestId: 'settings-nav-item',
    navigationTarget: PATH_SETTINGS_PROFILE,
    activeTargets: [PATH_SETTINGS_PROFILE],
    icon: <Cog8ToothIcon />,
    displayIfInactiveAccount: true
  },
  {
    displayText: 'Logout',
    dataTestId: 'logout-nav-item',
    icon: <ArrowRightOnRectangleIcon />,
    onClick: handleLogout,
    displayIfInactiveAccount: true
  }
];

interface IProps {
  children: React.ReactElement
}

export const Layout = ({ children }: IProps) => {
  const dims = useWindowDimensions();
  const windowDims = useMemo(() => dims, [dims]);

  const tikTokFeatureFlag = useFlag('tick_tock_app');
  const checkTikTok = isIframe();

  useEffect(() => {
    if (tikTokFeatureFlag) {

      const integrationsItem = {
        displayText: 'Integrations',
        dataTestId: 'integrations-nav-item',
        icon: <PuzzlePieceIcon fill='none' stroke='currentColor' />,
        navigationTarget: PATH_INTEGRATIONS,
        requiredPermissions: [perms.TIKTOK_INTEGRATIONS_READ],
        requiredProducts: [products.TIKTOK_INTEGRATIONS],
        hideIfUnauthorized: true
      }
      const index = navigationItems.findIndex(item => item.displayText === 'Reporting');
      navigationItems.splice(index + 1, 0, integrationsItem);


      if (checkTikTok) {
        // Modify tabs for TikTOk IFrame
        const activationsIndex = navigationItems.findIndex (
          item => item.displayText === 'Activations'
        )

        navigationItems[activationsIndex] = {
          ...navigationItems[activationsIndex],
          navigationTarget: undefined,
          requiredProducts: [products.TIKTOK_ACTIVATIONS],
          navigationItems: [
            {
              displayText: 'Campaigns',
              dataTestId: 'campaigns-nav-item',
              itemKey: 'Campaigns key',
              navigationTarget: PATH_ACTIVATIONS_CAMPAIGNS,
              hideIfUnauthorized: true,
              activeTargets: [PATH_ACTIVATIONS_CAMPAIGNS],
              requiredPermissions: [perms.TIKTOK_CAMPAIGNS_READ, perms.TIKTOK_CAMPAIGNS_UPDATE],
              requiredProducts: [products.TIKTOK_ACTIVATIONS]
            },
            {
              displayText: 'TikTok',
              dataTestId: 'activations-tiktok-nav-item',
              itemKey: 'Activations TikTok key',
              navigationTarget: PATH_ACTIVATIONS_TIKTOK,
              hideIfUnauthorized: true,
              requiredPermissions: [perms.TIKTOK_ACTIVATIONS_READ],
              requiredProducts: [products.TIKTOK_ACTIVATIONS],
              activeTargets: [PATH_ACTIVATIONS_TIKTOK]
            }
          ]
        }
      } else { // Normal website
        const activationsIndex = navigationItems.findIndex (
          item => item.displayText === 'Activations'
        )
        navigationItems[activationsIndex] = {
          ...navigationItems[activationsIndex],
          activeTargets: [PATH_ACTIVATIONS, PATH_ACTIVATIONS_CAMPAIGNS, PATH_ACTIVATIONS_TIKTOK],
          navigationItems: [
            {
              displayText: 'Google Ads',
              dataTestId: 'google-ads-nav-item',
              itemKey: 'Google Ads key',
              navigationTarget: PATH_ACTIVATIONS,
              hideIfUnauthorized: true,
              activeTargets: [PATH_ACTIVATIONS],
              requiredPermissions: [perms.ACTIVATIONS_READ],
              requiredProducts: [products.GOOGLE_ADS_ACTIVATIONS],
              searchCallback: (search) => ({
                brandProfileId: search!.brandProfileId,
                accountId: search!.accountId
              })
            },
            {
              displayText: 'Campaigns',
              dataTestId: 'campaigns-nav-item',
              itemKey: 'Campaigns key',
              navigationTarget: PATH_ACTIVATIONS_CAMPAIGNS,
              hideIfUnauthorized: true,
              requiredPermissions: [perms.TIKTOK_CAMPAIGNS_READ],
              requiredProducts: [products.TIKTOK_ACTIVATIONS],
              activeTargets: [PATH_ACTIVATIONS_CAMPAIGNS]
            },
            {
              displayText: 'TikTok',
              dataTestId: 'activations-tiktok-nav-item',
              itemKey: 'Activations TikTok key',
              navigationTarget: PATH_ACTIVATIONS_TIKTOK,
              hideIfUnauthorized: true,
              requiredPermissions: [perms.TIKTOK_ACTIVATIONS_READ],
              requiredProducts: [products.TIKTOK_ACTIVATIONS],
              activeTargets: [PATH_ACTIVATIONS_TIKTOK]
            }
          ]
        }
      }
    }
  }, [])

  return (
    <div className="flex flex-row flex-auto">
      <NavigationBar
        orientation={'vertical'}
        navigationItems={navigationItems}
        headerImage={<BrandMentalityRSlateLogo className="w-[135px] m-auto" />}
        minimizedHeaderImage={<SightlyLogo className="w-full" />}
        secondaryNavigationItems={subNavigationItems}
      />
      <StyledContainer>
        <HorizontalNavBar />
        <ZendeskWidget />
        <StyledContainerDiv height={windowDims.height}>
          {children}
        </StyledContainerDiv>
      </StyledContainer>
    </div>
  )
}
