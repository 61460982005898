import React from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { socialsObjValidation } from '../../../../../schemas/brandProfiles'
import Table from 'rsuite/lib/Table'
import SightlyButton from '../../../../../components/Sightly/SightlyButton'
import IconButton from 'rsuite/lib/IconButton'
import Icon from 'rsuite/lib/Icon'
import { accentColor } from '../../../../../assets/jss/colorConstants'
import { CustomWhisper } from '../../../../../components/CustomWhisper'
import Dropdown from 'rsuite/lib/Dropdown'
import toast from 'react-hot-toast'
import SocialModal from './SocialModal'
import Panel from 'rsuite/lib/Panel'
import { userAccountAxios } from '../../../../../axiosInstances'
import { useMatch, useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '../../../../../classes/utils'
import useUser from '../../../../../hooks/useUser'
import { TableColumnJs } from '../../../../../utils'
import { rqKeys } from '../../../../../ReactQueryKeyFactory'
import { products, userActions } from '@/staticData/products'
import Tooltip from 'rsuite/lib/Tooltip'
import Whisper from 'rsuite/lib/Whisper'
import config from '@/config'
import AccordionSection from './components/AccordionSection'
import { BRAND_PROFILE_RELATIONSHIP_LOOKUP, SOCIAL_SECTION } from './constants'
import useBrandProfileBasic from '@/hooks/brandProfile/useBrandProfileBasic'
import useSocialCauses from './useSocialCauses'
import SocialAIGenerationForm, { SocialAIGenerationFormType, SocialAIGenerationType } from './SocialAIGenerationForm'
import { getRelationships } from './queries-api'
import { AIGeneratedCell, tableHeaderStyle } from './Brand/BrandSection'

export type Social = {
  id: number
  name?: string
  description: string
  wikiUrl: string
  relationshipId: number
  relationshipName?: string
  causeIsNotListed: boolean
  aylienId: number | null
}

const getSocials = async (brandProfileId: number): Promise<Social[]> => {
  let url = `/brand-profile/${brandProfileId}/social-and-environmental`

  const result = await userAccountAxios.get(url)

  if (result.status === 200) {
    if (result.data.length > 0) {
      socialsObjValidation.validate(result.data).catch(function (err) {
        console.error(err)
      })
    }
  }

  const tranformedData = result.data.map((i: any) => {
    if (i.aylienNewsId) {
      return {
        ...i,
        aylienId: i.aylienNewsId
      }
    } else if (i.aylienIndustriesId) {
      return {
        ...i,
        aylienId: i.aylienIndustriesId
      }
    } else {
      return i
    }
  })

  return tranformedData
}

const SocialSection = (props: { isActive?: boolean; handleAccordionClick?: () => void }) => {
  const {
    params: { brandProfileId }
  } = useMatch<MyLocationGenerics>()
  const { accounts, user, currentAccount, perms, userCanService } = useUser()

  const queryClient = useQueryClient()

  const { viewOnly: viewOnlyParam } = useSearch<MyLocationGenerics>()
  const viewOnly = viewOnlyParam || false

  const {
    data: items,
    isLoading,
    isFetched
  } = useQuery(rqKeys.brandProfileSocial(Number(brandProfileId)), () => getSocials(Number(brandProfileId)), {
    enabled: !!brandProfileId
  })
  const { data: relationships, isLoading: isRelationShipLoading } = useQuery(
    ['brandProfileRelationshipsLookUp'],
    () => getRelationships()
  )

  const [editing, setEditing] = React.useState(false)
  const [editingId, setEditingId] = React.useState<number | null>(null)
  const [adding, setAdding] = React.useState(false)
  const [isAIGenerate, setIsAIGenerate] = React.useState(false)
  const { queryResult: BrandProfileInfo } = useBrandProfileBasic(Number(brandProfileId))
  const { brandQueryMutation, isMutatingBrandQuery, handleError } = useSocialCauses(Number(brandProfileId))

  const editingSocial = React.useMemo(() => {
    if (!editingId || !isFetched || !items || !editing) return null

    const item = items.filter((c: Social) => c.id === editingId)[0]

    if (!item) return null
    return item
  }, [editingId, editing, items])

  const existingQueryTypes = React.useMemo(() => {
    return (items && items.map((item: any) => item.name)) || []
  }, [items])

  const getRelationshipId = (relationshipName: string) => {
    if (!relationships) return null
    const selectedRelationship = relationships.find(
      (relationship: { id: number; relationship: string }) => relationship.relationship === relationshipName
      )
    return selectedRelationship.id || null
  }

  const handleAIGenerationFormSubmit = async (data: SocialAIGenerationFormType) => {
    try {
      const brandProfile = BrandProfileInfo.data
      if (!brandProfile) return
      let doNotSuggest = existingQueryTypes
      const payload: SocialAIGenerationType = {
        brandName: brandProfile.brandProfileName,
        suggestionCount: 0,
        companySummary: brandProfile.companySummary,
        companyMentality: brandProfile.companyMentality,
        existingQueryTypes: [],
        relationshipId: 0
      }
      setIsAIGenerate(false)
      if (data.socialBlockCount) {
        payload.suggestionCount = data.socialBlockCount
        payload.relationshipId = getRelationshipId(BRAND_PROFILE_RELATIONSHIP_LOOKUP.BLOCK)
        payload.existingQueryTypes = doNotSuggest
        const resp = await brandQueryMutation.mutateAsync({ brandProfileId: Number(brandProfileId), payload })
        doNotSuggest = resp.data.doNotSuggest
      }
      if (data.socialMonitorCount) {
        payload.suggestionCount = data.socialMonitorCount
        payload.relationshipId = getRelationshipId(BRAND_PROFILE_RELATIONSHIP_LOOKUP.MONITOR)
        payload.existingQueryTypes = doNotSuggest
        const resp = await brandQueryMutation.mutateAsync({ brandProfileId: Number(brandProfileId), payload })
        doNotSuggest = resp.data.doNotSuggest
      }
      if (data.socialTargetCount) {
        payload.suggestionCount = data.socialTargetCount
        payload.relationshipId = getRelationshipId(BRAND_PROFILE_RELATIONSHIP_LOOKUP.TARGET)
        payload.existingQueryTypes = doNotSuggest
        const resp = await brandQueryMutation.mutateAsync({ brandProfileId: Number(brandProfileId), payload })
        doNotSuggest = resp.data.doNotSuggest
      }
    } catch (error) {
      handleError(error)
    }
  }

  const handleDelete = async (id: number) => {
    const url = `/brand-profile/${brandProfileId}/social-and-environmental/${id}`
    try {
      await userAccountAxios.delete(url)
      // to fetch the latest product quota
      await queryClient.fetchQuery(rqKeys.subscriptionQuotaQueryKey(currentAccount?.accountId, products.QUERIES))
      toast.success('Deleted')
      queryClient.invalidateQueries(rqKeys.brandProfileSocial(Number(brandProfileId)))
      queryClient.invalidateQueries({ queryKey: rqKeys.brandProfileBasic(Number(brandProfileId)) })
    } catch (err) {
      toast.error('Error')
    }
  }

  return (
    <>
      {config.featureFlag.aiProfiles ? (
        <div data-testid="accordion-social-causes-section-panel">
          <AccordionSection
            sectionCaption={SOCIAL_SECTION.headerText}
            isActive={props.isActive ?? false}
            description={SOCIAL_SECTION.descriptionText}
            canCreate={!viewOnly}
            handleAddClick={() => setAdding(true)}
            handleAIGenerate={() => {
              setIsAIGenerate(true)
            }}
            handleAccordionClick={props.handleAccordionClick}
            isAIGenerating={!!isMutatingBrandQuery}
          >
            <SocialAIGenerationForm
              isAIGenerate={isAIGenerate}
              setIsAIGenerate={setIsAIGenerate}
              handleSubmitForm={handleAIGenerationFormSubmit}
            />
            {!!editingSocial && (
              <SocialModal
                key="edit"
                type="edit"
                open={editing}
                handleClose={() => {
                  setEditing(false)
                  setEditingId(null)
                }}
                item={editingSocial}
              />
            )}

            <SocialModal
              key="add"
              type="add"
              open={adding && userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE)}
              handleClose={() => setAdding(false)}
              item={{
                id: 456456546456456564,
                name: '',
                description: '',
                aylienId: null,
                wikiUrl: '',
                relationshipId: 2,
                causeIsNotListed: false
              }}
            />

            {isFetched && items && items.length > 0 && (
              <Table
                loading={isLoading}
                autoHeight
                data={items}
                bordered
                style={{
                  borderRadius: '10px',
                  boxShadow: '0px 1px 2px 0px #0000000F'
                }}
              >
                <TableColumnJs width={30}>
                  <Table.HeaderCell style={tableHeaderStyle} />
                  <AIGeneratedCell dataKey="isAIGenerated" />
                </TableColumnJs>
                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell style={tableHeaderStyle}>TYPE</Table.HeaderCell>
                  <Table.Cell
                    style={{ display: 'flex', alignItems: 'center' }}
                    dataKey="relationshipName"
                  />
                </TableColumnJs>

                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell style={tableHeaderStyle}>NAME</Table.HeaderCell>
                  <Table.Cell>
                    {(item: Social) => {
                      return <>{item.name}</>
                    }}
                  </Table.Cell>
                </TableColumnJs>

                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell style={tableHeaderStyle}>WIKI</Table.HeaderCell>
                  <Table.Cell dataKey="wikiUrl" />
                </TableColumnJs>

                {!viewOnly && (
                  <TableColumnJs flexGrow={1}>
                    <Table.HeaderCell style={tableHeaderStyle}></Table.HeaderCell>
                    <Table.Cell style={{ display: 'flex', alignItems: 'center' }}>
                      {(item: Social) => {
                        return (
                          <CustomWhisper
                            dropdownItems={[
                              <Dropdown.Item
                                key={1}
                                onClick={() => {
                                  setEditingId(item.id)
                                  setEditing(true)
                                }}
                              >
                                Edit
                              </Dropdown.Item>,
                              <Dropdown.Item
                                key={2}
                                onClick={() => handleDelete(item.id)}
                              >
                                Delete
                              </Dropdown.Item>
                            ]}
                          >
                            <IconButton
                              size="lg"
                              appearance="subtle"
                              icon={
                                <Icon
                                  icon="more"
                                  color={accentColor}
                                />
                              }
                            />
                          </CustomWhisper>
                        )
                      }}
                    </Table.Cell>
                  </TableColumnJs>
                )}
              </Table>
            )}
          </AccordionSection>
        </div>
      ) : (
        <Panel header={<div className="sightlyPanelHeader">Social</div>}>
          {!!editingSocial && (
            <SocialModal
              key="edit"
              type="edit"
              open={editing}
              handleClose={() => {
                setEditing(false)
                setEditingId(null)
              }}
              item={editingSocial}
            />
          )}

          <SocialModal
            key="add"
            type="add"
            open={adding && userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE)}
            handleClose={() => setAdding(false)}
            item={{
              id: 456456546456456564,
              name: '',
              description: '',
              aylienId: null,
              wikiUrl: '',
              relationshipId: 2,
              causeIsNotListed: false
            }}
          />

          <div className="descriptionText"></div>
          {!viewOnly && items && items.length > 0 && (
            <Whisper
              delayShow={300}
              enterable={true}
              placement="auto"
              trigger="hover"
              speaker={
                !userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE) ? (
                  <Tooltip>
                    <p>
                      This account has reached it’s limit.{' '}
                      <a
                        className="font-semibold"
                        target="_blank"
                        rel="noopener"
                        href="https://www.sightly.com/contact-us/"
                      >
                        Upgrade for more queries
                      </a>
                    </p>
                  </Tooltip>
                ) : (
                  <Tooltip>Adds a new Query</Tooltip>
                )
              }
            >
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <SightlyButton
                  disabled={!userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE)}
                  handleClick={() => setAdding(true)}
                  id="addMorebutton"
                  datatestid="queriesCreateNewbutton"
                  text="Create New"
                />
              </div>
            </Whisper>
          )}

          {isFetched && items && items.length < 1 ? (
            <Whisper
              delayShow={300}
              enterable={true}
              placement="autoHorizontalStart"
              trigger="hover"
              speaker={
                !userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE) ? (
                  <Tooltip>
                    <p>
                      This account has reached it’s limit.{' '}
                      <a
                        className="font-semibold"
                        target="_blank"
                        rel="noopener"
                        href="https://www.sightly.com/contact-us/"
                      >
                        Upgrade for more queries
                      </a>
                    </p>
                  </Tooltip>
                ) : (
                  <Tooltip>Adds a new Query</Tooltip>
                )
              }
            >
              <div>
                <SightlyButton
                  disabled={
                    !userCanService(perms.BRAND_PROFILE_UPDATE, products.QUERIES, userActions.CREATE) || viewOnly
                  }
                  handleClick={() => setAdding(true)}
                  id="addMorebutton"
                  datatestid="queriesAddMorebutton"
                  type="secondary"
                  text="Add +"
                />
              </div>
            </Whisper>
          ) : (
            isFetched && (
              <Table
                loading={isLoading}
                autoHeight
                data={items}
              >
                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.Cell
                    style={{ display: 'flex', alignItems: 'center' }}
                    dataKey="relationshipName"
                  />
                </TableColumnJs>

                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.Cell>
                    {(item: Social) => {
                      return <>{item.name}</>
                    }}
                  </Table.Cell>
                </TableColumnJs>

                <TableColumnJs flexGrow={1}>
                  <Table.HeaderCell>Wiki</Table.HeaderCell>
                  <Table.Cell dataKey="wikiUrl" />
                </TableColumnJs>

                {!viewOnly && (
                  <TableColumnJs flexGrow={1}>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.Cell style={{ display: 'flex', alignItems: 'center' }}>
                      {(item: Social) => {
                        return (
                          <CustomWhisper
                            dropdownItems={[
                              <Dropdown.Item
                                key={1}
                                onClick={() => {
                                  setEditingId(item.id)
                                  setEditing(true)
                                }}
                              >
                                Edit
                              </Dropdown.Item>,
                              <Dropdown.Item
                                key={2}
                                onClick={() => handleDelete(item.id)}
                              >
                                Delete
                              </Dropdown.Item>
                            ]}
                          >
                            <IconButton
                              size="lg"
                              appearance="subtle"
                              icon={
                                <Icon
                                  icon="more"
                                  color={accentColor}
                                />
                              }
                            />
                          </CustomWhisper>
                        )
                      }}
                    </Table.Cell>
                  </TableColumnJs>
                )}
              </Table>
            )
          )}
        </Panel>
      )}
    </>
  )
}

export default SocialSection
