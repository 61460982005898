import { AgeReport, AGE_DIMENSION, baseAgeReport } from "@/views/ActivationReports/reports/age.report";
import { AudienceReport, AUDIENCE_DIMENSION, baseAudienceReport, personaAudienceReportTemplate } from "@/views/ActivationReports/reports/audience.report";
import { baseCreativeReport, CreativeReport, CREATIVE_DIMENSION } from "@/views/ActivationReports/reports/creative.report";
import { adGroupReportTemplate, baseDeviceReport, campaignSummaryTemplate, DeviceReport, DEVICE_DIMENSION, performanceDetailTemplate, personaReportTemplate } from "@/views/ActivationReports/reports/device.report";
import { baseDmaRegionReport, DmaRegionReport, DMA_REGION_DIMENSION } from "@/views/ActivationReports/reports/dma-region.report";
import { baseGenderReport, GenderReport, GENDER_DIMENSION } from "@/views/ActivationReports/reports/gender.report";
import { baseYoutubePlacementReport, GOOGLE_PLACEMENT, YoutubePlacementReport } from "@/views/ActivationReports/reports/google-placement.report";
import { baseHouseholdIncomeReport, HouseholdIncomeReport, HOUSEHOLD_INCOME_DIMENSION } from "@/views/ActivationReports/reports/household-income.report";
import { baseIndustryCategoryReport, IndustryCategoryReport, INDUSTRY_CATEGORY_DIMENSION } from "@/views/ActivationReports/reports/industry-category.report";
import { baseKeywordReport, personaKeywordReportTemplate, KEYWORD_DIMENSION, KeywordReport } from "@/views/ActivationReports/reports/keyword.report";
import { baseMomentKeywordReport, MomentKeywordReport, MOMENT_KEYWORD_DIMENSION } from "@/views/ActivationReports/reports/moment-keyword.report";
import { baseMomentScenarioReport, MomentScenarioReport, MOMENT_SCENARIO_DIMENSION } from "@/views/ActivationReports/reports/moment-scenario.report";
import { baseMomentReport, MomentReport, MOMENT_DIMENSION } from "@/views/ActivationReports/reports/moment.report";
import { baseNewsCategoryReport, NewsCategoryReport, NEWS_CATEGORY_DIMENSION } from "@/views/ActivationReports/reports/news-category.report";
import { baseParentalStatusReport, ParentalStatusReport, PARENTAL_STATUS_DIMENSION } from "@/views/ActivationReports/reports/parental-status.report";
import { baseTopicReport, personaTopicReportTemplate, TopicReport, TOPIC_DIMENSION } from "@/views/ActivationReports/reports/topic.report";
import { GENERIC_DIMENSION, GenericDimensionOption } from '@/views/ActivationReports/types/activation-report.types';


export type AllReportTemplates =
    | DeviceReport
    | TopicReport
    | AudienceReport
    | KeywordReport
    | AgeReport
    | GenderReport
    | ParentalStatusReport
    | CreativeReport
    | DmaRegionReport
    | YoutubePlacementReport
    | HouseholdIncomeReport
    | IndustryCategoryReport
    | MomentReport
    | MomentKeywordReport
    | NewsCategoryReport
    | MomentScenarioReport;

    
export const allTemplateReports: AllReportTemplates[] = [
    performanceDetailTemplate,
    campaignSummaryTemplate,
    adGroupReportTemplate,
    personaReportTemplate,
    personaTopicReportTemplate,
    personaAudienceReportTemplate,
    personaKeywordReportTemplate,
    baseAgeReport,
    baseGenderReport,
    baseParentalStatusReport,
    baseCreativeReport,
    baseDeviceReport,
    baseAudienceReport,
    baseDmaRegionReport,
    baseYoutubePlacementReport,
    baseHouseholdIncomeReport,
    baseIndustryCategoryReport,
    baseKeywordReport,
    baseMomentReport,
    baseMomentKeywordReport,
    baseMomentScenarioReport,
    baseNewsCategoryReport,
    baseTopicReport
];

export type Option<T> = {
    [key: string]: T;
}

export interface CustomDimensionOption extends Omit<GenericDimensionOption, 'value'> {
    name: string
    value: GENERIC_DIMENSION | AGE_DIMENSION | AUDIENCE_DIMENSION | CREATIVE_DIMENSION | DEVICE_DIMENSION | DMA_REGION_DIMENSION | GENDER_DIMENSION | GOOGLE_PLACEMENT | HOUSEHOLD_INCOME_DIMENSION | INDUSTRY_CATEGORY_DIMENSION | KEYWORD_DIMENSION | MOMENT_KEYWORD_DIMENSION| MOMENT_SCENARIO_DIMENSION | MOMENT_DIMENSION | NEWS_CATEGORY_DIMENSION | PARENTAL_STATUS_DIMENSION | TOPIC_DIMENSION
  }

export const dimensionOptions: Option<CustomDimensionOption>  = {
    [AGE_DIMENSION.AGE_RANGE]: {
      name: 'Age Range',
      value: AGE_DIMENSION.AGE_RANGE
    },
    [AGE_DIMENSION.DEVICE]: {
      name: 'Device',
      value: AGE_DIMENSION.DEVICE
    },
    [AUDIENCE_DIMENSION.AUDIENCE_NAME]: {
        name: 'Audience Name',
        value: AUDIENCE_DIMENSION.AUDIENCE_NAME
      },
      [AUDIENCE_DIMENSION.AUDIENCE_TYPE]: {
        name: 'Audience Type',
        value: AUDIENCE_DIMENSION.AUDIENCE_TYPE
      },
      [CREATIVE_DIMENSION.AD_ID]: {
        name: 'Ad Id',
        value: CREATIVE_DIMENSION.AD_ID
      },
      [CREATIVE_DIMENSION.CLIENT_CREATIVE_ID]: {
        name: 'Client Creative Id',
        value: CREATIVE_DIMENSION.CLIENT_CREATIVE_ID
      },
      [CREATIVE_DIMENSION.CLIENT_CREATIVE_NAME]: {
        name: 'Client Creative Name',
        value: CREATIVE_DIMENSION.CLIENT_CREATIVE_NAME
      },
      [CREATIVE_DIMENSION.DEVICE]: {
        name: 'Device',
        value: CREATIVE_DIMENSION.DEVICE
      },
      [CREATIVE_DIMENSION.VIDEO_ID]: {
        name: 'Video Id',
        value: CREATIVE_DIMENSION.VIDEO_ID
      },
      [CREATIVE_DIMENSION.VIDEO_TITLE]: {
        name: 'Video Title',
        value: CREATIVE_DIMENSION.VIDEO_TITLE
      },
      [CREATIVE_DIMENSION.VIDEO_DURATION]: {
        name: 'Video Duration',
        value: CREATIVE_DIMENSION.VIDEO_DURATION
      },
      [DEVICE_DIMENSION.AD_ID]: {
        name: 'Ad Id',
        value: DEVICE_DIMENSION.AD_ID
      },
      [DEVICE_DIMENSION.AD_NAME]: {
        name: 'Ad Name',
        value: DEVICE_DIMENSION.AD_NAME
      },
      [DEVICE_DIMENSION.AD_TYPE]: {
        name: 'Ad Type',
        value: DEVICE_DIMENSION.AD_TYPE
      },
      [DEVICE_DIMENSION.CLIENT_CREATIVE_ID]: {
          name: 'Client Creative Id',
          value: DEVICE_DIMENSION.CLIENT_CREATIVE_ID
      },
      [DEVICE_DIMENSION.CLIENT_CREATIVE_NAME]: {
          name: 'Client Creative Name',
          value: DEVICE_DIMENSION.CLIENT_CREATIVE_NAME
      },
      [DEVICE_DIMENSION.DEVICE]: {
          name: 'Device',
          value: DEVICE_DIMENSION.DEVICE
      },
      [DMA_REGION_DIMENSION.DEVICE]: {
        name: 'Device',
        value: DMA_REGION_DIMENSION.DEVICE
      },
      [DMA_REGION_DIMENSION.DMA_REGION]: {
        name: 'DMA Region',
        value: DMA_REGION_DIMENSION.DMA_REGION
      },
      [GENDER_DIMENSION.DEVICE]: {
        name: 'Device',
        value: GENDER_DIMENSION.DEVICE
      },
      [GENDER_DIMENSION.GENDER]: {
        name: 'Gender Range',
        value: GENDER_DIMENSION.GENDER
      },
      [GOOGLE_PLACEMENT.DEVICE]: {
        name: 'Device',
        value: GOOGLE_PLACEMENT.DEVICE
      },
      [GOOGLE_PLACEMENT.PLACEMENT_TYPE]: {
        name: 'Placement Type',
        value: GOOGLE_PLACEMENT.PLACEMENT_TYPE
      },
      [GOOGLE_PLACEMENT.YOUTUBE_PLACEMENT_ID]: {
        name: 'Youtube Placement Id',
        value: GOOGLE_PLACEMENT.YOUTUBE_PLACEMENT_ID
      },
      [GOOGLE_PLACEMENT.YOUTUBE_PLACEMENT_NAME]: {
        name: 'Youtube Placement Name',
        value: GOOGLE_PLACEMENT.YOUTUBE_PLACEMENT_NAME
      },
      [HOUSEHOLD_INCOME_DIMENSION.DEVICE]: {
        name: 'Device',
        value: HOUSEHOLD_INCOME_DIMENSION.DEVICE
      },
      [HOUSEHOLD_INCOME_DIMENSION.INCOME_RANGE]: {
        name: 'Household Income',
        value: HOUSEHOLD_INCOME_DIMENSION.INCOME_RANGE
      },
      [INDUSTRY_CATEGORY_DIMENSION.BRAND_PROFILE_NAME]: {
        name: 'Brand Profile Name',
        value: INDUSTRY_CATEGORY_DIMENSION.BRAND_PROFILE_NAME
      },
      [INDUSTRY_CATEGORY_DIMENSION.INDUSTRY_CATEGORY]: {
        name: 'Industry Category',
        value: INDUSTRY_CATEGORY_DIMENSION.INDUSTRY_CATEGORY
      },
      [KEYWORD_DIMENSION.DEVICE]: {
        name: 'Device',
        value: KEYWORD_DIMENSION.DEVICE
      },
      [KEYWORD_DIMENSION.KEYWORD]: {
        name: 'Keyword',
        value: KEYWORD_DIMENSION.KEYWORD
      },
      [MOMENT_KEYWORD_DIMENSION.CLUSTER_ID]: {
        name: 'Cluster Id',
        value: MOMENT_KEYWORD_DIMENSION.CLUSTER_ID
      },
      [MOMENT_KEYWORD_DIMENSION.CLUSTER_NAME]: {
        name: 'Cluster Name',
        value: MOMENT_KEYWORD_DIMENSION.CLUSTER_NAME
      },
      [MOMENT_KEYWORD_DIMENSION.DEVICE]: {
        name: 'Device',
        value: MOMENT_KEYWORD_DIMENSION.DEVICE
      },
      [MOMENT_KEYWORD_DIMENSION.KEYWORD]: {
        name: 'Keyword',
        value: MOMENT_KEYWORD_DIMENSION.KEYWORD
      },
      [MOMENT_SCENARIO_DIMENSION.ACTION]: {
        name: 'Action',
        value: MOMENT_SCENARIO_DIMENSION.ACTION
      },
      [MOMENT_SCENARIO_DIMENSION.BRAND_PROFILE_NAME]: {
        name: 'Brand Profile Name',
        value: MOMENT_SCENARIO_DIMENSION.BRAND_PROFILE_NAME
      },
      [MOMENT_SCENARIO_DIMENSION.CLUSTER_ID]: {
        name: 'Moment Id',
        value: MOMENT_SCENARIO_DIMENSION.CLUSTER_ID
      },
      [MOMENT_SCENARIO_DIMENSION.CLUSTER_NAME]: {
        name: 'Moment Name',
        value: MOMENT_SCENARIO_DIMENSION.CLUSTER_NAME
      },
      [MOMENT_SCENARIO_DIMENSION.IMPLEMENTED_AT]: {
        name: 'Moment Implemented At',
        value: MOMENT_SCENARIO_DIMENSION.IMPLEMENTED_AT
      },
      [MOMENT_SCENARIO_DIMENSION.MAX_PUBLISHED_AT]: {
        name: 'Max Published At',
        value: MOMENT_SCENARIO_DIMENSION.MAX_PUBLISHED_AT
      },
      [MOMENT_SCENARIO_DIMENSION.MIN_PUBLISHED_AT]: {
        name: 'Min Published At',
        value: MOMENT_SCENARIO_DIMENSION.MIN_PUBLISHED_AT
      },
      [MOMENT_SCENARIO_DIMENSION.PERSONAS]: {
        name: 'Personas',
        value: MOMENT_SCENARIO_DIMENSION.PERSONAS
      },
      [MOMENT_SCENARIO_DIMENSION.SCENARIO_NAME]: {
        name: 'Scenario Name',
        value: MOMENT_SCENARIO_DIMENSION.SCENARIO_NAME
      },
      [MOMENT_SCENARIO_DIMENSION.SCENARIO_RESPONSE]: {
        name: 'Scenario Response',
        value: MOMENT_SCENARIO_DIMENSION.SCENARIO_RESPONSE
      },
      [MOMENT_SCENARIO_DIMENSION.STORY_TITLES]: {
        name: 'Story Titles',
        value: MOMENT_SCENARIO_DIMENSION.STORY_TITLES
      },
      [MOMENT_DIMENSION.ACTION]: {
        name: 'Action',
        value: MOMENT_DIMENSION.ACTION
      },
      [MOMENT_DIMENSION.BRAND_PROFILE_NAME]: {
        name: 'Brand Profile Name',
        value: MOMENT_DIMENSION.BRAND_PROFILE_NAME
      },
      [MOMENT_DIMENSION.CATEGORY_ID_LIST]: {
        name: 'Category Ids',
        value: MOMENT_DIMENSION.CATEGORY_ID_LIST
      },
      [MOMENT_DIMENSION.CATEGORY_LABEL_LIST]: {
        name: 'Category Labels',
        value: MOMENT_DIMENSION.CATEGORY_LABEL_LIST
      },
      [MOMENT_DIMENSION.CLUSTER_ID]: {
        name: 'Moment Id',
        value: MOMENT_DIMENSION.CLUSTER_ID
      },
      [MOMENT_DIMENSION.CLUSTER_NAME]: {
        name: 'Moment Name',
        value: MOMENT_DIMENSION.CLUSTER_NAME
      },
      [MOMENT_DIMENSION.IMPLEMENTED_AT]: {
        name: 'Moment Implemented At',
        value: MOMENT_DIMENSION.IMPLEMENTED_AT
      },
      [MOMENT_DIMENSION.MAX_PUBLISHED_AT]: {
        name: 'Max Published At',
        value: MOMENT_DIMENSION.MAX_PUBLISHED_AT
      },
      [MOMENT_DIMENSION.MIN_PUBLISHED_AT]: {
        name: 'Min Published At',
        value: MOMENT_DIMENSION.MIN_PUBLISHED_AT
      },
      [MOMENT_DIMENSION.PERSONAS]: {
        name: 'Personas',
        value: MOMENT_DIMENSION.PERSONAS
      },
      [MOMENT_DIMENSION.STORY_TITLES]: {
        name: 'Story Titles',
        value: MOMENT_DIMENSION.STORY_TITLES
      },
      [NEWS_CATEGORY_DIMENSION.BRAND_PROFILE_NAME]: {
        name: 'Brand Profile Name',
        value: NEWS_CATEGORY_DIMENSION.BRAND_PROFILE_NAME
      },
      [NEWS_CATEGORY_DIMENSION.NEWS_CATEGORY]: {
        name: 'News Category',
        value: NEWS_CATEGORY_DIMENSION.NEWS_CATEGORY
      },
      [PARENTAL_STATUS_DIMENSION.DEVICE]: {
        name: 'Device',
        value: PARENTAL_STATUS_DIMENSION.DEVICE
      },
      [PARENTAL_STATUS_DIMENSION.PARENTAL_STATUS]: {
        name: 'Parental Status',
        value: PARENTAL_STATUS_DIMENSION.PARENTAL_STATUS
      },
      [TOPIC_DIMENSION.TOPIC_NAME]: {
        name: 'Topic Name',
        value: TOPIC_DIMENSION.TOPIC_NAME
      },
      [TOPIC_DIMENSION.DEVICE]: {
          name: 'Device',
          value: TOPIC_DIMENSION.DEVICE
      },
  }

  export const templateCustomDimensionOptions:CustomDimensionOption[] = Object.keys(dimensionOptions).map(key => dimensionOptions[key])
