import { userAccountAxios } from '@/axiosInstances';
import { userCompletionStepsArr, UserCompletionStepSchema } from '@/schemas/schemas';

export const userCompletionSteps = {
  get: async (userId:number) => {
    const url = `/user-completion-steps/?userId=${userId}`;
    const result = await userAccountAxios.get(url);
    return userCompletionStepsArr.parse(result.data);
  },
  post: async (userId:number, videoId:string)  => {
    const url = '/user-completion-steps/';
    const result = await userAccountAxios.post(url, { userId, videoId });
    return UserCompletionStepSchema.parse(result.data);
  }
};
