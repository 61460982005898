import React, { useEffect, useState } from 'react'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { BaseOptions } from '@/views/ActivationReports/types/activation-report.types';

interface IProps {
    filters: BaseOptions[]
    filterName: string
    tableData: any[]
    handleDataTable: Function
}



export const ReportFiltersItems = ({ filters, filterName, tableData, handleDataTable }: IProps) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [filtersToDisplay, setFiltersToDisplay] = useState<BaseOptions[]>([])

    useEffect(() => {
        handleFiltersToDisplay();
    }, [])

    useEffect(() => {
        handleFiltersToDisplay();
    }, [tableData, filters])

    const handleFiltersToDisplay = () => {
        const filtersNotInTable = filters.filter(
            ( filter ) => !tableData.some(item => item.name === filter.name)
          );
          setFiltersToDisplay([...filtersNotInTable])
    }

    const handleAddDatatoTable = (filter: any) => {

        handleDataTable([...tableData, { 'name': filter.name, 'value': filter.value, 'category': filterName}]);
    };

    return (
        <div className='ml-2' data-testid="activation-reports-filters-item">
            <div className='flex flex-row align-middle cursor-pointer' onClick={() => { setIsCollapsed(!isCollapsed) }} data-testid="activation-reports-filters-item-collapsed">

                {isCollapsed ? <ChevronRightIcon
                    className="h-6 w-6"
                    aria-hidden="true"
                /> : <ChevronDownIcon
                    className="h-6 w-6"
                    aria-hidden="true"
                />}

                <div className='text-lg	font-semibold ml-1'>
                    {filterName}

                </div>
            </div>
            {
                !isCollapsed &&
                <div className='mt-3' data-testid="activation-reports-filters-item-data">
                    {
                        filtersToDisplay.map((filter) => {
                            return <div className='flex flex-row text-base mb-3 cursor-pointer' key={filter.name} onClick={() => handleAddDatatoTable(filter)}>
                                <PlusCircleIcon
                                    className="h-6 w-6 mr-1"
                                    aria-hidden="true"
                                />
                                {filter.name}
                            </div>
                        })
                    }
                </div>
            }
        </div>
    )
}
