import React, { useState } from 'react';
import { Modal } from 'rsuite';
import { adGroupReportTemplate, baseDeviceReport, campaignSummaryTemplate, performanceDetailTemplate, personaReportTemplate } from '../templates/device.template.type';
import { baseCreativeReport } from '../templates/creative.template.type';
import { baseParentalStatusReport } from '../templates/parental-status.template.type';
import { baseAgeReport } from '../templates/age.template.type';
import { baseAudienceReport, personaAudienceReportTemplate } from '../templates/audience.template.type';
import { baseDmaRegionReport } from '../templates/dma-region.template.type';
import { baseGenderReport } from '../templates/gender.template.type';
import { baseHouseholdIncomeReport } from '../templates/household-income.template.type';
import { baseIndustryCategoryReport } from '../templates/industry-category.template.type';
import { baseKeywordReport, basePersonaKeywordReport } from '../templates/keyword.template.type';
import { baseMomentReport } from '../templates/moment.template.type';
import { baseMomentKeywordReport } from '../templates/moment-keyword.template.type';
import { baseMomentScenarioReport } from '../templates/moment-scenario.template.type';
import { baseNewsCategoryReport } from '../templates/news-category.template.type';
import { baseTopicReport, personaTopicReportTemplate } from '../templates/topic.template.type';
import { baseYoutubePlacementReport } from '../templates/google-placement.template.type';

const CreateModal = ({ open, handleClose }: { open: boolean, handleClose: () => void }) => {
    const [selectedCategory, setSelectedCategory] = useState('Campaign Reports');
    const [selectedReport, setSelectedReport] = useState<string | null>(null);

    const reportsByCategory: any = {
        'Campaign Reports': [performanceDetailTemplate],
        'Analytics Reports': [campaignSummaryTemplate, adGroupReportTemplate, personaReportTemplate, personaTopicReportTemplate, personaAudienceReportTemplate, basePersonaKeywordReport, baseAgeReport, baseGenderReport, baseParentalStatusReport, baseCreativeReport, baseDeviceReport],
        'Custom Reports': [baseAgeReport, baseAudienceReport, baseCreativeReport, baseDeviceReport, baseDmaRegionReport, baseGenderReport, baseYoutubePlacementReport, baseHouseholdIncomeReport, baseIndustryCategoryReport, baseKeywordReport, baseMomentReport, baseMomentKeywordReport, baseMomentScenarioReport, baseNewsCategoryReport, baseParentalStatusReport, baseTopicReport],
    };

    return (
        <Modal show={open} onClose={handleClose} onHide={handleClose} size="lg">
            <Modal.Header>
            </Modal.Header>

            <Modal.Body className="flex p-4">
                <div className="w-1/4 pr-4">
                    {Object.keys(reportsByCategory).map((category) => (
                        <button
                            key={category}
                            className={`py-2 px-2 text-left font-semibold text-lg w-full rounded-lg mt-2 ${selectedCategory === category
                                    ? 'bg-purple-100 text-purple-600'
                                    : 'text-gray-600'
                                }`}
                            onClick={() => setSelectedCategory(category)}
                        >
                            {category}
                        </button>
                    ))}
                </div>

                <div className="w-3/4 pl-3 grid grid-cols-2 gap-4">
                    {reportsByCategory[selectedCategory].map((report: any) => (
                        <div
                            key={report.name}
                            className={`p-6 border rounded-lg shadow-md bg-white hover:shadow-lg transition-shadow duration-300 w-72 cursor-pointer ${selectedReport === report.name ? 'border-purple-600' : 'border-gray-300'
                                }`}
                            onClick={() => setSelectedReport(report.name)}
                        >
                            <p className="font-bold flex items-center">
                                {report.name}
                                {report.icon && <img className="ml-2 w-5 h-5" src={report.icon} alt={`${report.name} icon`} />}
                            </p>
                            <p className="text-sm text-gray-600">{report.description}</p>
                            <p className="text-xs text-gray-500">
                                <span className="text-purple-500 font-semibold">Metrics include: <span className="text-xs text-gray-500 font-normal">{report.metrics.map((metric: any) => metric.name).join(', ')}</span></span>
                            </p>
                        </div>
                    ))}
                </div>
            </Modal.Body>

            <Modal.Footer className="border-t p-4 flex justify-end">
                <button className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700" onClick={handleClose}>
                    Continue
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateModal;
