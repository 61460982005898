import React from 'react';
import { CustomRadio } from './CustomRadio';
import { connect } from 'react-redux';
import Panel from 'rsuite/lib/Panel';
import Tooltip from 'rsuite/lib/Tooltip';
import Whisper from 'rsuite/lib/Whisper';
import Skeleton from 'react-loading-skeleton';
import useOpinions from './useOpinions';
import config  from '@/config';
import { useMatch, useSearch } from '@tanstack/react-location';
import {Opinions as OpinionsV2} from './V2/index.tsx';

const mapStateToProps = (state) => {
    return {
        brandProfile: state.brandProfileUnderEdit
    }
}

function Opinions(props) {
    const {
        params: { brandProfileId }
    } = useMatch()
    const { viewOnly: viewOnlyParam } = useSearch()
    const viewOnly = viewOnlyParam || false
    const { opinions, handleOpinionChange, opinionsIsLoading } = useOpinions(brandProfileId)

    const _handleOpinionSelect = (opinion, opinionResponseId) => {
        let opinionId = opinion.opinionId
        let data = {
            opinionId: opinionId,
            opinionResponseId: Number(opinionResponseId) //
        }

        let params = {
            opinions: [data],
            brandProfileId: brandProfileId
        }
        handleOpinionChange(params)
    }

    const handleOpinionSelect = React.useCallback(
        (opinion, opinionResponseId) => {
            _handleOpinionSelect(opinion, opinionResponseId)
        },
        [props.brandProfile]
    )

    return (<>
    { config.featureFlag.aiProfiles
            ? <OpinionsV2 /> :
            <Panel header={<div className="sightlyPanelHeader">Opinions</div>}>
            <div
                style={{
                    fontWeight: 500,
                    color: '#a3a3a3',
                    fontSize: 14,
                    paddingLeft: 20,
                    paddingRight: 20
                }}
            >
                Note: These selections work in tandem with your existing campaign targeting. For example, media would
                only run across these content types if the user watching fits within the audience parameters for the
                campaign.
            </div>
            <div style={{ height: 30 }} />
            <div style={{ position: 'relative' }}>
                {opinionsIsLoading && (
                    <Skeleton
                        height={259}
                        count={5}
                        width={'100%'}
                    />
                )}
                {opinions &&
                    opinions.length > 0 &&
                    opinions.map((opinionType, index) => {
                        const expandedByDefault =
                            !!window.location.hash &&
                            opinionType?.opinions?.filter((o) => o.opinionId == window.location.hash.replace('#', ''))
                                .length > 0
                        return (
                            <div key={index}>
                                <Panel
                                    collapsible
                                    defaultExpanded={expandedByDefault}
                                    header={
                                        <div style={{ fontSize: 20, fontWeight: 700 }}>
                                            {opinionType.opinionTypeName}
                                        </div>
                                    }
                                >
                                    <Whisper
                                        delayShow={700}
                                        delayHide={0}
                                        trigger="hover"
                                        placement="auto"
                                        speaker={
                                            <Tooltip>
                                                <div style={{ textAlign: 'left' }}>
                                                    Strongly Agree: Target content and actively look for opportunities
                                                    related to this opinion
                                                </div>
                                                <br />
                                                <div style={{ textAlign: 'left' }}>
                                                    Agree: Target content related to this opinion
                                                </div>
                                                <br />
                                                <div style={{ textAlign: 'left' }}>
                                                    Unsure: Do not take any action on content related to this opinion.
                                                    Based on other targeting tactics, you may serve on content related
                                                    to this opinion
                                                </div>
                                                <br />
                                                <div style={{ textAlign: 'left' }}>
                                                    Disagree: Block all content related to this opinion
                                                </div>
                                                <br />
                                                <div style={{ textAlign: 'left' }}>
                                                    Strongly Disagree: Block all content related to this opinion, and
                                                    consider anything related to this opinion a risk
                                                </div>
                                            </Tooltip>
                                        }
                                    >
                                        <div
                                            id="whatDoOptionsMean"
                                            style={{ display: 'flex', justifyContent: 'end' }}
                                        >
                                            <div style={{ flex: 1 }} />
                                            <div>What do these options mean?</div>
                                        </div>
                                    </Whisper>

                                    <br />
                                    {opinionType.opinions.map((opinion, opinionIndex) => {
                                        return (
                                            <CustomRadio
                                                key={opinionIndex}
                                                handleOpinionSelect={handleOpinionSelect}
                                                opinion={opinion}
                                                disabled={viewOnly}
                                            />
                                        )
                                    })}
                                </Panel>
                            </div>
                        )
                    })}
            </div>
        </Panel>
        }
    </>
        
    )
}

export default connect(mapStateToProps, null)(Opinions)
