import React from 'react'
import { FormLabel } from './SightlyFormLabel'
import {
    ArrowLongLeftIcon,
    CheckIcon,
    HandThumbUpIcon,
    HomeIcon,
    MagnifyingGlassIcon,
    PaperClipIcon,
    QuestionMarkCircleIcon,
    UserIcon
} from '@heroicons/react/20/solid'
import { FormError } from './SightlyFormError'

interface IProps {
    id: string
    hasSearchIcon?: boolean
    label?: string
    placeholder?: string
    disabled?: boolean
    error?: string
    width?: string | number
    autoFocus?: boolean
    prefix?: string
    textarea?: boolean
    required?: boolean
    value?: any
    defaultValue?: any
    onChange: (val: any) => void
    onBlur?: () => void
    dataTestId?: string
}

export const SightlyInput = ({
    label,
    placeholder,
    disabled = undefined,
    hasSearchIcon,
    id,
    error,
    width,
    autoFocus,
    prefix,
    required,
    defaultValue,
    value,
    textarea,
    onChange,
    onBlur,
    dataTestId
}: IProps) => {
    if (defaultValue && value) {
        throw new Error('defaultValue and value cannot both be declared')
    }
    if (defaultValue === undefined && value === undefined) {
        throw new Error('defaultValue or value must be declared')
    }
    const borderClass = prefix ? 'rounded-none rounded-r-md' : 'rounded-md'
    const primaryClassName = `${borderClass} ${
        hasSearchIcon && 'pl-10 pr-3 leading-5'
    } form-input font-normal block w-full border-gray-300 placeholder:font-light placeholder:text-slate-400 focus:border-sightly-blue focus:ring-sightly-blue text-sm disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500`

    return (
        <div
            data-testid={dataTestId}
            style={{
                textAlign: 'left',
                position: 'relative',
                width: width ? width : undefined
            }}
        >
            <div>
                {label && (
                    <FormLabel
                        id={id}
                        text={label}
                        required={required}
                    />
                )}
                <div className="flex rounded-md shadow-sm relative">
                    {prefix && (
                        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                            {prefix}
                        </span>
                    )}
                    {hasSearchIcon && (
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <MagnifyingGlassIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </div>
                    )}

                    {defaultValue ? (
                        <input
                            type="text"
                            id={id}
                            className={primaryClassName}
                            placeholder={disabled ? '' : placeholder}
                            disabled={disabled}
                            autoFocus={autoFocus}
                            defaultValue={defaultValue}
                            onChange={(e) => onChange(e.target.value)}
                            onBlur={onBlur}
                        />
                    ) : (
                        <>
                            {textarea ? (
                                <textarea
                                    id={id}
                                    rows={3}
                                    className={primaryClassName}
                                    placeholder={disabled ? '' : placeholder}
                                    disabled={disabled}
                                    autoFocus={autoFocus}
                                    value={value}
                                    onChange={(e) => onChange(e.target.value)}
                                    onBlur={onBlur}
                                />
                            ) : (
                                <input
                                    type="text"
                                    id={id}
                                    className={primaryClassName}
                                    placeholder={disabled ? '' : placeholder}
                                    disabled={disabled}
                                    autoFocus={autoFocus}
                                    value={value}
                                    onChange={(e) => onChange(e.target.value)}
                                    onBlur={onBlur}
                                />
                            )}
                        </>
                    )}
                </div>
                <FormError
                    id={id}
                    error={error}
                />
            </div>
        </div>
    )
}
