import React, { useState } from 'react'
import { TikTokHashtagCard } from '@/views/TikTok/Hashtags/TikTokHashtagCard'
import SightlyButton from '@/components/Sightly/SightlyButton'
import { Icon } from 'rsuite'
import { IHashtag, IHashtagsStatus, SelectedHashtagType } from '@/views/TikTok/Hashtags/types'
import { InfoPage } from '@/components/InfoPage'
import { SerializableMap } from '@/utils/classes/SerializableMap'
import { LoadingPage } from '@/components/LoadingPage'

interface HashtagsInfiniteScrollProps {
  hashtags: IHashtag[]
  hashtagsStatus: IHashtagsStatus[] | undefined
  hasNext: boolean
  isLoading: boolean
  selectedHashtags: SerializableMap<string, SelectedHashtagType>
  handleCheckboxChange: (hashtagId:string) => void
  setSelectedHashtag: Function
  setPreviousPagesNarratives: Function
  setPage: Function
  page: number,
  dateRange: string
}

export default function HashtagsInfiniteScroll({
  hashtags,
  hashtagsStatus,
  hasNext,
  isLoading,
  selectedHashtags,
  handleCheckboxChange,
  setSelectedHashtag,
  setPreviousPagesNarratives,
  setPage,
  page,
  dateRange
}: HashtagsInfiniteScrollProps) {
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false)
  const hashtagStatusLookup = new Map(hashtagsStatus?.map((status) => [status.hashtag_id, status]));

  React.useEffect(() => {
    setIsLoadingMore(false)
  }, [hashtags])

  return (
    <div>
      {isLoading && !isLoadingMore ? (
        <LoadingPage message="Loading hashtags" />
      ) : !hashtags || hashtags.length < 1 ? (
        <InfoPage message="There are no hashtags for your filters" />
      ) : (<>
        <div className="flex flex-row flex-wrap gap-x-10 xl:justify-between gap-y-10 hashtags">
          {hashtags.map((hashtag, index) => (
            <TikTokHashtagCard
              key={index}
              tiktokHashtag={hashtag}
              status={hashtagStatusLookup.get(hashtag.hashtagId)}
              isChecked={selectedHashtags.has(hashtag.hashtagId)}
              handleCheckboxChange={(hashtagId: string) => handleCheckboxChange(hashtagId)}
              index={index + 1}
              onOpenInsights={(hashtag: IHashtag) => setSelectedHashtag(hashtag)}
              dateRange={dateRange}
            />
          ))}
        </div>

        {hasNext && (
          <div className="flex justify-center w-full pt-6 pb-4">
            <SightlyButton
              datatestid="load-more-moments-button"
              block
              id="loadmorebutton"
              text="Load More"
              handleClick={() => {
                setPreviousPagesNarratives(hashtags)
                setPage(page + 1)
                setIsLoadingMore(true)
              }}
              loading={isLoading}
              disabled={isLoading}
            />
          </div>
        )}

        {isLoadingMore && (
          <p className='pt-6 pb-4' style={{ color: '#999', textAlign: 'center' }}>
            <Icon
              icon="spinner"
              spin
            />{' '}
            Loading...
          </p>
        )}
      </>)}
    </div>
  )
}