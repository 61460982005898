import React from 'react'
import { ClusterType, RelevanceClassification } from '@/views/Discover/Moments/v2/types';
import { useFlag } from '@unleash/proxy-client-react';
import config from '@/config';

import highRelevanceIcon from '@/assets/img/relevance/high_relevance_icon.svg';
import lowRelevanveIcon from '@/assets/img/relevance/low_relevanve_icon.svg';
import mediumRelevanceIcon from '@/assets/img/relevance/medium_relevance_icon.svg';

interface IProps {
    relevanceClassification: RelevanceClassification | null | undefined
  }

interface RelevanceContent{
    title: string,
    icon: string,
  }


  
export const RelevanceClassificationBadge = ({
    relevanceClassification
  }: IProps) => {

    const getRelevanceInformation = ( relevanceClassification: RelevanceClassification | null | undefined) : RelevanceContent => {
        switch (relevanceClassification) {
          case 'High':
            return {
              title: 'High Relevance',
              icon: highRelevanceIcon
            } 
            highRelevanceIcon
          case 'Medium':
            return {
              title: 'Medium Relevance',
              icon: mediumRelevanceIcon
            } 
          case 'Low':
            return {
              title: 'Low Relevance',
              icon: lowRelevanveIcon
            } 
          default:
            return   {
              title: '',
              icon: ''
            } ;
        }
      }

    const brandRelevanceEnabled = useFlag(`enable_brand_relevance_${config.environment}`);
    return (
        <div>                
            {
                brandRelevanceEnabled && relevanceClassification && (
                <div data-testid="anticipation-boards-moment-card-relevance"
                    className="flex items-center pl-1 text-xs text-gray-500">
                    <img src={getRelevanceInformation(relevanceClassification).icon} alt={relevanceClassification} className="pr-1" />
                    <p className='font-bold text-sm text-[#575757]'>{getRelevanceInformation(relevanceClassification).title}</p>
                </div>
                )
            }
        </div>
    )
}
