import toast from 'react-hot-toast';
import { SignupFormType } from '@/views/Signup/types';
import { ResetPasswordApiResponse, SignupApiResponse } from '@/api/apiTypes';
import { ErrorOrResult, ErrorWithStatusOrResult } from '@/api/types';
import { userAccountAxios } from '@/axiosInstances';
import { LoginFormType } from '@/views/Login/types';
import { ResetPasswordFormType } from '@/views/ResetPassword/types';
import { ChangePasswordFormType } from '@/views/ChangePassword/types';
import { UserLoginSchema } from '@/schemas/schemas';
import { logError } from '@/utils';
import { AccountType, UserSchema, UserType } from '@/schemas/schemas';
import { isIframe } from '@/validations';

export const user = {
    login: async (loginDetails: LoginFormType): Promise<ErrorWithStatusOrResult<boolean>> => {
      const genericErrorMessage = 'Please try again, or contact support at support@sightly.com';
      const url = '/authenticate'
      try {
        const result = await userAccountAxios.post(url, {
          username: loginDetails.email,
          password: loginDetails.password
        })
        if (!result.status) {
          return { isError: true, error: 'No status', status: 500 }
        }
        if (result.status !== 200 || ('message' in result.data)) {
          if (result.data.status === 401) {
            return { isError: true, error: 'Please try again, or use the Forgot Your Password link.', status: 401 };
          }
          return { isError: true, error: genericErrorMessage, status: result.status };
        }
  
        const token = result.data.jwt
        const user = result.data.user
        const userLoginParsing = UserLoginSchema.safeParse(user)
        if (!userLoginParsing.success) {
          logError(userLoginParsing.error)
        }
        localStorage.setItem('token', token)
        localStorage.setItem('userId', user.userId)
        localStorage.setItem('email', user.email)
        return { isError: false, result: true }
      } catch (error) {
        return { isError: true, error: genericErrorMessage, status: 500 }
      }
    },
  signup: async (signupDetails: SignupFormType): Promise<ErrorOrResult<boolean>> => {
    const result = await userAccountAxios.post<SignupApiResponse>('/signup', {
      email: signupDetails.email,
      password: signupDetails.password,
      firstName: signupDetails.firstName,
      lastName: signupDetails.lastName,
      companyName: signupDetails.companyName,
      isTikTokUser: isIframe(),
      ...(isIframe() && { tikTokAccessToken: localStorage.getItem('tiktok_access_token') }),
      // trialStartDate, trialEndDate, packageId, accountStatusId provided by API
    })
    if (!result.status) {
      return { isError: true, error: 'No status' }
    }
    try {
      if (result.status !== 200) {
        if ('message' in result.data) {
          return { isError: true, error: result.data.message }
        }
        return { isError: true, error: 'Unknown error' }
      }

      if (!('jwt' in result.data)) {
        throw new Error('jwt not found in signup payload for successful request')
      }

      const token = result.data.jwt
      const user = result.data.user
      const userLoginParsing = UserLoginSchema.safeParse(user)
      if (!userLoginParsing.success) {
        logError(userLoginParsing.error)
      }
      localStorage.setItem('token', token)
      localStorage.setItem('userId', user.userId.toString())
      return { isError: false, result: true }
    } catch (error) {
      return { isError: true, error: `catch: ${error}` }
    }
  },
  resetPassword: async (resetPasswordDetails: ResetPasswordFormType): Promise<ErrorOrResult<string>> => {
    const result = await userAccountAxios.post<ResetPasswordApiResponse>('/reset-password', {
      email: resetPasswordDetails.email
    })
    if (!result.status) {
      return { isError: true, error: 'No status' }
    }
    try {
      if (result.status !== 200) {
        let errorPayload = result.data as any
        if ('Error' in errorPayload) {
          return { isError: true, error: errorPayload.Error as string }
        }
        return { isError: true, error: 'Unknown error' }
      }

      return { isError: false, result: resetPasswordDetails.email }
    } catch (error) {
      return { isError: true, error: `catch: ${error}` }
    }
  },
  changePassword: async (
    userId: string,
    token: string,
    changePasswordDetails: ChangePasswordFormType
  ): Promise<ErrorOrResult<boolean>> => {
    const result = await userAccountAxios.post(`/update-password/${userId}/${token}`, {
      password: changePasswordDetails.password
    })

    if (!result.status) {
      return { isError: true, error: 'No status' }
    }
    try {
      if (result.status !== 200) {
        if ('message' in result.data) {
          return { isError: true, error: result.data.message }
        }
        return { isError: true, error: 'Unknown error' }
      }

      return { isError: false, result: true }
    } catch (error) {
      return { isError: true, error: `catch: ${error}` }
    }
  },
  get: async (userId: number, packageAccountId?: number, limitProducts?: boolean) => {
    try {
      let url = `/user/${userId}?permissions=true`
      if (packageAccountId) url += `&packageAccountId=${packageAccountId}`;
      if (limitProducts) url += `&limitProducts=${limitProducts}`;
      const { data } = await userAccountAxios.get<UserType>(url)
      const parsedUser = UserSchema.parse(data)
      return parsedUser
    } catch (error) {
      logError(error)
      throw error
    }
  },
  updateAccounts: async (userId: number, accounts: AccountType[]) => {
    try {
      if (accounts.length < 1) {
        alert('User not saved. Each user must have at least one account assigned to them.')
        return
      }
      const url = `/user/${userId}/accounts`
      return await userAccountAxios.patch(url, accounts)
    } catch (error) {
      logError(error)
      throw error
    }
  },
  updateUserData: async (user: Partial<UserType>) => {
    const userId = user.userId
    const url = `/user/${userId}`

    let myUser: Partial<UserType> = {
      userId: user.userId,
      firstName: user.firstName,
      lastName: user.lastName,
      company: user.company,
      email: user.email,
      userType: user.userType,
      roleId: user.roleId,
      accounts: []
    }

    const userLoginParsing = UserLoginSchema.safeParse(myUser)
    if (!userLoginParsing.success) {
      logError(userLoginParsing.error)
      alert('Could not validate new user')
    }
    delete myUser.accounts
    const result = await userAccountAxios.patch(url, myUser)
    if (result.status === 200) {
      toast.success('User saved!')
    }
  },
  create: async (user: Partial<UserType>) => {
    const url = `/user/invite`
    user.userName = 'placeholder'
    user.phoneNumber = '123123123'
    delete user.userId
    return await userAccountAxios.post(url, user)
  },
  canAccessAllAccountsOfTargetUser: async (targetUserId: number) => {
    if(typeof(targetUserId) !== 'number') return
    const url = `/user/${targetUserId}`
    const response = await userAccountAxios.head(url)
    return response
  }
};
