import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { brandProfilesObjValidation } from '@/schemas/brandProfiles';
import { BrandProfile } from '@/classes/brandProfile';
import { userAccountAxios } from '@/axiosInstances';
import React from 'react';
import { useNavigate, useSearch } from '@tanstack/react-location';
import { MyLocationGenerics } from '@/classes/utils';
import { api } from '@/api/api';
import useUser from '@/hooks/useUser';
import { location } from '@/RouteContainer';
import { rqKeys } from '@/ReactQueryKeyFactory';
import { NewBrandProfileFormType } from '@/views/BrandProfiles/types';
import { products } from '@/staticData/products';

async function getRegularBrandProfiles(accountId: number | undefined) {
    if (accountId === undefined) return []
    const url = `/account/${accountId}/brand-profiles`
    const { data } = await userAccountAxios.get<BrandProfile[]>(url)
    brandProfilesObjValidation.validate(data.slice(0, 2)).catch(function(err: object) {
        console.log(err)
        console.error(
            ' we received different data from the api than expected while fetching all brand profiles, see console log for more details'
        )
    })
    return data.filter((p: BrandProfile) => !p.archived)
}

type IProps = {
    submittedOnly: boolean
}

export default function useBrandProfiles(args: IProps) {
    const { currentAccount } = useUser()
    const navigate = useNavigate()

    const queryClient = useQueryClient()

    const QUERY_KEY = React.useMemo(() => rqKeys.brandProfilesKey(currentAccount?.accountId), [currentAccount?.accountId]);
    const brandProfilesObj = useQuery(QUERY_KEY, () => getRegularBrandProfiles(currentAccount?.accountId), {
        enabled: !!currentAccount?.accountId,
        refetchOnMount: true,
        select: (bps: BrandProfile[]) =>
            args.submittedOnly === true ? bps.filter((bp: BrandProfile) => bp.submitted) : bps
    })

    const createBrandProfileMutation = useMutation((formData?:NewBrandProfileFormType) => api.brandProfile.create(currentAccount?.accountId, formData), {
        mutationKey: rqKeys.brandProfileCreateMutationKey(currentAccount?.accountId),
        onSuccess: data => {
          if (!data || !data.brandProfileId) {
            throw new Error('brand profile creation result must contain brandProfileId');
          }
          queryClient.invalidateQueries(QUERY_KEY);

          // to refresh product quota
          queryClient.invalidateQueries(
            rqKeys.subscriptionQuotaQueryKey(currentAccount?.accountId, products.BRAND_PROFILES)
          );

          const url = `/app/brandProfiles/brandProfile/${data.brandProfileId}/brandInformation`;
          navigate({ to: url });
        }
    })

    const cloneBrandProfileMutation = useMutation(
        ({ brandProfileId }: { brandProfileId: number }) => api.brandProfile.clone(brandProfileId),
        {
            onSuccess: data => {
                if (!data || !data.brandProfileId) {
                    throw new Error('brand profile clone result must contain brandProfileId')
                }
                const brandProfileId = data?.brandProfileId
                queryClient.invalidateQueries(QUERY_KEY)
                const url = `/app/brandProfiles/brandProfile/${brandProfileId}/brandInformation`
                navigate({ to: url })
            }
        }
    )

    const brandProfiles = brandProfilesObj.data
    const { brandProfileId: urlParamBrandProfileId } = useSearch<MyLocationGenerics>()

    const currentBrandProfile = React.useMemo(() => {
        if (!brandProfiles || brandProfiles.length < 1) {
            return null
        }
        if (urlParamBrandProfileId) {
            const urlParamsBrandProfile = brandProfiles.filter(
                (p: BrandProfile) => p.brandProfileId == Number(urlParamBrandProfileId)
            )
            if (!urlParamsBrandProfile || urlParamsBrandProfile === undefined || urlParamsBrandProfile.length < 1) {
                return brandProfiles[0]
            }

            return urlParamsBrandProfile[0]
        } else {
            return brandProfiles[0]
        }
    }, [brandProfiles, urlParamBrandProfileId, location.current.search])

    return {
        ...brandProfilesObj,
        currentBrandProfile,
        createBrandProfile: createBrandProfileMutation.mutate,
        createBrandProfileMutation,
        cloneBrandProfile: cloneBrandProfileMutation
    }
}
