import React from "react";
import { SelectPicker } from "rsuite";
import {granularity} from '@/views/ReportGenerator/reportGenerator-types';
import { ActivationReportConfiguration } from "@/views/ActivationReports/types/activation-report.types";

interface IProps {
    handleOnChange: Function
    formValues: ActivationReportConfiguration
}


export default function Granularity({ handleOnChange, formValues } : IProps) {
    return (
      <div data-testid='div-report-generator-select-date-range'>
        <h5 className="mt-3 mb-2 text-base">Granularity</h5>
        <div className="relative">
          <SelectPicker
            data={granularity}
            value={formValues.granularity}
            onChange={(value) => handleOnChange('granularity', value)}
            searchable={false}
            placement="autoVerticalStart"
            cleanable={false}
          />
        </div>
      </div>
    )
}
