import React, { useState, useEffect } from 'react';
import { IntegrationCard } from './integrationCard';
import { api } from '@/api/api';
import useUser from '@/hooks/useUser';

const getAuthCodeFromReferrer = () => {
  const referrer = document.referrer;
  return referrer.includes('auth_code') ? referrer.split('auth_code=')[1].split('&')[0] : null;
};

export const Integrations = () => {
  const [tikTokStatus, setTikTokStatus] = useState('NO_ACCESS_TOKEN');
  const { currentAccount } = useUser();
  const authCode = getAuthCodeFromReferrer();

  useEffect(() => {
    const fetchTikTokStatus = async () => {
      if (!currentAccount) return;

      try {
        const { data } = await api.tikTok.status(currentAccount.accountId);
        setTikTokStatus(data.status);

        if (authCode && data.status !== 'ACCESS_TOKEN_VALID') {
          const response = await api.tikTok.setAccessToken(authCode, currentAccount.accountId);
          setTikTokStatus(response.data.status || 'NO_ACCESS_TOKEN');
        }
      } catch (error) {
        console.error('Failed to fetch TikTok status:', error);
      }
    };

    fetchTikTokStatus();
  }, [currentAccount, authCode]);

  const removeTikTokIntegration = async () => {
    if (!currentAccount) return;

    try {
      const response = await api.tikTok.removeAccessToken(currentAccount.accountId);
      if (response.status === 200) {
        setTikTokStatus('NO_ACCESS_TOKEN');
      }
    } catch (error) {
      console.error('Failed to remove TikTok integration:', error);
    }
  };

  const addTikTokIntegration = async () => {
    const appID = process.env.REACT_APP_TIK_TOK_APP_ID
    const baseURL = process.env.REACT_APP_TIK_TOK_AUTH_URL
    const redirectURI = process.env.REACT_APP_TIK_TOK_REDIRECT_URI

    if (!(appID && baseURL && redirectURI)) return
      
    const params = new URLSearchParams()
    params.append("app_id", appID)
    params.append("redirect_uri", redirectURI)

    const fullURL = `${baseURL}?${params.toString()}`
    
    window.location.href = fullURL
  };

  return (
    <div className="px-10 pt-12 h-full overflow-auto">
      <div className="text-2xl font-semibold mb-10">Integrations</div>
      <div className="max-w-2xl mb-4">
        <p>
          Integrations allow you to automatically import campaigns from your ad accounts to your
          Brand Mentality activation manager, and enables you to activate moments on these accounts.
        </p>
      </div>
      <IntegrationCard tikTokStatus={tikTokStatus} 
        removeTikTokIntegration={removeTikTokIntegration}
        addTikTokIntegration={addTikTokIntegration} />
    </div>
  );
};
