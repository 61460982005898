import NoImageFoundPath from '@/assets/img/NoImageFound.svg'
import ChartTooltip from '@/views/Discover/Boards/components/conversations/ChartTooltip'
import { RelevanceClassificationBadge } from '@/views/Discover/Moments/v2/components/RelevanceClassificationBadge'
import { IResult } from '@/views/Trends/types'
import React from 'react'
import { Area, AreaChart, Tooltip as RechartTooltip, ResponsiveContainer, XAxis } from 'recharts'
import Growth from './Growth'

interface IProps {
  result: IResult
}

const ResultCard = ({ result }: IProps) => {
  const totals = [
    { title: 'Total Trends', value: result.totalTrends },
    { title: 'Total Moments', value: result.totalMoments },
    { title: 'Total Social Posts', value: result.totalSocialPosts }
  ]

  return (
    <div key={result.title} className="relative grid grid-cols-11 gap-2 h-full rounded-md border border-gray-200 bg-white hover:z-50 animate-in fade-in">
      {/* Image section */}
      <div className='col-span-2 h-full'>
        <img
          src={result.imageUrl}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src = NoImageFoundPath
          }}
          alt={`image:${result.title}`}
          className="h-full"
        />
      </div>
      <div className='col-span-9 px-2 xl:px-0 py-4 grid grid-cols-1 gap-4 divide-y xl:grid-cols-5 xl:divide-y-0 xl:divide-x divide-slate-300 '>
        {/* Middle section */}
        <div className='xl:col-span-3 flex flex-col justify-between'>
          <div>
            <span className="text-xs xl:text-sm font-bold line-clamp-1">{result.title}</span>
            {result.description}
          </div>
          <div className='flex flex-row justify-between'>
            {
              totals.map((item: { title: string, value: number }) => {
                return (
                  <div className='flex flex-col'>
                    <span className='text-xs'>
                      {item.title}
                    </span>
                    <span className='font-bold text-lg text-sightlyPurple'>
                      {item.value}
                    </span>
                  </div>
                )
              })
            }
          </div>
        </div>
        {/* Graph section */}
        <div className='xl:col-span-2 px-4 flex flex-col justify-between'>
          <RelevanceClassificationBadge relevanceClassification={result.relevance} background />
          <Growth growth={result.growth}/>
          <ResponsiveContainer width="100%" height="45%">
            <AreaChart
              margin={{ bottom: -10 }}
              data={result.growthData}
            >
              <XAxis
                dataKey="date"
                axisLine={false}
                tickLine={false}
                style={{
                  fontSize: '8px'
                }}
              />
              <Area
                type="linear"
                dataKey="posts"
                stroke={'#651AEB'}
                fill={'#ECE3FC'}
              />
              <RechartTooltip content={<ChartTooltip />} allowEscapeViewBox={{ x: false, y: true }} wrapperStyle={{ zIndex: 1000 }} />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}

export default ResultCard
