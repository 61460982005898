import React from 'react'
import { ActivationItemType } from '@/views/Discover/Activations/v2/activations-types'
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'
import { BoltIcon, TrashIcon } from '@heroicons/react/24/outline'
import { SmallBadge } from '@/components/SmallClosableBadge'
import SightlyAvatar from '@/components/SightlyAvatar'
import { Badge } from '@/components/Badge'

const ActivationRow = (props: {
  activationItem: ActivationItemType
  selectedActivationIds: number[]
  handleSelect: (checked: boolean, activationItem: ActivationItemType) => void
  handleDelete: (activationItemId: number) => void
  isToDoActivation?: boolean
}) => {
  const { activationItem, selectedActivationIds, handleSelect, handleDelete, isToDoActivation: isToDoActivation } = props
  return (
    <tr
      key={activationItem.activationItemId}
      className={'bg-gray-50 fade-in animate-in'}
      data-testid="activations-todo-activation-row"
      id="activations-todo-activation-row"
    >
      <td></td>
       <td className={'whitespace-nowrap py-4 text-sm font-medium truncate flex gap-2 pl-2'}>
      {isToDoActivation && (
          <SightlyCheckbox
            dataTestId="activations-todo-activation-checkbox"
            handleChange={(checked: boolean) => handleSelect(checked, activationItem)}
            id="activations-todo-activation-checkbox"
            checked={selectedActivationIds?.includes(activationItem.activationItemId)}
            disabled={!activationItem.personas || activationItem.personas.length <= 0}
          />
        )}
        <div className="flex-shrink-0 w-4 h-4">
          <BoltIcon className="text-sightly-blue" />
        </div>
        <div
          data-testid="activations-todo-cluster-name-text"
          id="activations-todo-cluster-name-text"
          className="truncate"
        >
          {activationItem.clusterName}
        </div>
      </td>
      <td
        data-testid="activation-row-created-date"
        id="activation-row-created-date"
        className="text-sm text-gray-500 whitespace-nowrap"
      >
        {activationItem.submittedDate?.split(' ')[0]}
      </td>
      <td className="text-sm text-gray-500 whitespace-nowrap">
        <div
          className="flex flex-col max-w-xs gap-1 xl:flex-row xl:gap-2"
          data-testid="activations-todo-personas"
          id="activations-todo-personas"
        >
          {activationItem.personas.slice(0, 1).map((persona, index) => {
            return (
              <Badge
                size="small"
                color="gray"
                truncateTextSize={15}
                dataTestId="activations-implemented-persona-badge"
                key={`${activationItem.activationItemId}_${persona}_${index}`}
                text={persona}
                onClose={() => undefined}
                disabled={true}
              />
            )
          })}
          {activationItem.personas.length > 1 && (
            <div
              className="text-xs"
              data-testid="activations-todo-personas-others"
              id="activations-todo-personas-others"
            >
              + {activationItem.personas.length - 1} others
            </div>
          )}
        </div>
      </td>
      <td className="text-sm text-gray-500 whitespace-nowrap">
        <div className="flex items-center flex-shrink-0 gap-2 mt-4 sm:mt-0">
          <div
            className="flex -space-x-1 "
            data-testid="activations-todo-assignees"
            id="activations-todo-assignees"
          >
            {activationItem.assignees.slice(0, 1).map((assignee) => {
              return (
                <div
                  key={assignee}
                  className="inline-block rounded-full ring-2 ring-white"
                  data-testid="activations-implemented-assignee-avatar"
                  id="activations-implemented-assignee-avatar"
                >
                  <SightlyAvatar
                    text={assignee}
                    size="xs"
                    type={null}
                  />
                </div>
              )
            })}
          </div>
          {activationItem.assignees.length > 1 && (
            <div
              className="text-xs"
              data-testid="activations-implemented-assignees-others"
              id="activations-implemented-assignees-others"
            >
              + {activationItem.assignees.length - 1} others
            </div>
          )}
        </div>
      </td>

      { isToDoActivation &&(<td className="text-sm font-medium text-right whitespace-nowrap sm:pr-6">
        <div
          className={`hover:cursor-pointer `}
          data-testid={'activations-todo-delete-btn'}
          id={'activations-todo-delete-btn'}
        >
          <TrashIcon
            onClick={() => handleDelete(activationItem.activationItemId)}
            className="w-6 h-6 rounded hover:bg-slate-100"
          />
        </div>
      </td>
      )}
    </tr>
  )
}

export default ActivationRow
