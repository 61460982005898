import { useMatch, useSearch } from '@tanstack/react-location'
import { useIsMutating, useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { brandSuccessColor } from '../../../../../../assets/jss/colorConstants'
import { MyLocationGenerics } from '../../../../../../classes/utils'
import { SightlyActionDropdown } from '../../../../../../components/Sightly/SightlyFormElements/SightlyActionDropdown'
import SightlyCheckbox from '../../../../../../components/Sightly/SightlyFormElements/SightlyCheckbox'
import { SCENARIO_ACTIONS } from '../../../../../../staticData/data'
import { logError } from '../../../../../../utils'
import { ActionButton } from '../ActionButton'
import { scenariosApi } from '../scenariosV2-api'
import { rqKeysScenarioV2 } from '../scenariosV2-rqKeys'
import { PatchScenariosType, PatchScenarioType, ScenarioThemeType, ScenarioType } from '../scenariosV2-types'
import config from '@/config'
import { InspireMeButton } from '@/views/BrandProfiles/BrandProfile/components/Queries/components/InspireMeButton'
import { AIWarningModal } from '@/views/BrandProfiles/BrandProfile/components/AIWarningModal'
import { AiInfoWhisper } from '@/views/BrandProfiles/BrandProfile/components/AiInfoWhisper'
import { Loader } from 'rsuite'
import useBrandProfileBasic from '@/hooks/brandProfile/useBrandProfileBasic'
import useUser from '@/hooks/useUser'
import { rqKeys as mainRqKeys } from '@/ReactQueryKeyFactory'
import { productNamesForUI, products, userActions } from '@/staticData/products'
import GeneratingData from '../../GeneratingData'
import { useProductUsage } from '@/hooks/subscription/useProductUsage'
import ProductLimitedAccessInfo from '@/components/ProductLimitedAccessInfo'
import Tooltip from '@/components/TailwindTooltip'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const getResponseLabel = (responseId: number) => {
  const SCENARIOS_ACTIONS_VALUES = Object.values(SCENARIO_ACTIONS)
  const currentScenario = SCENARIOS_ACTIONS_VALUES.find((val) => val.id === responseId)
  return currentScenario?.text || ''
}

const getResponseBgColor = (responseId: number) => {
  return responseId === 1
    ? 'bg-[#FBBEBE]'
    : responseId === 2
    ? 'bg-[#EBE7FC]'
    : responseId === 3
    ? 'bg-[#BFE1FB]'
    : 'bg-[#f1f2f7]'
}

const getResponseTextColor = (responseId: number) => {
  return responseId === 1
    ? 'text-[#EA430F]'
    : responseId === 2
    ? 'text-[#5B32EF]'
    : responseId === 3
    ? 'text-[#26AAE1]'
    : ''
}

export const ScenarioTable = ({
  scenarioThemeId,
  sortBy
}: {
  scenarioThemeId: number | undefined
  sortBy: 'least' | 'most'
}) => {
  const queryClient = useQueryClient()
  const {
    params: { brandProfileId }
  } = useMatch<MyLocationGenerics>()

  const scenarioThemes = queryClient.getQueryData<ScenarioThemeType[]>(
    rqKeysScenarioV2.scenarioThemes(Number(brandProfileId), sortBy)
  )
  const { currentAccount, userCanService, perms } = useUser()
  const scenarioTheme = scenarioThemes?.filter((scenTheme) => scenTheme.scenarioThemeId === scenarioThemeId)[0]
  const scenarios = scenarioTheme?.scenarios || []
  const { viewOnly } = useSearch<MyLocationGenerics>()
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [checked, setChecked] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)
  const [selectedScenarios, setSelectedScenarios] = useState<ScenarioType[]>([])
  const { queryResult } = useBrandProfileBasic(Number(brandProfileId))
  const { productQuotaTally: aiCreditsTally } = useProductUsage({
    accountId: currentAccount?.accountId,
    productName: products.AI_CREDITS
  })
  const isIndeterminate = selectedScenarios.length > 0 && selectedScenarios.length < scenarios.length
  function toggleAll() {
    setSelectedScenarios(checked || indeterminate ? [] : scenarios)
    setChecked(!checked && !indeterminate)
    setIndeterminate(false)
  }

  const handleOnMutate = ({ scenarios, brandProfileId }: { scenarios: PatchScenariosType; brandProfileId: number }) => {
    const scenariosIds = scenarios.map((_scen: PatchScenarioType) => _scen.scenarioId)
    const scenarioThemesData = queryClient.getQueryData<ScenarioThemeType[]>(
      rqKeysScenarioV2.scenarioThemes(brandProfileId, sortBy)
    )
    let finalScenarioThemes = []
    if (!scenarioThemesData) return
    finalScenarioThemes = scenarioThemesData.map((scenarioTheme: ScenarioThemeType) => {
      return {
        ...scenarioTheme,
        scenarios: scenarioTheme.scenarios.map((scen: ScenarioType) => {
          return scenariosIds.includes(scen.scenarioId)
            ? {
                ...scen,
                scenarioResponseId: scenarios.filter((_scen) => scen.scenarioId === _scen.scenarioId)[0]
                  .scenarioResponseId
              }
            : scen
        })
      }
    })
    queryClient.setQueryData(rqKeysScenarioV2.scenarioThemes(brandProfileId, sortBy), finalScenarioThemes)
  }

  const handleResponseMutation = useMutation(scenariosApi.respondToScenarios, {
    onMutate: handleOnMutate,
    onSettled: () => {
      queryClient.invalidateQueries(rqKeysScenarioV2.scenarioThemesAll(Number(brandProfileId)))
    },
    onSuccess: () => {
      toast.success(`Scenario Response(s) Saved`)
      setSelectedScenarios([])
    },
    onError: (err) => {
      logError(err)
    }
  })

  const aiGenerateScenariosMutation = useMutation(
    (scenarioIds: number[]) => {
      const brandProfileInfo = queryResult.data
      const payload = {
        scenarioIds,
        companyName: brandProfileInfo?.brandProfileName || '',
        companyInfoSummary: brandProfileInfo?.companySummary || '',
        companyMentalitySummary: brandProfileInfo?.companyMentality || '',
        brandProfileId: Number(brandProfileId)
      }
      return scenariosApi.aiGenerateBrandProfileScenarios(payload)
    },
    {
      mutationKey: rqKeysScenarioV2.scenariosAIGenerationMutation(currentAccount?.accountId),
      onSettled: () => {
        queryClient.invalidateQueries(rqKeysScenarioV2.scenarioThemes(Number(brandProfileId), sortBy))
        queryClient.invalidateQueries(
          mainRqKeys.subscriptionQuotaQueryKey(currentAccount?.accountId, products.AI_CREDITS)
        )
      },
      onSuccess() {
        toast.success('Scenario Response(s) Generated')
        setSelectedScenarios([])
      }
    }
  )

  const isAIGeneratingScenarios = useIsMutating({
    mutationKey: rqKeysScenarioV2.scenariosAIGenerationMutation(currentAccount?.accountId)
  })

  const handleBulkActionClick = (actionId: 1 | 2 | 3 | 4) => {
    const newSelectedScenarios = JSON.parse(JSON.stringify(selectedScenarios))
    const _scenarios = [...newSelectedScenarios].map((scenario) => {
      return {
        scenarioResponseId: actionId,
        scenarioId: scenario.scenarioId
      }
    })

    handleResponseMutation.mutate({
      scenarios: _scenarios,
      brandProfileId: Number(brandProfileId)
    })
  }

  const percentComplete = scenarioTheme ? (scenarioTheme?.answeredCount / scenarioTheme?.scenarios.length) * 100 : 0

  const isScenarioAIGenerationAllowed = React.useMemo(()=>{
    if (aiCreditsTally && selectedScenarios.length > aiCreditsTally.productBalance) {
      return false
    }
    else {
      return true
    }
  },[selectedScenarios])

  const selectedScenarioIds = React.useMemo(() => {
    return selectedScenarios.map((_scen: PatchScenarioType) => _scen.scenarioId)
  }, [selectedScenarios])

  if (config.featureFlag.aiProfiles) {
    return (
      <>
        <div className="flex flex-col">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="relative">
              {!isScenarioAIGenerationAllowed && (
                <p
                  className="pl-8 text-sm text-red-600"
                  data-testid="brand-suggestion-count-max-error"
                >
                  Selection count exceeds the available limit of {aiCreditsTally?.productBalance || 0} AI Credits.
                </p>
              )}
              <div
                data-testid="scenario-table-percentage-complete"
                className="w-full mb-2 text-end text-[#8f8f8f] font-semibold text-xs pr-4"
              >
                Progress: {percentComplete.toFixed(0)}%
              </div>
              <div className="w-full h-2 rounded bg-slate-100">
                <div
                  data-testid="scenario-table-progress-complete"
                  style={{
                    backgroundColor: percentComplete === 100 ? brandSuccessColor : '#0047ff',
                    width: `${percentComplete}%`
                  }}
                  className="w-5 h-2 rounded"
                ></div>
              </div>
              <div className="flex items-center px-8 bg-sightly-blue-light h-14">
                <SightlyCheckbox
                  dataTestId="scenario-table-select-all-checkbox"
                  id="selectAllCheckbox"
                  checked={selectedScenarios.length === scenarios.length}
                  handleChange={toggleAll}
                  indeterminate={isIndeterminate}
                  disabled={!!viewOnly}
                />

                {selectedScenarios.length > 0 && (
                  <div
                    data-testid="scenario-table-select-all-count"
                    style={{ color: '#333d47' }}
                    className="ml-2 text-xs font-bold mr-9"
                  >{`${selectedScenarios.length} item(s) selected`}</div>
                )}

                {selectedScenarios.length > 0 && (
                  <div className="flex items-center h-12 ml-4 space-x-3 bg-sightly-blue-light">
                    <Tooltip
                      disabled={userCanService(perms.AI_CREDITS, products.AI_CREDITS, userActions.CREATE)}
                      content={
                        <ProductLimitedAccessInfo
                          inSufficientPermission={!userCanService(perms.AI_CREDITS, products.AI_CREDITS)}
                          productNames={[
                            aiCreditsTally && aiCreditsTally.isMaxedOut ? productNamesForUI.AI_CREDITS : undefined
                          ]}
                        />
                      }
                    >
                      <div>
                        <InspireMeButton
                          size="xs"
                          label="Inspire Me"
                          dataTestId="scenario-ai-generate-btn"
                          pendoId='pendo-inspireme-scenarios'
                          disabled={
                            viewOnly ||
                            !isScenarioAIGenerationAllowed ||
                            !userCanService(perms.AI_CREDITS, products.AI_CREDITS)
                          }
                          handleAIGenerate={() => {
                            setIsConfirmDialogOpen(true)
                          }}
                        />
                      </div>
                    </Tooltip>
                    <ActionButton
                      dataTestId="action-button-target"
                      type="target"
                      handleClick={() => {
                        handleBulkActionClick(SCENARIO_ACTIONS.target.id)
                      }}
                    />
                    <ActionButton
                      dataTestId="action-button-block"
                      type="block"
                      handleClick={() => {
                        handleBulkActionClick(SCENARIO_ACTIONS.block.id)
                      }}
                    />
                    <ActionButton
                      dataTestId="action-button-monitor"
                      type="monitor"
                      handleClick={() => {
                        handleBulkActionClick(SCENARIO_ACTIONS.monitor.id)
                      }}
                    />
                    <ActionButton
                      dataTestId="action-button-dont-monitor"
                      type="dontmonitor"
                      handleClick={() => {
                        handleBulkActionClick(SCENARIO_ACTIONS.dontmonitor.id)
                      }}
                    />
                  </div>
                )}
              </div>

              <table className="min-w-full divide-y divide-gray-300">
                <tbody className="bg-white divide-y divide-gray-200">
                  {scenarios.map((scenario: ScenarioType) => (
                    <tr
                      data-testid="scenario-table-row"
                      key={scenario.scenarioId}
                      className={selectedScenarios.includes(scenario) ? 'bg-gray-50' : undefined}
                    >
                      <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                        {selectedScenarios.includes(scenario) && (
                          <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                        )}
                        <SightlyCheckbox
                          dataTestId="scenario-table-row-checkbox"
                          disabled={!!viewOnly}
                          id="checkbox"
                          checked={selectedScenarioIds.includes(scenario.scenarioId)}
                          handleChange={(checked: boolean) => {
                            setSelectedScenarios(
                              checked
                                ? [...selectedScenarios, scenario]
                                : selectedScenarios.filter((p) => p.scenarioId !== scenario.scenarioId)
                            )
                          }}
                        />
                      </td>
                      <td
                        data-testid="scenario-table-row-scenario-name"
                        style={{ color: '#333d47' }}
                        className={classNames(
                          'whitespace py-4 pr-3 font-semibold text-sm',
                          selectedScenarioIds.includes(scenario.scenarioId) ? 'text-indigo-600' : 'text-gray-900'
                        )}
                      >
                        {scenario.scenarioName}
                      </td>

                      <td className="py-4 pl-3 pr-4 text-right whitespace-nowrap w-44">
                        <div data-testid="scenario-action-dropdown">
                          <SightlyActionDropdown
                            includeDontMonitor
                            placeholder="Select Action"
                            value={scenario.scenarioResponseId}
                            disabled={!!viewOnly || selectedScenarios.length > 0}
                            handleChange={(val: number) => {
                              const _scenarios = [
                                {
                                  scenarioId: scenario.scenarioId,
                                  scenarioResponseId: val
                                }
                              ]
                              handleResponseMutation.mutate({
                                scenarios: _scenarios,
                                brandProfileId: Number(brandProfileId)
                              })
                            }}
                          />
                        </div>
                      </td>
                      <td className="w-20 text-right whitespace-nowrap">
                        {scenario.isAiGenerated && (
                          <AiInfoWhisper
                            confidenceScore={scenario.confidenceScore || 0}
                            question={scenario.scenarioName}
                            reason={scenario.reason || ''}
                            responseLabel={getResponseLabel(scenario.scenarioResponseId || 0)}
                            responseBgColor={getResponseBgColor(scenario.scenarioResponseId || 0)}
                            responseTextColor={getResponseTextColor(scenario.scenarioResponseId || 0)}
                            key={scenario.scenarioId}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {!!isAIGeneratingScenarios && (
            <GeneratingData
              backdrop
              message="Generating..."
            />
          )}
          {handleResponseMutation.isLoading && <Loader backdrop />}
          {isConfirmDialogOpen && (
            <AIWarningModal
              open={isConfirmDialogOpen}
              handleCancel={() => setIsConfirmDialogOpen(false)}
              handleGenerate={() => {
                aiGenerateScenariosMutation.mutate(selectedScenarioIds)
                setIsConfirmDialogOpen(false)
              }}
            />
          )}
        </div>
        <style>
          {`.rs-loader-spin {
            position:fixed;
            top:40em;
        }`}
        </style>
      </>
    )
  }

  return (
    <div className="flex flex-col">
      <div>
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="relative">
            <div
              data-testid="scenario-table-percentage-complete"
              style={{ color: '#8f8f8f', fontWeight: 600, fontSize: 12, paddingRight: 16 }}
              className="w-full mb-2 text-end"
            >
              Progress: {percentComplete.toFixed(0)}%
            </div>
            <div className="w-full h-2 rounded bg-slate-100">
              <div
                data-testid="scenario-table-progress-complete"
                style={{
                  backgroundColor: percentComplete === 100 ? brandSuccessColor : '#0047ff',
                  width: `${percentComplete}%`
                }}
                className="w-5 h-2 rounded"
              ></div>
            </div>

            <div className="flex items-center px-8 bg-sightly-blue-light h-14">
              <SightlyCheckbox
                dataTestId="scenario-table-select-all-checkbox"
                id="selectAllCheckbox"
                checked={selectedScenarios.length === scenarios.length}
                handleChange={toggleAll}
                indeterminate={isIndeterminate}
                disabled={!!viewOnly}
              />

              {selectedScenarios.length > 0 && (
                <div
                  data-testid="scenario-table-select-all-count"
                  style={{ color: '#333d47' }}
                  className="ml-2 text-xs font-bold mr-9"
                >{`${selectedScenarios.length} item(s) selected`}</div>
              )}

              {selectedScenarios.length > 0 && (
                <div className="flex items-center h-12 ml-4 space-x-3 bg-sightly-blue-light">
                  <ActionButton
                    dataTestId="action-button-target"
                    type="target"
                    handleClick={() => {
                      handleBulkActionClick(SCENARIO_ACTIONS.target.id)
                    }}
                  />
                  <ActionButton
                    dataTestId="action-button-block"
                    type="block"
                    handleClick={() => {
                      handleBulkActionClick(SCENARIO_ACTIONS.block.id)
                    }}
                  />
                  <ActionButton
                    dataTestId="action-button-monitor"
                    type="monitor"
                    handleClick={() => {
                      handleBulkActionClick(SCENARIO_ACTIONS.monitor.id)
                    }}
                  />
                  <ActionButton
                    dataTestId="action-button-dont-monitor"
                    type="dontmonitor"
                    handleClick={() => {
                      handleBulkActionClick(SCENARIO_ACTIONS.dontmonitor.id)
                    }}
                  />
                </div>
              )}
            </div>

            <table className="min-w-full divide-y divide-gray-300">
              <tbody className="bg-white divide-y divide-gray-200">
                {scenarios.map((scenario: ScenarioType) => (
                  <tr
                    data-testid="scenario-table-row"
                    key={scenario.scenarioId}
                    className={selectedScenarios.includes(scenario) ? 'bg-gray-50' : undefined}
                  >
                    <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                      {selectedScenarios.includes(scenario) && (
                        <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                      )}
                      <SightlyCheckbox
                        dataTestId="scenario-table-row-checkbox"
                        disabled={!!viewOnly}
                        id="checkbox"
                        checked={selectedScenarioIds.includes(scenario.scenarioId)}
                        handleChange={(checked: boolean) => {
                          setSelectedScenarios(
                            checked
                              ? [...selectedScenarios, scenario]
                              : selectedScenarios.filter((p) => p.scenarioId !== scenario.scenarioId)
                          )
                        }}
                      />
                    </td>
                    <td
                      data-testid="scenario-table-row-scenario-name"
                      style={{ color: '#333d47' }}
                      className={classNames(
                        'whitespace py-4 pr-3 font-semibold text-sm',
                        selectedScenarioIds.includes(scenario.scenarioId) ? 'text-indigo-600' : 'text-gray-900'
                      )}
                    >
                      {scenario.scenarioName}
                    </td>

                    <td className="py-4 pl-3 pr-4 text-right whitespace-nowrap w-44">
                      <SightlyActionDropdown
                        includeDontMonitor
                        placeholder="Select Action"
                        value={scenario.scenarioResponseId}
                        disabled={!!viewOnly || selectedScenarios.length > 0}
                        handleChange={(val: number) => {
                          const _scenarios = [
                            {
                              scenarioId: scenario.scenarioId,
                              scenarioResponseId: val
                            }
                          ]
                          handleResponseMutation.mutate({
                            scenarios: _scenarios,
                            brandProfileId: Number(brandProfileId)
                          })
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
