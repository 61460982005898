import React from 'react'
import { ClusterType } from '@/views/Discover/Moments/v2/types'
import { SerializableMap } from '@/utils/classes/SerializableMap'
import { SerializableSet } from '@/utils/classes/SerializableSet'
import { TargetListType } from '@/views/Discover/Moments/v2/TargetMoment/index'

export const useTargetMoments = (props: { brandProfileId: number | undefined }) => {
  const { brandProfileId } = props

  const getLocalSelectedMoments = () => {
    const storedSelectedMoments = sessionStorage.getItem('selectedMoments')
    return storedSelectedMoments && storedSelectedMoments !== 'undefined' && JSON.parse(storedSelectedMoments)
  }

  /**
   * Loads the target list from session storage based on the brand profile ID.
   *
   * @returns {Map<number, TargetListType>} - Map of cluster IDs to TargetListType.
   */
  const loadTargetListFromStorage = (): Map<number, TargetListType> => {
    // Retrieve the locally stored selected moments from session storage.
    const selectedAllMomentsFromStorage = getLocalSelectedMoments()

    if (selectedAllMomentsFromStorage && brandProfileId) {
      const selectedMoments: ClusterType[] = selectedAllMomentsFromStorage[brandProfileId]

      if (selectedMoments) {
        // Transform the selected moments into a Map<number, TargetListType>.
        return new Map<number, TargetListType>(
          selectedMoments.map((targetMoment) => {
            if (targetMoment.targetList) {
              // Extract the previous target list.
              const prevTargetList = targetMoment.targetList as TargetListType
              // Transform ioIdWithPersonas from an object to a Map<number, Set<string>>.
              prevTargetList.ioIdWithPersonas = new Map(
                Object.entries(prevTargetList.ioIdWithPersonas).map(([ioId, personas]) => {
                  return [Number(ioId), new Set(personas)]
                })
              )
              // Return the transformed entry for the Map.
              return [Number(targetMoment.clusterId), prevTargetList]
            }
            // If there is no target list, create a new entry for the Map.
            return [
              Number(targetMoment.clusterId),
              {
                id: targetMoment.clusterId,
                clusterName: targetMoment.clusterName,
                ioIdWithPersonas: new Map<number, Set<string>>()
              }
            ]
          })
        )
      }
    }
    // Return an empty Map if there are no selected moments or brand profile ID.
    return new Map<number, TargetListType>()
  }

  /**
   * Serializes a set of strings using SerializableSet.
   *
   * @param {Set<string>} originalSet - The original set of strings.
   * @returns {SerializableSet} - The serialized set.
   */
  const serializeStringSet = (originalSet: Set<string>): SerializableSet<string> => {
    const serializedSet = new SerializableSet<string>()
    originalSet.forEach((item) => {
      serializedSet.add(item)
    })
    return serializedSet
  }

  /**
   * Serializes a map of number keys and sets of strings using SerializableMap.
   *
   * @param {Map<number, Set<string>>} originalMap - The original map.
   * @returns {SerializableMap} - The serialized map.
   */
  const serializeNumberStringMap = (originalMap: Map<number, Set<string>>): SerializableMap<number, SerializableSet<string>> => {
    const serializedMap = new SerializableMap<number, SerializableSet<string>>()
    originalMap.forEach((values, key) => {
      serializedMap.set(key, serializeStringSet(values))
    })
    return serializedMap
  }

  /**
   * Sets the target list to session storage based on the brand profile ID.
   *
   * @param {Map<number, TargetListType>} targetList - Map of cluster IDs to TargetListType.
   * @returns {void}
   */
  const setTargetListToStorage = (targetList: Map<number, TargetListType>): void => {
    const selectedAllMomentsFromStorage = getLocalSelectedMoments()

    if (!selectedAllMomentsFromStorage || !brandProfileId) {
      return
    }

    const selectedMoments = selectedAllMomentsFromStorage[brandProfileId]

    if (!selectedMoments) {
      return
    }

    const targetedMoments = selectedMoments.map((targetMoment: { clusterId: number; targetList: TargetListType }) => {
      const updatedTargetList = targetList.get(targetMoment.clusterId)

      if (updatedTargetList) {
        updatedTargetList.ioIdWithPersonas = serializeNumberStringMap(updatedTargetList.ioIdWithPersonas)
        targetMoment.targetList = updatedTargetList
      }

      return targetMoment
    })

    selectedAllMomentsFromStorage[brandProfileId] = targetedMoments

    sessionStorage.setItem('selectedMoments', JSON.stringify(selectedAllMomentsFromStorage))
  }

  return {
    loadTargetListFromStorage,
    setTargetListToStorage
  }
}
