import React from 'react'
import Table from 'rsuite/lib/Table'
import { TableColumnJs } from '@/utils'
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'
import { IActivation } from '@/views/TikTok/Activations/types'
import { BoltIcon as SolidBoltIcon } from '@heroicons/react/24/solid';
import { BoltIcon as OutlineBoltIcon } from '@heroicons/react/24/outline';

interface ICheckboxesConfig {
    handleSelectAll: (checked: boolean) => void
    handleCheckboxChange: (activationId: number) => void
    selected: Set<number>
    allSelected: boolean
    disableOfflineHashtags: boolean
}

interface IProps {
    activations: IActivation[]
    checkboxesConfig?: ICheckboxesConfig
}

const ActivationsTable = ({ activations, checkboxesConfig }: IProps) => {

    return (
        <Table
            loading={false}
            autoHeight
            data={activations}
            bordered
            className='shadow-md rounded-md px-2'
            data-testid="activations-activations-results-table"
        >
            {checkboxesConfig && (
                <TableColumnJs width={50}>
                    <Table.HeaderCell>
                        <SightlyCheckbox
                            id={'select-all-activations-hashtags'}
                            handleChange={checkboxesConfig.handleSelectAll ?? (() => { })}
                            checked={checkboxesConfig.allSelected}
                            disabled={!activations || activations.length < 1} />
                    </Table.HeaderCell>
                    <Table.Cell>
                        {(rowData: { activationId: number, hashtagStatus: string }) => {
                            return <SightlyCheckbox
                                id={`select-${rowData.activationId}-activations-hashtag`}
                                handleChange={() => checkboxesConfig.handleCheckboxChange(rowData.activationId)}
                                dataTestId={`activations-activations-results-table-select-column-${rowData.activationId}`}
                                checked={checkboxesConfig.selected.has(rowData.activationId)} 
                                disabled={checkboxesConfig.disableOfflineHashtags && 'ONLINE' !== rowData.hashtagStatus}/>
                        }}
                    </Table.Cell>
                </TableColumnJs>
            )}
            <TableColumnJs width={100}>
                <Table.HeaderCell>Date added</Table.HeaderCell>
                <Table.Cell>
                    {(rowData: { activationId: string, createdAt: string }) => {
                        const date = new Date(rowData.createdAt)
                        const month = String(date.getUTCMonth() + 1).padStart(2, '0')
                        const day = String(date.getUTCDate()).padStart(2, '0')
                        const year = String(date.getUTCFullYear()).slice(-2)
                        return <p data-testid={`activations-activations-results-table-date-column-${rowData.activationId}`}>{`${month}/${day}/${year}`}</p>
                    }}
                </Table.Cell>
            </TableColumnJs>

            <TableColumnJs width={150} resizable>
                <Table.HeaderCell>Hashtag</Table.HeaderCell>
                <Table.Cell>
                    {(rowData: { activationId: string, hashtagName: string }) => {
                        return <p data-testid={`activations-activations-results-table-hashtag-column-${rowData.activationId}`}>#{rowData.hashtagName}</p>
                    }}
                </Table.Cell>
            </TableColumnJs>

            <TableColumnJs width={50}>
                <Table.HeaderCell>Hashtag status</Table.HeaderCell>
                <Table.Cell>
                    {(rowData: { activationId: string, hashtagStatus: string }) => {
                        if ('ONLINE' === rowData.hashtagStatus) {
                            return <SolidBoltIcon className={"h-4 text-sightlyPurple"} data-testid={`activations-activations-results-table-hashtag-status-column-${rowData.hashtagStatus}`} />
                        } else {
                            return <OutlineBoltIcon className={"h-4 text-sightlyPurple"} data-testid={`activations-activations-results-table-hashtag-status-column-${rowData.hashtagStatus}`} />
                        }
                    }}
                </Table.Cell>
            </TableColumnJs>

            <TableColumnJs width={400} resizable>
                <Table.HeaderCell>Campaign</Table.HeaderCell>
                <Table.Cell>
                    {(rowData: { activationId: string, campaignName: string }) => {
                        return <p data-testid={`activations-activations-results-table-campaign-column-${rowData.activationId}`} className='truncate'>{rowData.campaignName}</p>
                    }}
                </Table.Cell>
            </TableColumnJs>

            <TableColumnJs width={200} resizable>
                <Table.HeaderCell>Adgroup</Table.HeaderCell>
                <Table.Cell>
                    {(rowData: { activationId: string, adGroupName: string }) => {
                        return <p data-testid={`activations-activations-results-table-adgroup-column-${rowData.activationId}`} className='truncate'>{rowData.adGroupName}</p>
                    }}
                </Table.Cell>
            </TableColumnJs>
        </Table>
    )
}

export default ActivationsTable