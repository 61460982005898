import { Link, useNavigate } from '@tanstack/react-location';
import React from 'react';
import { LoadingPage } from '@/components/LoadingPage';
import SightlyToggle from '@/components/Sightly/SightlyFormElements/SightlyToggle';
import SightlyButton from '@/components/Sightly/SightlyButton';
import { ThemeType } from '@/views/Admin/ScenariosV2/types';
import { useThemes } from '@/views/Admin/ScenariosV2/useThemes';

export const Themes = () => {
  const [archived, setArchived] = React.useState(false);
  const themesQuery = useThemes({ archived });
  const navigate = useNavigate();

  if (themesQuery.isLoading) {
    return <LoadingPage message="Fetching Themes" />
  }
  return (
    <div className="p-8">
      <div className="px-4 sm:px-6 lg:px-8">
        <Link to="/app/admin/v2scenarios">Back to Scenarios</Link>
        <div className="sm:flex sm:items-center mt-2">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Scenario v2 Themes</h1>
            <p className="mt-2 text-sm text-gray-700"></p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">
            <SightlyButton
              handleClick={() => navigate({ to: 'create' })}
              id="addTheme"
              text="Add Theme"
            />
          </div>
        </div>

        <div className="mt-8 flex flex-col">
          <SightlyToggle
            id="archivedToggle"
            enabled={archived}
            setEnabled={setArchived}
            enabledText="Archived"
            disabledText="Active"
          />
          <div className="pt-3 -my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Archived
                      </th>

                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {themesQuery?.data?.map((theme: ThemeType) => (
                        <tr key={theme.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {theme.theme}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-400 truncate max-w-lg">
                            {theme.description}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {theme.archived.toString()}
                          </td>

                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <Link to={`/app/admin/v2scenarios/themes/edit/${theme.id}`}>
                              Edit
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
