import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { joinClasses } from '../utils'
import { range } from 'lodash'

interface IProps {
    activePage: number
    countPerPage: number
    total: number
    onChangePage: (val: number) => void
    showingStart: number
    showingEnd: number
}
export function PaginationTailwind({
    activePage,
    countPerPage,
    total,
    onChangePage,
    showingStart,
    showingEnd
}: IProps) {
    const numButtons = Math.ceil(total / countPerPage);

    function getRange() {
        let start = Math.max(activePage - 2, 1)
        switch (numButtons - activePage) {
            case 0:
                start = Math.max(activePage - 4, 1)
                break;
            case 1:
                start = Math.max(activePage - 3, 1)
                break;
        }
        const length = start + 4 > numButtons ? numButtons - start + 1 : 5
        return Array.from({ length: length }, (v, i) => start + i);
    }

    return (
        <div className="flex items-center justify-between bg-white px-4 py-3 sm:px-6">
            <div className="sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    {total > 0 && (
                        <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">{showingStart}</span> to{' '}
                            <span className="font-medium">{showingEnd}</span> of{' '}
                            <span className="font-medium">{total}</span> results
                        </p>
                    )}
                </div>
                <div>
                    <nav
                        className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                        aria-label="Pagination"
                    >
                        <div
                            onClick={() => (activePage < 2 ? undefined : onChangePage(activePage - 1))}
                            className={joinClasses(
                                activePage < 2 ? 'bg-slate-100 cursor-not-allowed' : 'cursor-pointer',
                                'relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
                            )}
                        >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                            />
                        </div>
                        {getRange().map((item) => {
                            return (
                                <div
                                    key={item}
                                    onClick={() => onChangePage(item)}
                                    aria-current="page"
                                    className={joinClasses(
                                        item === activePage
                                            ? 'bg-sightly-blue-light border-sightly-blue-light text-sightly-blue'
                                            : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',
                                        'cursor-pointer relative z-10 inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-20'
                                    )}
                                >
                                    {item}
                                </div>
                            )
                        })}

                        <div
                            className={joinClasses(
                                numButtons - 1 < activePage ? 'bg-slate-100 cursor-not-allowed' : 'cursor-pointer',
                                'relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
                            )}
                            onClick={() => (numButtons - 1 < activePage ? undefined : onChangePage(activePage + 1))}
                        >
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                            />
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}
