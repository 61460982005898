import {
  ReportTemplate,
  REPORT_TYPE,
  Option,
  TEMPLATE_TYPE,
  templateGenericDimensionOptions,
  templateMetricOptions,
  templateConversionOptions,
  GENERIC_DIMENSION,
  METRIC, GenericDimensionOption, CONVERSION
} from "@/views/ReportGenerator/types/report-generator.types";

import personaIcon from '@/assets/img/persona.svg';

enum AUDIENCE_DIMENSION {
    AD_ID = 'adId',
    AUDIENCE_NAME = 'audienceName',
    AUDIENCE_TYPE = 'audienceType',
    DEVICE = 'device',
    VIDEO_ID = 'videoId',
    VIDEO_TITLE = 'videoTitle',
    VIDEO_DURATION = 'videoDuration',
}

interface AudienceDimensionOption extends Omit<GenericDimensionOption, 'value'> {
  name: string
  value: GENERIC_DIMENSION | AUDIENCE_DIMENSION
}


interface AudienceReport extends Omit<ReportTemplate, 'columns' | 'dimensions'> {
  columns: (GENERIC_DIMENSION | AUDIENCE_DIMENSION | METRIC | CONVERSION)[],
  dimensions: (GenericDimensionOption | AudienceDimensionOption)[]
}

export const dimensionOptions: Option<AudienceDimensionOption> = {
    [AUDIENCE_DIMENSION.AD_ID]: {
      name: 'Ad Id',
      value: AUDIENCE_DIMENSION.AD_ID
    },
    [AUDIENCE_DIMENSION.AUDIENCE_NAME]: {
      name: 'Audience Name',
      value: AUDIENCE_DIMENSION.AUDIENCE_NAME
    },
    [AUDIENCE_DIMENSION.AUDIENCE_TYPE]: {
      name: 'Audience Type',
      value: AUDIENCE_DIMENSION.AUDIENCE_TYPE
    },
    [AUDIENCE_DIMENSION.DEVICE]: {
      name: 'Device',
      value: AUDIENCE_DIMENSION.DEVICE
    },
    [AUDIENCE_DIMENSION.VIDEO_ID]: {
        name: 'Video Id',
        value: AUDIENCE_DIMENSION.VIDEO_ID
    },
    [AUDIENCE_DIMENSION.VIDEO_TITLE]: {
        name: 'Video Title',
        value: AUDIENCE_DIMENSION.VIDEO_TITLE
    },
    [AUDIENCE_DIMENSION.VIDEO_DURATION]: {
      name: 'Video Duration',
      value: AUDIENCE_DIMENSION.VIDEO_DURATION
    },
}

export const baseAudienceReport: AudienceReport = {
  name: 'Audience Report',
  description: '',
  type: REPORT_TYPE.DETAIL,
  templateType: TEMPLATE_TYPE.AUDIENCE,
  columns: [],
  dimensions: [
    ...templateGenericDimensionOptions,
    ...Object.keys(dimensionOptions).map(key => dimensionOptions[key])
  ],
  metrics: templateMetricOptions,
  conversions: templateConversionOptions
}


export const personaAudienceReportTemplate: AudienceReport = {
    ...baseAudienceReport,
    name: 'Persona Audience Report',
    columns: [
      METRIC.STAT_DATE,
      GENERIC_DIMENSION.CUSTOMER_ID,
      GENERIC_DIMENSION.INSERTION_ORDER_NAME,
      GENERIC_DIMENSION.PERSONA,
      AUDIENCE_DIMENSION.AUDIENCE_TYPE,
      AUDIENCE_DIMENSION.AUDIENCE_NAME,
      METRIC.IMPRESSIONS,
      METRIC.CPM,
      METRIC.VIEWS,
      METRIC.CLICKS,
      METRIC.CTR,
      METRIC.COST,
      METRIC.VCR,
      METRIC.QUARTILE_25,
      METRIC.QUARTILE_50,
      METRIC.QUARTILE_75,
      METRIC.QUARTILE_100,
    ],
    icon: personaIcon
}
