import {
  ReportTemplate,
  REPORT_TYPE,
  Option,
  TEMPLATE_TYPE,
  templateGenericDimensionOptions,
  templateMetricOptions,
  templateConversionOptions,
  GENERIC_DIMENSION,
  METRIC, GenericDimensionOption, CONVERSION
} from "@/views/ReportGenerator/types/report-generator.types";

export enum DMA_REGION_DIMENSION {
  DEVICE = 'device',
  DMA_REGION = 'dmaRegion',
}

interface DmaRegionDimensionOption extends Omit<GenericDimensionOption, 'value'> {
  name: string
  value: GENERIC_DIMENSION | DMA_REGION_DIMENSION
}

interface DmaRegionReport extends Omit<ReportTemplate, 'columns' | 'dimensions'> {
  columns: (GENERIC_DIMENSION | DMA_REGION_DIMENSION | METRIC | CONVERSION)[],
  dimensions: (GenericDimensionOption | DmaRegionDimensionOption)[]
}

export const dimensionOptions: Option<DmaRegionDimensionOption> = {
  [DMA_REGION_DIMENSION.DEVICE]: {
    name: 'Device',
    value: DMA_REGION_DIMENSION.DEVICE
  },
  [DMA_REGION_DIMENSION.DMA_REGION]: {
    name: 'DMA Region',
    value: DMA_REGION_DIMENSION.DMA_REGION
  },
}

export const baseDmaRegionReport: DmaRegionReport = {
  name: 'DMA Region Report',
  description: '',
  type: REPORT_TYPE.DETAIL,
  templateType: TEMPLATE_TYPE.DMA_REGION,
  columns: [],
  dimensions: [
    ...templateGenericDimensionOptions,
    ...Object.keys(dimensionOptions).map(key => dimensionOptions[key])
  ],
  metrics: templateMetricOptions,
  conversions: templateConversionOptions
}
