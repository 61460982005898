import { useQuery } from '@tanstack/react-query'
import { api } from './api'

interface IProps {
    archived: boolean
}

export const useThemes = ({ archived }: IProps) => {
    const QUERY_KEY = ['allScenarioThemes', archived]
    const themesQuery = useQuery(QUERY_KEY, () => api.themes.get({ archived }))
    return {
        ...themesQuery
    }
}
