import React from 'react'

export enum ChipSize {
    SMALL,
    MEDIUM
}

interface IProps {
    text: string
    size?: ChipSize,
    dataTestId?: string
}

const Chip: React.FunctionComponent<IProps> = React.memo(
    ({ text, size, dataTestId }) => {
        switch (size) {
            case ChipSize.SMALL:
                return (
                    <span data-testid={dataTestId} className="line-clamp-1 px-2 py-0.5 text-[0.6rem] text-purple-600 bg-purple-100 border border-purple-600 rounded-full font-bold h-fit">
                      {text}
                    </span>
                  )
            case ChipSize.MEDIUM:
            default:
                return (<span data-testid={dataTestId}
                    className="line-clamp-1 px-2 py-0.5 text-xs text-purple-600 bg-purple-100 border border-purple-600 rounded-full font-bold h-fit"
                >
                    {text}
                </span>)
        }   
    }
)

export default Chip
